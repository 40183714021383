import React from 'react';
import { CashOrderRejectToolbar as CashRT } from '../cashorders/CashOrderReject';
import { CreditOrderRejectToolbar as CreditRT } from '../creditorders/CreditOrderReject';


/**
 * For use within the RequestRejectForm "toolbar" 
 */

export const Rejection = (props: any) => {

  const { resource } = props;

  // orders
  const cashOrders = resource === 'cash-orders';
  const creditOrders = resource === 'credit-orders';

  return creditOrders ?
    /* reject CREDIT orders */ <CreditRT {...props} />
    : cashOrders 
      ? /* refuse CASH orders */ <CashRT {...props} />
      : null;
};