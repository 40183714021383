import CoordinatorsIcon from '@material-ui/icons/EmojiPeopleOutlined';

import UserList from '../usercrud/UserList';
import UserCreate from '../usercrud/UserCreate';
import UserEdit from '../usercrud/UserEdit';

const coordinators = {
  list: UserList,
  create: UserCreate,
  edit: UserEdit,
  icon: CoordinatorsIcon,
};

export default coordinators;
