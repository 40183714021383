import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  income: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: -8,
    marginBottom: -8,
    justifyContent: 'center',
  },
  addedby: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: -8,
    marginBottom: -8,
    justifyContent: 'start',
  },
  chip: { margin: 4 },
});

export const IncomeField = ({ record }) => {
  const classes = useStyles();
	const income = record && record.monthly_income;

  if (!record) {
    return null;
  };

  return (
    <span className={classes.income}>
    {!!income > 0 ? <Chip label={income} className={classes.chip} /> : '--'}
    </span>
  );
};

IncomeField.defaultProps = {
  record: {},
  label: 'Income',
  textAlign: 'center',
  cellClassName: '',
  headerClassName: '',
};


export const AddedByField = ({ record }) => {
  const classes = useStyles();
	const addedBy = record && record.branch_added_by;

  if (!record) {
    return null;
  };

  return (
    <span className={classes.addedby}>
    {!!addedBy > 0 ? addedBy : '--'}
    </span>
  );
};

AddedByField.defaultProps = {
  record: {},
  label: 'Reg. by',
  cellClassName: '',
  headerClassName: '',
};