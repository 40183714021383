import SuperagentIcon from '@material-ui/icons/SupervisedUserCircleOutlined';

import ProviderList from '../providercrud/ProviderList';
import ProviderCreate from '../providercrud/ProviderCreate';
import ProviderEdit from '../providercrud/ProviderEdit';

const superagents = {
  list: ProviderList,
  create: ProviderCreate,
  edit: ProviderEdit,
  icon: SuperagentIcon,
};

export default superagents;