import GroupMembersIcon from '@material-ui/icons/People';

import UserList from '../usercrud/UserList';
import UserCreate from '../usercrud/UserCreate';
import UserEdit from '../usercrud/UserEdit';

const groupmembers = {
  list: UserList,
  create: UserCreate,
  edit: UserEdit,
  icon: GroupMembersIcon,
};

export default groupmembers;
