import GroupWorkIcon from '@material-ui/icons/GroupWork';

import GroupList from '../groupcrud/GroupList';
import GroupEdit from '../groupcrud/GroupEdit';
import GroupCreate from '../groupcrud/GroupCreate';

const coopbranches = {
  create: GroupCreate,
  list: GroupList,
  edit: GroupEdit,
  icon: GroupWorkIcon,
};

export default coopbranches;