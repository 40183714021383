import React from 'react';
import packageJson from '../package.json';

global.appVersion = packageJson.version;

/**
 * first param  - version from 'meta.json'
 * second param - version in bundle file
 */

const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);
  const versionsB = versionB.split(/\./g);

  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());
    const b = Number(versionsB.shift());

    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  };
  return false;
};

const CacheBuster = props => {
  const [state, setState] = React.useState({ loading: true, isLatestVersion: false });
  const [refresh, setRefresh] = React.useState(false);

  const refreshCacheAndReload = () => {
    console.log('Clearing cache and hard reloading...');
    console.log(caches, 'caches');
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      // caches.keys().then(function (names) {
      //   // console.log(names, 'names')
      //   for (let name of names) caches.delete(name);
      // });
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(names => names.map(name => caches.delete(name)))
    };

    // delete browser cache and hard reload
    return window.location.reload(true);
  };

  React.useEffect(() => {
    fetch(`/meta.json`)
      .then(res => res.json())
      .then(meta => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;

        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
        setRefresh(shouldForceRefresh);
        
        if (refresh) {
          console.log(`We have a new version - ${latestVersion}. Forcing a refresh now.`);
          setState({ loading: false, isLatestVersion: false });
        } else {
          console.log(`You have the latest version - ${latestVersion}. No cache refresh needed.`);
          setState({ loading: false, isLatestVersion: true });
        };

      });
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  const { loading, isLatestVersion } = state;

  return props.children({ loading, isLatestVersion, refreshCacheAndReload });
};

export default CacheBuster;
