import React from 'react';
import clsx from 'clsx';
import { Redirect } from 'react-router';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Dialog } from '@material-ui/core';
import { Edit } from 'react-admin';

import { Transition } from '../settingsforms/FunderForm';
import { ProgramTitle } from '../settingsfields';
import { FetchProgress } from '../../../layout';

import * as P from '../../../allPermissions';

const FunderList = React.lazy(() => import("../funders/FunderList"));
const FunderForm = React.lazy(() => import("../settingsforms/FunderForm"));
const ProgramForm = React.lazy(() => import("../settingsforms/ProgramForm"));


const SettingEdit = (props: any) => {
  const history = useHistory();

  const { basePath, permissions, match } = props;
  
  const superuser = P.superuser(permissions);

  const handleClose = React.useCallback(() => {
    history.push(basePath);
  }, [history, basePath]);

  const moreProps = {
    onCancel: handleClose,
    ...props,
  };

  let isLoaded = React.useRef(false);

  const show = match && (match.path === '/funders/:id');

  React.useEffect(() => {

    if (isLoaded && show) {
      // ...disable "page scrolling" when dialog is "open"
      document.documentElement.style.overflow = 'hidden';
      document.body.style.overflow = 'hidden';
    };

    // ...and on unmount
    return () => {
      isLoaded.current = false; 
      document.body.style.overflow = '';
      document.documentElement.style.overflow = '';
    };

  }, [show, isLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Suspense fallback={<FetchProgress title="Loading..." top={true} /> }>
      {!!permissions
        ? superuser
          ? <Switch>
              <Route exact path="/programs/:id">
                <EditProgram {...moreProps} />
              </Route>
              <Route path="/funders/:id">
                <FunderList {...moreProps} />
                <Dialog // individual order details (for provider / sacco-admin)
                  maxWidth="lg"
                  open={true}
                  onClose={handleClose}
                  TransitionComponent={Transition}
                  className={clsx('funders--modal')}
                  aria-labelledby="funders-dialog-title"
                >
                  <EditFunder {...moreProps} />
                </Dialog>
              </Route>    
            </Switch>
          : <Redirect to="/" />
        : null}
    </React.Suspense>
  )
};

const EditProgram = (props: any) => {

  const { onCancel, ...other } = props;
  const { basePath, hasCreate, hasEdit, hasList, hasShow, permissions, ...rest } = other;

  const moreProps = {
    onCancel: onCancel,     // used to close the forms
    ...rest,
  };

  return (
    <Edit title={<ProgramTitle />} {...other}>
      <ProgramForm {...moreProps} /> 
    </Edit>
  );
};

const EditFunder = (props: any) => {

  const { onCancel, ...other } = props;
  const { basePath, hasCreate, hasEdit, hasList, hasShow, history, match, options, permissions, ...rest } = other;

  const moreProps = {
    onCancel: onCancel,     // used to close the forms
    ...rest,
  };

  const Title = () => null;

  return (
    <Edit title={<Title/>} {...other}>
      <FunderForm {...moreProps} /> 
    </Edit>
  );
};

export default SettingEdit;
