import React from 'react';
import clsx from 'clsx';
import { useFormState } from 'react-final-form';

import { SelectInput, SimpleForm } from 'react-admin';
import { apiFullCall } from '../../../modules';

export const SelectSuperAgentField = (props: any) => {

  const { classes, setSelectedAgent } = props;

  const token = sessionStorage.getItem('token');
  const programid = sessionStorage.getItem('userProgramId');
  const [superAgents, setSuperAgents] = React.useState([]);

  let isLoaded = React.useRef(true);

  /* Populate the Super-agent drop-down lists */

  React.useEffect(() => {

    /* Fetch Super-agents */

    if (isLoaded) {

      apiFullCall(
        '',
        token,
        'get',
        `programsuperagent/?program_id=${programid}`
      ).then(res => {

        if (res) {

          const { body } = res;
          const { results: data } = body;

          if ( data && data.length > 0 ) {
            // if there are "superagents", add "All"
            data.unshift({ id: '0', name: '-- All --' })
          } else {
            // if no "superagents", add "None"
            data.push({ id: '0', name: '-- None --' })
          };

          setSuperAgents(data);

        };        
      
      }).catch(
        error => console.error('Error during fetch:', error)
      );

    };

    // clean up API call, on unmount
    return () => { isLoaded.current = false; };

  }, [programid]); // eslint-disable-line react-hooks/exhaustive-deps
  

  return (
    <SimpleForm
      submitOnEnter={false}
      className={clsx(classes.formFourColumn, classes.first, 'main-filter')}
      toolbar={<FilterToolbar setSelectedAgent={setSelectedAgent} />}
    >
      <SelectInput
        label="Select Superagent"
        source="selected_superagent_id"
        optionText="name"
        choices={superAgents}
      />
    </SimpleForm>
  );
};

const FilterToolbar = props => {
  const formState = useFormState();

  const { setSelectedAgent } = props;

  const { selected_superagent_id } = formState.values;

  let isLoaded = React.useRef(true);

  React.useEffect(() => {

    // check if we're in "create mode" (no user-id yet)
    if ( isLoaded && !!selected_superagent_id ) {
      setSelectedAgent(selected_superagent_id);
    };

    // clean up API call, on unmount
    return function cleanup() { isLoaded.current = false; }

  }, [isLoaded, selected_superagent_id]) // eslint-disable-line react-hooks/exhaustive-deps

  return null
};
