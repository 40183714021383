import React from 'react';

type Dispatch<A> = (value: A) => void;
type SetStateAction<S> = S | ((prevState: S) => S);

interface contextProps{
  cropSidemenu: boolean,
  switchCardProgram: boolean,
  switchChartsProgram: boolean,
  setCropSidemenu: Dispatch<SetStateAction<boolean>>,
  setSwitchCardProgram: Dispatch<SetStateAction<boolean>>,
  setSwitchChartsProgram: Dispatch<SetStateAction<boolean>>,
};

export const AppContext = React.createContext({} as contextProps);
