import React from 'react';
import clsx from 'clsx';
import format from 'date-fns/format';
import formatDistance from 'date-fns/formatDistance';
import isBefore from 'date-fns/isBefore'
import { Box, Card, CardContent } from '@material-ui/core';

import { Separator, SectionTitle } from '../../../utils';


export const DeliveryField = (props) => {

  return (
    <>
      <Box
        display={{ xs: 'block' }} 
        width={{ xs: '100% !important' }}
      >
        <SectionTitle label="" show={<PlannedDeliveryTitle />} />
      </Box>
      <Box
        display={{ xs: 'block' }} 
        width={{ xs: '100% !important' }}
      >
        <Card className={clsx('requested-by-card')}>
          <CardContent className={clsx('provider-requests-notice')}>
            <p className={clsx('details')}>
              <PlannedDeliveryDate {...props} />
            </p>
          </CardContent>
        </Card>
      </Box>
      <Separator />          
    </>
  )
};

const PlannedDeliveryTitle = () => (
  // allow styling for modal-planned-delivery
  <p className={clsx('details')}>
    <span className={clsx('intro')}>Planned delivery:</span>
  </p>
);

const PlannedDeliveryDate = (props) => {

  const { completion, classesBase } = props;

  if (!completion) return null;

  // returns "boolean" to support color code
	const timeToDeliver = completion && completion.provider_visit_date &&
    isBefore(new Date(), new Date(completion['provider_visit_date']));

  return (
    <span>
      {format(new Date(completion['provider_visit_date']), 'dd/MMM/yyyy')}&nbsp;
      <span className={clsx({
          [classesBase.withInTime]: timeToDeliver,
          [classesBase.outOfTime]: !timeToDeliver,
        })}>
        ({formatDistance(new Date(completion['provider_visit_date']), new Date(), { addSuffix: true } )})
      </span>
    </span>
  )
};
