import React from 'react';

export const Stats = () => {
  const programid = sessionStorage.getItem('userProgramId');
  console.log('My program is', programid)

  const iframeSource = `<iframe style="min-height:98vh;border:0!important" width="100%" src="https://d21r4xvi0utz4u.cloudfront.net"></iframe>`;

  return (
    <div className="App" dangerouslySetInnerHTML={{__html: iframeSource}}></div>
  );
};
