import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { 
  useTranslate, 
  useLogin,
  useRefresh,
  useSafeSetState
} from 'react-admin';
import {
  Grid,
  Card,
  Button,
  CardActions,
  CircularProgress,
  TextField
} from '@material-ui/core';

import CopyrightIcon from '@material-ui/icons/Copyright';
import akImage from 'assets/ak.png';

const Input = ({
  meta: { touched, error }, // eslint-disable-line react/prop-types
  input: inputProps, // eslint-disable-line react/prop-types
  ...props
}) => <TextField error={!!(touched && error)} helperText={touched && error} {...inputProps} {...props} fullWidth />;

export const LoginForm = ({ redirectTo }) => {
  const [loading, setLoading] = useSafeSetState(false);
  const translate = useTranslate();
  const login = useLogin();

  const submit = values => {
    setLoading(true);
    login(values, redirectTo)
      .then(() => {
        setLoading(false);
      })
      .catch(error => {
        sessionStorage.setItem('loginError', true);
        setLoading(false);
      });
  };

  return (
    <Form
      onSubmit={submit}
      render={({ handleSubmit, modifiedSinceLastSubmit, values }) => {
        return (
          <Card className="signup-card position-relative y-up-center">
            <Grid container>
              <Grid item lg={5} md={5} sm={5} xs={12}>
                <div className="akb-loader"></div>
                <div className="p-8 pb-1 box flex flex-column justify-end items-center h-full">
                  <img src={akImage} alt="akellobanker-logo" />
                  <span className="small-text">Powered by: Quest Digital Finance Ltd <CopyrightIcon/>2016</span>
                </div>
              </Grid>
              <Grid item lg={7} md={7} sm={7} xs={12}>
                <div className="p-9 h-full bg-light-gray position-relative">
                  <form onSubmit={handleSubmit} noValidate>
                    <div>
                      <div className="mb-4">
                        <Field
                          id="phone"
                          name="username"
                          component={Input}
                          label={translate('login.auth.phone')}
                          disabled={loading}
                        />
                      </div>
                      <div className="mb-8">
                        <Field
                          id="password"
                          name="password"
                          component={Input}
                          label={translate('login.auth.password')}
                          disabled={loading}
                          type="password"
                          autoComplete="current-password"
                        />
                      </div>
                    </div>
                    <CardActions className="flex flex-wrap items-center p-0 mb-4">
                      <Button 
                        variant="contained" 
                        type="submit" 
                        color="primary" 
                        disabled={loading}
                      >
                        {loading && 
                          <CircularProgress className="btn-loading" size={18} thickness={2} />}
                        LOG IN
                      </Button>
                    </CardActions>
                    <SubmitError changed={modifiedSinceLastSubmit} />
                  </form>
                </div>
              </Grid>
            </Grid>
          </Card>
        )}}
    />
  );
};

const SubmitError = ({ changed }) => {

  const refresh = useRefresh();
  let isLoaded = React.useRef(false);
  const loginError = sessionStorage.getItem('loginError');

  React.useEffect(()=>{
    if (changed) {
      sessionStorage.removeItem('loginError'); // clear warning
      refresh();
    };
    /* Cleanup useEffect */   
    isLoaded.current = true; // set to true on mount...
    return () => { isLoaded.current = false; }; // ... and to false on unmount
  }, [isLoaded,changed,refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    loginError==='true'&&<p className="text-error">Wrong username or password. Try again!</p>
  )
}

LoginForm.propTypes = {
  redirectTo: PropTypes.string
};
