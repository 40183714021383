import React from 'react';
import clsx from 'clsx';

import { required, SimpleForm, TextInput } from 'react-admin';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

import { Deliver } from '../requestops';

import { Transition, SectionTitle } from '../../utils';
import { userEditStyles } from '../../stylesheets';

export const RequestDeliverForm = props => {
  const classes = userEditStyles();

  const {
    isXSmall, 
    handleData,
    deliverDialog, 
    handleDeliverDialog, 
    syncWithLocation,
    ...rest 
  } = props;

  const [errorStatus, setErrorStatus] = React.useState(false);

  const checkCode = value => {

    if (!value) return null;

    if (!!value && errorStatus) {
      return 'Code is invalid!';
    }

    return [];
  };

  let isValid = [required('Please enter a delivery code'), checkCode];

  // passed to <Deliver />
  const deliverProps = {
    isXSmall: isXSmall,
    setErrorStatus: setErrorStatus,
    handleDeliverDialog: handleDeliverDialog,
    ...rest
  };

  return (
    <Dialog
      maxWidth="xs"
      open={deliverDialog}
      TransitionComponent={Transition}
      className={clsx('request--modal')}
      aria-labelledby="delivery-dialog-title"
    >
      <DialogTitle
        disableTypography={true} // makes this a <div> not a default <h2>
        id="delivery-dialog-title"
      >
        <SectionTitle label="Confirm delivery" />
      </DialogTitle>
      <DialogContent>
        <SimpleForm 
          {...rest}
          className={clsx(
            'request',
            props.className
          )}
          submitOnEnter={false}
          toolbar={<Deliver {...deliverProps} />}
        >
          <TextInput
            id="completion_code"
            label="Enter delivery code"
            source="completion_code"
            className={classes.input}
            validate={isValid}
          />
        </SimpleForm>
      </DialogContent>
    </Dialog>
  );
};
