import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Chip,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  menuItem: {
    fontWeight: 398,
    minHeight: '34px',
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  indexedz: {
    zIndex: 99999999,
    paddingRight: '60px',
  },

  table: {
    width: '100%',
    borderCollapse: 'collapse',
    border: '1px solid rgba(224, 224, 224, 1)'
  },
  td: { border: '1px solid rgba(224, 224, 224, 1)', textAlign: 'center', padding: '10px', },
  th: { border: '1px solid rgba(224, 224, 224, 1)', color: 'gray', padding: '10px', },
  dialog: { marginTop: '-34px', }

}));


export default function ViewTransactionDetails({ record = {}, source, basePath }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [request, setRequest] = React.useState(record); // eslint-disable-line

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  return (
    <div className={classes.root}>
      <Button
        size="small"
        color="primary"
        variant="outlined"
        onClick={handleOpen}
      >
        View
      </Button>
      {/* dialog view*/}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'View Details'}
        </DialogTitle>
        <DialogContent className={classes.dialog}>
          <div>
            <h4>Service Provider: <Chip
              size="small"
              color="primary"
              variant="outlined"
              label={request.provider['business_name']}
            /> <Chip
                size="small"
                color="primary"
                variant="outlined"
                label={request.provider['primary_contact']}
              /> <Chip
                size="small"
                color="primary"
                variant="outlined"
                label={request.provider['village']}
              /></h4>
            <h4>Farmer Names: <Chip
              size="small"
              color="primary"
              variant="outlined"
              label={request.farmer['farmer_full_name']}
            /> <Chip
                size="small"
                color="primary"
                variant="outlined"
                label={request.farmer['farmer_username']}
              />  <Chip
                size="small"
                color="primary"
                variant="outlined"
                label={request.location['villagename'] + ' ' + request.location['parishname'] + ' ' + request.location['districtname']}
              /></h4>
          </div>
          <div>
            <table className={classes.table}>
              <thead>
                <th className={classes.th}>#</th>
                <th className={classes.th}>Service Name</th>
                <th className={classes.th}>Service Variants</th>
                <th className={classes.th}>Total Cost</th>
              </thead>
              <tbody>
                {request.farmer_requested_services.map((row, index) => (
                  <tr key={index + 1}>
                    <td className={classes.td}>{index + 1} </td>
                    <td className={classes.td}> {row['service_name']}</td>
                    <td className={classes.td}>
                      {row['specifications'].map((specification, i) => (
                        <Chip
                          key={i + 1}
                          size="small"
                          color="primary"
                          variant="outlined"
                          label={specification['qty'] + specification['unit_of_measure'] + '@' + specification['service_cost']}
                        />
                      ))}
                    </td>
                    <td className={classes.td}>{row['total_cost']}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <TableContainer >
            <Table >
              <TableHead>
                <TableRow>
                </TableRow>
              </TableHead>
              <TableBody>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} size="small" variant="outlined">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
