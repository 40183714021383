import React from 'react';
import clsx from 'clsx';
import { Box, Card } from '@material-ui/core';

import { RequestWithProviderField } from './CardHeaderField';

import { SectionTitle } from '../../../utils';

export const ProviderField = props => {

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const {
    classes, 
    classesBase,
    resource,
    record,
    // request status
    approved,
    rejected,
    ...rest // eslint-disable-line
  } = props;
  
  // destructure "record"
  const { provider, request_status } = record;

  // provider details
  const { business_name, primary_contact, village } = provider;

  const ProviderTitle = () => (
    // allow styling for modal-requested-by
    <p className={clsx('details')}>
      <span className={clsx('intro')}>Service Provider:</span>
    </p>
  );

  if (!request_status) return null;

  // passed to ...RequestWithProviderField
  const providerProps = {
    business_name: business_name, 
    primary_contact: primary_contact,
    village: village
  };

  /** 
   * if request was "approved" or "rejected", 
   * check if we're at CREDIT requests 
   */
  return resource === "credit-orders"
    ? <>
        <Box
          display={{ xs: 'block' }} 
          width={{ xs: '100% !important' }}
        >
          <SectionTitle label="" show={<ProviderTitle />} />
        </Box>
        <Box
          display={{ xs: 'block' }} 
          width={{ xs: '100% !important' }}
        >
          <Card className={clsx('requested-by-card')}>
            {/* "provider" section */}
            <RequestWithProviderField {...providerProps} />
          </Card>
        </Box>
      </>
    : null;
};
