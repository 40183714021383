import { makeStyles } from '@material-ui/core/styles';

const maxWidth = 599.98;   // sm (down)

export const requestStyles = makeStyles(theme => ({
  root: {
    margin: '1em' 
  },
  card: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 0 0.5rem 0',
  },
  cardTitleContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cardContent: {
    ...theme.typography.body1,
    display: 'flex',
    flexDirection: 'column',
  },
  first: {
    padding: '10px 12px',
  },
  space: {
    padding: '5px 10px',
  },
  heading: {
    fontWeight: 'bold',
  },
  container: {
    minWidth: '8em',
    marginLeft: '1em',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  requestDialog: {
    minWidth: '600px',
    [`@media (max-width: ${maxWidth}px)`]: {
      minWidth: '250px',
    },
  },
  requestTotal: {
    fontSize: '0.95rem',
    fontWeight: '600',
    textAlign: 'right !important',
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
  },
  requestItemType: {
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  formRow: {
    width: '100%',
    display: 'flex',
  },
  formColumn: {
    width: '50%',
    padding: '5px 10px',
  },
  dividerSuccess: {
    marginTop: '10px',
    marginBottom: '10px',
    background: '#488a4b',
  },
  deliveryDate:{
    fontSize:'18px',
    fontWeight:'700',
    fontStyle:'italic'
  },
  userDialog:{
    minWidth:'1200px',
  },
  profilePhoto:{
    display:'none',
  },
  formFourColumn: {
    width: '25%',
    padding: '10px 12px',
  },
  formThreeColumn: {
    width: '33%',
    padding: '5px 10px'
  },
  columnTwoThirds: {
    width: '66%',
    padding: '5px 10px'
  },
  formTwoColumn: {
    width: '50%',
    padding: '5px 12px'
  },
  formOneColumn:{
    width: '100%',
    padding: '5px 10px'
  },
  formInput:{
    width:'100%',
  },
  parent:{
    position:'relative',
    width:'100%'
  },
  searchResults:{
    position: 'absolute',
    zIndex: '9999',
    maxHeight: '300px',
    overflow: 'scroll',
    background:'#ffffff',
    width:'inherit',
    boxShadow: '2px 3px 6px 1px',
  },
  clearSuggestion:{
    textAlign:'center'
  },
  saccoServicesWrapper:{
    paddingTop:'25px',
  },
  saccoServiceTableCell:{
    fontSize: 14,
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  saccoServiceTableRow:{
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  saccoServiceTable:{
    width:'100%',
  },
  addServiceButton:{
    float:'right',
  },
  serviceColumn:{
    width:'33%',
  },
  serviceList:{
    width:'66%',
  },
  formColumFullWidth:{
    width:'100%',
  },
  addServiceDialog:{
    justifyContent:'space-between',
    display:'flex',
  },
  buttonAddService: {
    color: '#4caf50',
    borderColor: '#4caf50',
  },
  buttonSaveServices: {
    color: '#4caf50',
    borderColor: '#4caf50',
    marginTop:'10px',
    float:'right'
  },
  statisticsRow: {
    width: '100%',
    display: 'flex',
    marginTop: '20px',
    background: '#ffffff',
    borderRadius: '4px',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    '& .main-filter': {
      marginRight: '14px',
    },
  },
  formTwoThirds: {
    width: '66%',
    padding: '5px 10px'
  },
}));
