import { makeStyles } from '@material-ui/core/styles';

const layoutStyles = makeStyles(
  () => ({
    /* Overidding ra-layout styling
     * Ref: ra-ui-materialui/src/layout/Layout.js
     */
    root: {
      '& > div': {
        marginTop: '65px',
      },
      '& header': {
        '& [class*=MuiToolbar]': {
          '& > p[class*=MuiTypography]': {
            fontSize: '1.15rem',
            fontWeight: 600,
          },
          '&.MuiToolbar-dense': {
            minHeight: '65px',
          },
        },
      },
      '& main': {
        '& > div:nth-of-type(2n)': {
          '& [class*=main]': {
            '& table': {
              '& > thead': {
                '& th': {
                  fontWeight: 'bold',
                },
              },
              '& > tbody': {
                '& .MuiTableCell-root': {
                  '& > a, & > a[class*=link]': {
                    color: '#722F37 !important',
                    '&[role=button]': {
                      '&:hover, &:focus': {
                        backgroundColor: 'rgba(63, 81, 181, 0.08) !important',
                      },
                    },
                    '& > span': {
                      color: '#722F37',
                    },
                  },
                  '& > a[class*=button]': {
                    '&:hover, &:focus': {
                      color: '#722F37 !important',
                      backgroundColor: 'rgba(63, 81, 181, 0.08) !important',
                    },
                  },
                },
                '& > [class*=clickableRow]': {
                  '&[class*=hover]': {
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.07)',
                    },
                  },
                },
              },
            },
            '& [class*=scroller]': {
              '& > span[class*=indicator]': {
                backgroundColor: '#722F37 !important',
              },
            },
          },
          '& form': {
            '& > .MuiToolbar-root': {
              '& .MuiButton-textPrimary': {
                backgroundColor: ' #722F37 !important',
                color: '#eff0f1 !important',
                '&:hover, &:focus, &:active': {
                  backgroundColor: '#722F37 !important',
                  color: '#eff0f1 !important',
                  boxShadow: `0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)`,
                },
              },
            },
            '& [class*=filter-field]': {
              '& [class*=input]': {
                '& > label[class*=formControl]': {
                  '&:not([class*=error])': {
                    color: '#722F37',
                  },
                },
                '& > div[class*=formControl]': {
                  '& [class*=error]': {
                    '&:after': {
                      borderBottomColor: '#722F37',
                    },
                  },
                  '&:after': {
                    borderBottomColor: '#722F37',
                  },
                },
                /**
                 * Related to https://gitlab.com/akellobanker/akellobanker-web-app/-/issues/4
                 *
                 * Currently, we hide this error which is triggered by unintended api call
                 */
                '& > p[class*=contained]': {
                  display: 'none',
                },
              },
            },
            '& > [class*=content]': {
              '& [class*=container]': {
                '&[role=combobox]': {
                  display: 'inline-flex',
                },
              },
              '& > span': {
                '&:nth-child(4)': {
                  '& > div[class*=input]': {
                    '& > div': {
                      '&:nth-of-type(4), &:nth-of-type(5)': {
                        '& > div': {
                          width: 472,
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  }),
  { name: 'AkbLayout' }
);

const sidebarStyles = makeStyles(
  theme => ({
    /* Overidding ra-layout styling
     * Ref: ra-ui-materialui/src/layout/Sidebar.js
     */
    root: {
      zIndex: 1000,
      '& a': {
        minHeight: '44px',
        fontSize: '0.875rem',
        '&.MuiListItem-root': {
          color: '#722F37'
        },
        '& > .MuiListItemIcon-root': {
          color: '#722F37'
        },
        '&:hover, &:focus, &:active': {
          '&.MuiListItem-root': {
            color: '#722F37'
          },
          '& > .MuiListItemIcon-root': {
            color: '#722F37'
          },
        }
      },
      '& .MuiListItem-button': {
        '&:hover, &:focus, &:active': {
          backgroundColor: 'rgba(0, 0, 0, 0.065)',
        },
      },
      '& .general-sidemenu' : {
        '& a': {
          '&:hover, &:focus': {
            color: '#722F37',
          },
          '&[class*=active], &:active': {
            fontWeight: '600',
            borderRadius: '4px',
            color: '#722F37',
          },
          '& [class*=icon]': {
            '&:hover, &:focus, &:active': {
              backgroundColor: '£fff',
            },
          },
        },
        '& .MuiListItemIcon-root': {
          color: '#722F37',
          '&:hover, &:focus, &:active': {
            color: '#722F37',
          },
        }
      },
      '& .my-profile-sidemenu' : {
        '& a': {
          borderRadius: '4px',
          '&:hover, &:focus, &:active': {
            color: '#722F37',
            backgroundColor: 'rgba(0, 0, 0, 0.065)',
          },
          '&:active': {
            fontWeight: '600',
          },
          '&[class*=active]': {
            color: '#fff',
          },
          '& [class*=icon]': {
            '&:hover, &:focus, &:active': {
              color: '#fff',
            },
          },
        },
      },
      '& li': {
        minHeight: '44px',
        fontSize: '0.875rem',
        '&[class*=button]': {
          '& > .MuiTypography-colorTextSecondary': {
            color: '#722F37'
          },
        },
      },
      [theme.breakpoints.up('md')]: {
        '& > .MuiDrawer-paperAnchorLeft': {
          paddingTop: '1.5em',
        },
      },
      [theme.breakpoints.down('sm')]: {
        '& > .MuiDrawer-paperAnchorLeft': {
          minWidth: '75%',
        },
      },
    },
  }),
  { name: 'AkbSidebar' }
);

export { layoutStyles, sidebarStyles };
