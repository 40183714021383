import React from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom'
import { TextField } from 'react-admin';
import { Avatar, Card, CardHeader, CardContent } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/PeopleOutline';

import { CircularProgress } from '../../../layout';

const FarmerReportCard = props => {
  const history = useHistory();

  const { ids, basePath, data, filters, loading } = props;

  const viewDetails = farmerclass => {
    sessionStorage.setItem('_farmers_filter', JSON.stringify(filters));
    sessionStorage.setItem('_farmers_classification', JSON.stringify(farmerclass));

    history.push({ 
      basePath: basePath, 
      pathname: '/farmersreport-details', 
      state: { farmerclass: farmerclass, filters: filters } 
    });

  };

  return (
    <div className={clsx('report-section')}>
      {ids.map((id, i) =>
        <Card
          key={i} 
          raised={false}
					className={clsx('report-card', 'farmer')}  
          onClick={() => viewDetails(data[id])}
        >
          <CardHeader
            title={<TextField record={data[id]} source="name" />}
            avatar={<Avatar icon={<PersonIcon />} />}
          />
          {loading
						? <CircularProgress className={clsx('sm-wide')} />
						: <CardContent>
                <TextField record={data[id]} source="count" />
              </CardContent>}
        </Card>
      )}
    </div>
  );
};

FarmerReportCard.defaultProps = {
  data: {},
  ids: [],
};

export default FarmerReportCard;