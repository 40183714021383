import React, { FC, ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Record } from 'ra-core';
import { Button } from 'react-admin';
import { PropTypes as MuiPropTypes } from '@material-ui/core';
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import { DoneSharp } from '@material-ui/icons';

export const ApproveButton: FC<ApproveButtonProps> = ({
	basePath = '',
	icon = defaultIcon,
	label = 'Approve',
	record,
	classes,
	hasList,
	hasEdit,
	hasShow,
	hasCreate,
	fullScreen,
	isMatch,
	isXSmall,
	handleClick = (f) => f,
	...rest
}) => {

	return (
		<Button
			label={label} 
			onClick={handleClick}
			{...rest as any}
		>
			{typeof isXSmall !== "undefined" && isXSmall 
				? <IconAndLabel icon={icon} label={label} /> 
				: icon
			}
		</Button>
	);
};

const defaultIcon = <DoneSharp />;

const IconAndLabel = ({ icon, label }) => <span>{icon} {label}</span>;

interface ButtonProps {
	alignIcon?: 'left' | 'right';
	children?: ReactElement;
	classes?: object;
	className?: string;
	color?: MuiPropTypes.Color;
	disabled?: boolean;
	label?: string;
	size?: 'small' | 'medium' | 'large';
}

interface Props {
	basePath?: string;
	record?: Record;
	icon?: ReactElement | null;
	isXSmall?: boolean;
	handleClick?: any;
	[key: string]: any;
}

export type ApproveButtonProps = Props & ButtonProps & MuiButtonProps;

ApproveButton.propTypes = {
	basePath: PropTypes.string,
	icon: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.any
  ]),
	isXSmall: PropTypes.bool,
	label: PropTypes.string,
	record: PropTypes.any,
	handleClick: PropTypes.func
};
