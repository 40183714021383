import React from 'react';
import clsx from 'clsx';
import { usePermissions } from 'react-admin';
import { Box } from '@material-ui/core';

import { DateFields } from './DateFields';
import { SearchDistrictField } from './SearchDistrictField';
import { SelectSuperAgentField } from './SelectSuperAgentField';
import { AppContext, SuperuserDashboardContext } from '../../../context';

import { apiCall, requestStyles } from '../../../modules';

interface Style {
  first?: string,
  container?: string,
  selectInput?: string,
  statisticsRow?: string,
  formFourColumn?: string,
};

/**
 * 
 * To optimize performance, we trigger API calls for first 2 charts + targets
 * 
 * 1- plotUserTrends graph -> farmer enrollment trends
 * 2- plotProviderGrowth graph -> provider enrolled + cumulative 
 * 3- drawVUMeter graph -> Farmer targets
 * 4- plotProviderGrowth graph -> filtering
 * 
 * React.Suspense(d) graphs include:
 * 
 * @see /graphs/DelayedOtherGraphs.js
 * 
 * - plotProviderGrouping graph-> provider_distribution + filtering
 * - serviceProviderGrowthCumulative graph -> provider_growth_cumulative + filtering
 * - plotRequests graph -> requests
 * - plotUserGrouping graph -> total
 *  
 * @see /graphs/DelayedFarmerGraphs.js
 * 
 * - plotUserGrowth graph -> usergrowth
 */

export const TopFilters = () => {
  const classes: Style = requestStyles();
  const { permissions } = usePermissions();

  const token = sessionStorage.getItem('token');
  const programid = sessionStorage.getItem('userProgramId');

  const {
    Charts,
    isSuperuser,
    isSuperagent,
    superagentId,
    selectedAgent, 
    activeDistrict,
    setLoadingChart,
    setSelectedAgent,
    setSelectedDistrict
  } = React.useContext(SuperuserDashboardContext);

  const { setSwitchChartsProgram } = React.useContext(AppContext);

  let isLoaded = React.useRef(true);


  React.useEffect(() => { 

    if (isLoaded && !!selectedAgent && !!activeDistrict && permissions) {

      /* Update "farmer" growth - enrollment */

      if ( isSuperagent ) {
    
        apiCall(
          '',
          token,
          'get',
          `usergrowth/?district=${activeDistrict}&program_id=${programid}&agent_id=${superagentId}`
        ).then((data: object) => {
          
          Charts.plotUserTrends(data);
    
        }).catch(
          error => console.error("Error while fetching farmer's growth:", error)
        );

        /* delayed "provider" growth */

        setTimeout(() => {

          apiCall(
            '',
            token,
            'get',
            `providergrowth/?district=${activeDistrict}&program_id=${programid}&agent_id=${superagentId}`
          ).then((data: object) => {

            Charts.plotProviderGrowth(data);
      
          }).catch(
            error => console.error("Error while fetching provider's growth:", error)
          );

        }, 1500);

      } else {

        apiCall(
          '',
          token,
          'get',
          `usergrowth/?district=${activeDistrict}&program_id=${programid}&agent_id=${selectedAgent}`
        ).then((data: object) => {
         
          Charts.plotUserTrends(data);
    
        }).catch(
          error => console.error("Error while fetching farmer's growth:", error)
        );
  
        /* delayed "provider" growth */
  
        setTimeout(() => {
  
          apiCall(
            '',
            token,
            'get',
            `providergrowth/?district=${activeDistrict}&program_id=${programid}&agent_id=${selectedAgent}`
          ).then((data: object) => {
  
            Charts.plotProviderGrowth(data);
      
          }).catch(
            error => console.error("Error while fetching provider's growth:", error)
          );
  
        }, 1500);

      };

    };

    if ( permissions && !isSuperagent ) { // don't run, if superagent

      if ( programid ) {
        var programTarget = 80000;

        if( parseInt(programid) === 4 ){
          programTarget = 20000;
        } else if( parseInt(programid) === 6 ){
          programTarget = 3000;
        } else if( parseInt(programid) === 3 ){
          programTarget = 30000;
        };
        
        /* Handle "farmer" targets */
    
        setTimeout(() => {
    
          /* Plot "farmer" targets */
          apiCall(
            '',
            token,
            'get',
            `farmertargets/?&program_id=${programid}`
          ).then((data: object) => {
    
            let total = data['total'];
                 
            /* Reset chart display */
            setLoadingChart(false); 
            setSwitchChartsProgram(false);
  
            Charts.drawVUMeter({ value: total, max: programTarget }, 'farmer_targets', 'Farmers Targets');      
    
          }).catch(
            error => console.error('Error while fetching current farmer targets:', error)
          );
    
        }, 2500);
  
      };

    };

    // clean up API call, on unmount
    return () => { isLoaded.current = false; };

  }, [isLoaded, isSuperagent, activeDistrict, selectedAgent, programid, permissions]); // eslint-disable-line react-hooks/exhaustive-deps


  /* Filter the "provider" growth - based on selected District & Super-agent */


  React.useEffect(() => {

    let pickedAgent = Number(selectedAgent);
    let pickedDistrict = Number(activeDistrict);

    if (pickedAgent > 0 && pickedDistrict > 0) { // this runs when superagent is logged in.
  
      /* Plot service provider growth */
      apiCall(
        '',
        token,
        'get',
        `providergrowth/?district=${pickedDistrict}&program_id=${programid}&agent_id=${pickedAgent}`
      ).then((providerGrowth: object) => {

        Charts.plotProviderGrowth(providerGrowth);

      }).catch(
        error => console.error("Error while fetching provider's growth:", error)
      );

    };

    // clean up API call, on unmount
    return () => { isLoaded.current = false; };

  }, [isLoaded, activeDistrict, selectedAgent, programid]); // eslint-disable-line react-hooks/exhaustive-deps


  return isSuperuser ? (
    <Box className={classes.statisticsRow}>
      <Box className={clsx(classes.formFourColumn, classes.first)}>
        <SearchDistrictField classes={classes} setSelectedDistrict={setSelectedDistrict} />       
      </Box>
      <SelectSuperAgentField classes={classes} setSelectedAgent={setSelectedAgent} />
      <DateFields classes={classes} />
    </Box>
  ) : null;
};
