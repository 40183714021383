import React,{useState} from 'react';
import clsx from 'clsx';
import { SaveButton, SimpleForm,Toolbar, useRefresh, useNotify } from 'react-admin';
import { apiFullCall} from 'modules/apiHelper';
import { ApproveButton as NoButton } from 'layout';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {apiCall } from '../../../modules';
export const ConfirmAssignment = props => {
  const {
    handleDialogClose,
    unselectAll,
    selectedIds,
    ...rest
  } = props;
  const [agentprograms, setAgentPrograms] = useState({programs:[], selected:'0'});
  const [allsuperagents, setAllsuperagents] = useState({superagents:[], selected:'0'});
  const [allcoopBranches, setCooperativeBranches] = useState({coopBranches:[], selected:'0'});
  const token = sessionStorage.getItem('token');
  const bulkGrpProps = {
    ...props,
    allcoopBranches
  };
  React.useEffect(() => {

    apiCall('', token, 'get', `programs/`)
        .then(programData => {
          const allPrograms = {programs: programData.results, selected:'0'};
          setAgentPrograms(allPrograms);
        })
    .catch(error => console.error('Error during fetch:', error));
    }, [token]) 
  
    const selectProgram = event => {
      const value = event.target.value;
      setAgentPrograms({ ...agentprograms, selected:value });
      setCooperativeBranches({ ...allcoopBranches, coopBranches:[]});
      setCooperativeBranches({ ...allcoopBranches, selected:0});
      setAllsuperagents({ ...allsuperagents, superagents:[] });
      setAllsuperagents({ ...allsuperagents, selected:0 });
      apiCall('', token, 'get', `programsuperagent/?program_id=${value}`)
        .then(superagentData => {
          setAllsuperagents({ ...allsuperagents, superagents: superagentData.results });
        })
      .catch(error => console.error('Error during fetch:', error));
    };
  
    const selectAgent = event => {
      const value = event.target.value;
      setAllsuperagents({ ...allsuperagents, selected:value });
      setCooperativeBranches({ ...allcoopBranches, coopBranches:[]});
      setCooperativeBranches({ ...allcoopBranches, selected:0});
      apiCall('', token, 'get', `cooperativebranches/?programid=${agentprograms.selected}&agentid=${value}&status=cooperative_branch_name`)
      .then(groups => {
        setCooperativeBranches({ ...allcoopBranches, coopBranches:groups.results });
      }).catch(error => console.error('Error during fetch:', error));
    };

    const selectGroup = event => {
        const value = event.target.value;
        setCooperativeBranches({ ...allcoopBranches, selected:value });
    };
  return (
    <SimpleForm
      {...rest}
      submitOnEnter={false}
      className={clsx( 'profile', 'message' )}
      toolbar={<BulkAssignmentToolbar {...bulkGrpProps} />}
    >
         <FormControl variant="outlined" style={{ display: 'block', float: 'left', width: '100%', marginLeft: '10px' }}>
            <InputLabel id="demo-simple-select-outlined-label">Programs</InputLabel>
            <Select style={{ width: '100%',height: '40px' }}
            labelId="demo-simple-select-outlined-label"
            id="district-select-outlined"
            value={agentprograms.selected}
            onChange={selectProgram}
            label="Select Program"
            >
            <MenuItem value="0">
                All
            </MenuItem>
            {agentprograms.programs && agentprograms.programs.map((program, index) =>
                <MenuItem value={program.id} key ={`${index}-new-program`}>
                {`${program.program_name}`}
                </MenuItem>
            )}
            </Select>
        </FormControl>
        <FormControl variant="outlined" style={{ display: 'block', float: 'left', width: '100%', marginLeft: '10px' }}>
            <InputLabel id="demo-simple-select-outlined-label">Super Agents</InputLabel>
            <Select style={{ width: '100%',height: '40px' }}
            labelId="demo-simple-select-outlined-label"
            id="district-select-outlined"
            value={allsuperagents.selected}
            onChange={selectAgent}
            label="Select Agent"
            >
            <MenuItem value="0">
                All
            </MenuItem>
            {allsuperagents.superagents && allsuperagents.superagents.map((superagent, index) =>
                <MenuItem value={superagent.id}  key ={`${index}-new-agent`}>
                {`${superagent.name}`}
                </MenuItem>
            )}
            </Select>
        </FormControl>
        <FormControl variant="outlined" style={{ display: 'block', float: 'left', width: '100%', marginLeft: '10px' }}>
            <InputLabel id="demo-simple-select-outlined-label">Agent Groups</InputLabel>
            <Select style={{ width: '100%',height: '40px' }}
            labelId="demo-simple-select-outlined-label"
            id="district-select-outlined"
            value={allcoopBranches.selected}
            onChange={selectGroup}
            label="Select Group"
            >
            <MenuItem value="0">
                All
            </MenuItem>
            {allcoopBranches.coopBranches && allcoopBranches.coopBranches.map((coopBranch, index) =>
                <MenuItem value={coopBranch.id}  key ={`${index}-new-group`}>
                {`${coopBranch.cooperative_branch_name}`}
                </MenuItem>
            )}
            </Select>
        </FormControl>
    <div>
        Please note that the selected farmers will be assigned / re-assigned to the farmers group selected above.
    </div>
    </SimpleForm>
  );
};

export const BulkAssignmentToolbar = props => {
  const { allcoopBranches,isXSmall, ...rest } = props;
  const { handleDialogClose, selectedIds, unselectAll, ...other } = rest;
  return (
    <Toolbar {...other}>
      <NoButton
        label="No"
        icon={null}
        isXSmall={isXSmall}
        className={clsx('small--btn')}
        onClick={handleDialogClose}
      />
      <AssignButton {...rest} allcoopBranches = {allcoopBranches}/> 
    </Toolbar>
  )  
};

const AssignButton = props => {
  const refresh = useRefresh();
  const notify = useNotify();
  const {
    handleDialogClose,
    mutationMode, 
    record,
    resource,
    validating,
    unselectAll,
    selectedIds,
    allcoopBranches,
    ...rest 
  } = props;

  const token = sessionStorage.getItem('token');
  const [ loading, setLoading ] = React.useState(false);
  let isLoaded = React.useRef(false);

  React.useEffect(() => {
    isLoaded.current = true; 
    return () => { isLoaded.current = false; };
  }, [isLoaded]); 

  const handleSend = React.useCallback(() => {
    const memberData = {
      "groupId": allcoopBranches.selected, 
      "farmers": selectedIds.length >0?selectedIds:[],
    };
    if (!!selectedIds && selectedIds.length > 0) {
        apiFullCall(
            memberData, 
            token, 
            'post', 
            `savegroupassignments/` 
        ).then(res => {
            if (res) {
                if (isLoaded.current) setLoading(true);
                const { status } = res;
                console.log(res)
                if (status === 200 || status === 201) {
                    refresh();
                    unselectAll(resource);
                    notify(`Successfully assigned farmers to groups`, 'info');
                } else if (status === 400) {
                    notify(`Please check again and resend.`, 'warning');
                } else if (status >= 500) {
                    notify(`Server error, please try again later.`, 'warning');          
                };
                if (isLoaded.current) setLoading(false);
            };
        }).catch(
            error => console.error(`Error Ocurred`, error)
        );
    } else {
      notify(`Please select at least one item`, 'warning');
    };
  },[selectedIds,notify,unselectAll,resource,refresh,token,allcoopBranches]); 
  const NoIcon = () => null;
  return (
    <SaveButton
      {...rest}
      icon={<NoIcon />}
      label={loading ? "" : "Yes"}
      submitOnEnter={false}
      saving={loading}
      onSave={handleSend} 
    />
  );
};
