import React, { FC } from 'react';
import { Link, usePermissions } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import { FieldProps, Provider } from '../../../types';

import * as P from '../../../allPermissions';

const useStyles = makeStyles({
  root: {
    color: '#3f51b5',
  },
});

export const ProviderLinkField: FC<FieldProps<Provider>> = props => {
  const classes = useStyles();
  const { permissions } = usePermissions();
  
  const { record, resource } = props;

  const isProvider = resource === "provider";        // Provider
  const isSuperagent = resource === "superagents";   // Superagent

  const accessDeniedToProviders = P.cantAddOrEditproviders(permissions) && isProvider;
  const accessDeniedToSuperagents = P.cantAddOrEditSuperagents(permissions) && isSuperagent;

  const noLink = accessDeniedToProviders || accessDeniedToSuperagents;

  if (!record) {
    return null;
  };

  // re-mapped for "super-agents"
  // const url = resource === "superagents" ? resource : "provider";

	return noLink ? 
    <span>{record.business_name}</span>
  : (
    <Link to={`/${resource}/${record.id}`} className={classes.root}>
			{record.business_name}
    </Link>
  );
};

ProviderLinkField.defaultProps = {
  source: 'business_name',
  label: 'Name',
};
