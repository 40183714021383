import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import { FetchProgress } from 'layout';

const AgentList = React.lazy(() => import("../agents/AgentList"));
const FarmerList = React.lazy(() => import("../farmers/FarmerList"));
const MemberList = React.lazy(() => import("../groupmembers/MemberList"));
const UserDelete = React.lazy(() => import("../userdialogs/UserDeleteModal"));
const UserStatus = React.lazy(() => import("../userdialogs/UserStatusModal"));
const AccountantList = React.lazy(() => import("../accountants/AccountantList"));
const CoordinatorList = React.lazy(() => import("../coordinators/CoordinatorList"));

const UserList = props => {
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  let programid = sessionStorage.getItem('userProgramId');

  const [openDelete, setOpenDelete] = React.useState(false);
  const [openStatus, setOpenStatus] = React.useState(false);
  const [data, setData] = React.useState({});

  const { permissions, ...rest } = props;

  // handles modals open/close
  const handleOpenDelete = () => setOpenDelete(!openDelete);
  const handleOpenStatus = () => setOpenStatus(!openStatus);

  // handles fetch of single record
  const handleData = singleRecord => setData({ ...data, ...singleRecord });

  const moreProps = {
    handleOpenDelete: handleOpenDelete,
    permissions: permissions,
    handleData: handleData,
    programid: programid,
    isXSmall: isXSmall,
    ...rest,
  };

  const memberProps = {
    handleOpenStatus: handleOpenStatus,
    ...moreProps
  };

  return (
    <React.Suspense fallback={<FetchProgress title="Loading..." top={true} /> }>
      <Switch>
        <Route exact path="/users">
          <FarmerList {...moreProps} />
        </Route>
        <Route path="/accountants">
          <AccountantList {...moreProps} />
        </Route>
        <Route path="/agents">
          <AgentList {...moreProps} />
        </Route>
        <Route path="/members">
          <MemberList {...memberProps} />
          <UserStatus        
            {...props} 
            record={data} 
            open={openStatus}
            isXSmall={isXSmall}
            onClose={handleOpenStatus}
          />
        </Route>
        <Route path="/coordinators">
          <CoordinatorList {...moreProps} />
        </Route>
      </Switch>
      <UserDelete        
        {...props} 
        record={data} 
        open={openDelete}
        isXSmall={isXSmall}
        onClose={handleOpenDelete}
      />
    </React.Suspense>
  )
};

export default UserList;
