import React from 'react';
import clsx from 'clsx';
import Downshift from 'downshift';
import { Box, MenuItem, Paper  } from '@material-ui/core';
import { useRefresh, useNotify } from 'react-admin';

import { AkbInput, AkbTextField, SectionTitle, trim } from 'modules/utils';
import { apiFullCall } from 'modules/apiHelper';
import { boxNumber } from 'modules/validation';


export const AddressFields = (props: any) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const {
    token,
    state,
    record,
    classes,
    isFarmer,
    setState,
    rafields,
    setRaFields,
    filterValue,
    hasLocation,
    setCoordinates,
    setFilterValue,
    setHasLocation,
  } = props;

  let userId;
  if (!!record) {
    userId = record['id']
  };

  /* if record (ra) has key 'id', use this check to render guide messages */
  let isRegistered: boolean = record && Object.prototype.hasOwnProperty.call(record, 'id');

  const [getDistrict, setGetDistrict] = React.useState({ districtList: [] });
  
  const [filterDistrict, setFilterDistrict] = React.useState({
    districtid: '',
    districtname: '',
  });

  let isLoaded = React.useRef(true);

  // onChange method for the input field
  const inputOnChangeDistrict = React.useCallback(e => {
    if (!e.target.value) {
      return null;
    }
    searchCallDistrict(e.target.value);
  }, []); // eslint-disable-line
  
  const searchCallDistrict = value => {
    let district = trim(value);

    if (district.length > 2) {
      apiFullCall('', token, 'get', 'district/?search=' + district)
        .then(res => {

          if (res) {
            // destructure response
            const { status, body } = res;

            if (status === 200 || status === 201) {

              setGetDistrict(prevState => ({ ...prevState, districtList: body['results'] }))

            }
          };

        })
        .catch(error =>
          console.error('Error while searching for location:', error)
        );

    } else {
      return null
    }

  };

  // input field for the <Downshift /> component
  const downshiftOnChangeDistrict = React.useCallback(value => {
    setFilterDistrict({ ...value });
    setFilterValue({
      id: '',
      villagename: '',
      parishname: '',
      subcountyname: '',
      countyname: '',
      districtname: '',
      regionname: '',
    });
  }, []); // eslint-disable-line  

  // onChange method for the input field
  const inputOnChangeVillage = React.useCallback((e, id) => {
    if (!e.target.value) {
      return null;
    }

    searchCallVillage(e.target.value, id); // pass districtid
  }, []); // eslint-disable-line
  
  const searchCallVillage = (value, id) => {
    let location = trim(value);

    if (location.length > 2) {

      if (!!id) {

        // search with districtid, if defined
        apiFullCall('', token, 'get', `locationsearch/?districtid=${id}&search=${location}`)
        .then(res => {

          if (res) {
            // destructure response
            const { status, body } = res;

            if (status === 200 || status === 201) {

              setState(prevState => ({ ...prevState, address: body['results'] }))

            }
          };

        })
        .catch(error =>
          console.error('Error while searching for location:', error)
        );

      } else {

        // plain search, if no district is selected
        apiFullCall('', token, 'get', `locationsearch/?search=${location}`)
        .then(res => {
          if (res) {
            // destructure response
            const { status, body } = res;

            if (status === 200 || status === 201) {

              setState(prevState => ({ ...prevState, address: body['results'] }))

            }
          }
        })
        .catch(error =>
          console.error('Error while searching for location:', error)
        );

      }
      
    } else {
      return null
    }

  };

  // input field for the <Downshift /> component
  const downshiftOnChangeVillage = React.useCallback(value => {
    setFilterValue({ ...value });
  }, []); // eslint-disable-line

  React.useEffect(() => {

    if (isLoaded && userId) {

      apiFullCall(
        '', 
        token,
        'get', 
        `usersview/${userId}` 
      ).then(res => {

        if (res) {

          const { status, body } = res;

          if (status === 200 || status === 201) {

            const { 
              villageid,
              villagename,
              parishname,
              subcountyname,
              countyname,
              districtname,
              regionname,
              box_number,
              physical_address,
              ...rest // eslint-disable-line
            } = body;

            /* User has an address, utilized if we update location*/
            setHasLocation(!hasLocation);

            // pass this location object to form, onmount
            downshiftOnChangeVillage({ 
              id: villageid,
              villagename,
              parishname,
              subcountyname,
              countyname,
              districtname,
              regionname,
            });

            // set fields, to be used in API call (save buttons)
            // check if not "null" to avoid setting value=null in AkTextFields
            if (!!villageid) {

              setRaFields({
                ...rafields,
                box_number: !!box_number ? box_number : "", 
                physical_address: !!physical_address ? physical_address : "",
                villageid: villageid 
              });

            };
            
          // get farmer cards -----

          } else if (status === 404) { // Not found

            notify('post.notification.include_address', 'info', {
              smart_count: 1,
            });

            const geolocation = JSON.parse(sessionStorage.getItem('geolocation'));

            // update coordinates
            setCoordinates({ ...geolocation });
            
          } else if (status >= 500) {

            if (!body) { // we have "null" thus this user has 2 or more location records

              apiFullCall(
                '', 
                token,
                'get', 
                `usersaddress/?user_id=${userId}` 
              ).then(res => {

                if (res) {
                  const { status, body } = res;

                  if (status === 200 || status === 201) {
                    
                    const { results } = body;
                                        
                    results
                      // we remove results[0] and delete the rest
                      && results.shift()
                        // The remaining locations are in results[]
                        && results.map(location =>
                          // and loop through the list and "delete" other locations
                          apiFullCall(
                            '', 
                            token,
                            'delete', 
                            `usersaddress/${location['id']}` 
                          ).then(
                            res => res
                          ).catch(
                            error => console.error('Error while saving:', error)
                          ) 
                        );

                    // We trigger a reload state to render "only" location
                    refresh();  
                    
                  } else {

                    notify(`Server error, please try again later.`, 'warning');
                  };

                };

              }).catch(
                error => console.error('Error while fetching address:', error)
              );

            } else {

              notify(`Server error, please try again later.`, 'warning');          
            }
          };

        };

      }).catch(
        error => console.error('Error while fetching address:', error)
      );      
    }
    // clean up API call, on unmount
    return function cleanup() { isLoaded.current = false; }
  }, [downshiftOnChangeVillage, userId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isRegistered && <SectionTitle label="resources.users.fieldGroups.home_address" className='AkRegisterForm-title' />}
      {/* 
        * SimpleForm expects Input or Field components as children.
        * Explained here: https://marmelab.com/react-admin/CreateEdit.html#the-simpleform-component. 
        * So it's normal that you see warnings because we passed <Box>
        */}
      <Box
        display={{ md: 'flex' }}
        className={clsx('width-100', 'AkRegisterForm-body')}
      >
        <Box display={{ md: 'flex' }} flex={2} mb={{ xs: '25px' }} className={clsx('address')}>
          <AkbTextField
            value={rafields.box_number}                  
            name="box_number"
            label="Box address"
            className={classes.input}
            validate={boxNumber}
            placeholder="1234, Kampala"
            helperText="Wrong box address"
            onChange={event => setRaFields({ ...rafields, box_number: event.target.value })}
          />
          <AkbTextField
            value={rafields.physical_address}                  
            name="physical_address"
            label="Physical address"
            className={classes.input}
            placeholder="Ntinda, Kampala"
            onChange={event => setRaFields({ ...rafields, physical_address: event.target.value })}
          />
        </Box>
      </Box>
      {!isRegistered
        ? <SectionTitle label="Location" className='AkRegisterForm-title' />
        : isFarmer
          ? <SectionTitle label="resources.users.fieldGroups.farm_address" className='AkRegisterForm-title' />
          : <SectionTitle label="resources.users.fieldGroups.home_district" className='AkRegisterForm-title' />}
      <Box
        mb={{ xs: '20px' }}
        display={{ md: 'flex' }} 
        className={clsx('address','search','width-100','AkRegisterForm-body')}
      >
        <Box flex={3}>
          <Downshift
            onChange={downshiftOnChangeDistrict}
            itemToString={item => (item ? item.districtname : '')}
          >
            {({ selectedItem, getInputProps, getItemProps, highlightedIndex, isOpen, inputValue, getLabelProps }) => (
              <div 
                className={clsx(
                  'font-sm',
                  classes.container,
                  { 'stretch--district': isOpen }
                )}
              >
                {AkbInput({
                  fullWidth: true,
                  classes,
                  name: 'search-district',
                  label: 'Search by District',
                  InputProps: getInputProps({
                    onChange: inputOnChangeDistrict,
                  }),
                })}
                {isOpen && (
                  <Paper square className={clsx('select-location')}>
                    {getDistrictSuggestions(getDistrict.districtList, inputValue).map(
                      (item, index) => {
                        const { districtname } = item;

                        const isHighlighted = highlightedIndex === index;
                        const isSelected =
                          String(selectedItem || '').indexOf(districtname) >
                          -1;

                        return (
                          <MenuItem
                            {...getItemProps({ key: index, index, item })}
                            selected={isHighlighted}
                            component="div"
                            style={{
                              fontWeight: isSelected ? 500 : 400,
                            }}
                          >
                            {districtname}
                          </MenuItem>
                        );
                      }
                    )}
                  </Paper>
                )}
              </div>
            )}
          </Downshift>           
          <Downshift
            onChange={downshiftOnChangeVillage}
            itemToString={item => (item ? item.villagename : '')}
          >
            {({ selectedItem, getInputProps, getItemProps, highlightedIndex, isOpen, inputValue, getLabelProps }) => (
              <div 
                className={clsx(
                  'font-sm',
                  classes.container,
                  { 'stretch': isOpen }
                )}
              >
                {AkbInput({
                  fullWidth: true,
                  classes,
                  name: 'search-village',
                  label: 'Search by Village',
                  InputProps: getInputProps({
                    onChange: e => inputOnChangeVillage(e, filterDistrict['districtid']),
                  }),
                })}
                {isOpen && (
                  <Paper square className={clsx('select-location')}>
                    {getVillageSuggestions(state.address, inputValue).map(
                      (item, index) => {
                        const {
                          villagename,
                          parishname,
                          subcountyname,
                          districtname,
                        } = item;
                        const isHighlighted = highlightedIndex === index;
                        const isSelected =
                          String(selectedItem || '').indexOf(villagename) >
                          -1;

                        return (
                          <MenuItem
                            {...getItemProps({ key: index, index, item })}
                            selected={isHighlighted}
                            component="div"
                            style={{
                              fontWeight: isSelected ? 500 : 400,
                            }}
                          >
                            {villagename} ({parishname})<br />
                            {subcountyname}, {districtname}
                          </MenuItem>
                        );
                      }
                    )}
                  </Paper>
                )}
              </div>
            )}
          </Downshift>   
        </Box>
        {/* <Box flex={3}></Box> */}
      </Box>
      <Box 
        display={{ md: 'flex' }}
        className={clsx('width-100', 'AkRegisterForm-body')}
      >
        <SectionTitle 
          label='*fields (below) will be updated when you "Search by Village"'
          className={clsx(
            { 'hidden': !filterValue.id },
            classes.details
          )}
        />
      </Box>
      <Box 
        display={{ md: 'flex' }}
        className={clsx('address','results','width-100','AkRegisterForm-body')}
      >
        <Box flex={3}>
          <AkbTextField
            type="hidden"
            disabled={true}
            value={filterValue.id}                  
            name="village_id"
            label="id"
            className={clsx('unique-field', classes.input)}
          />
          <AkbTextField
            disabled={true}
            value={filterValue.villagename}
            name="villagename"
            label="Village"
            className={clsx(
              { 'hidden': !filterValue.id },
              classes.input
            )}
          />
          <AkbTextField
            disabled={true}
            value={filterValue.parishname}
            name="parishname"
            label="Parish"
            className={clsx(
              { 'hidden': !filterValue.id },
              classes.input
            )}
          />
          <AkbTextField
            disabled={true}
            value={filterValue.subcountyname}
            name="subcountyname"
            label="Subcounty"
            className={clsx(
              { 'hidden': !filterValue.id },
              classes.input
            )}
          />
          <AkbTextField
            disabled={true}
            value={filterValue.countyname}
            name="countyname"
            label="County"
            className={clsx(
              { 'hidden': !filterValue.id },
              classes.input
            )}
          />
          <AkbTextField
            disabled={true}
            value={filterValue.districtname}
            name="districtname"
            label="District (selected)"
            className={clsx(
              { 'hidden': !filterValue.id },
              classes.input
            )}
          />
          <AkbTextField
            disabled={true}
            value={filterValue.regionname}
            name="regionname"
            label="Region"
            className={clsx(
              { 'hidden': !filterValue.id },
              classes.input
            )}
          />           
        </Box>
      </Box>
    </>
  )
};

const getDistrictSuggestions = (address = [], inputValue) => {
  let count = 0;

  return address.filter(({ districtname }) => {
    const keep =
      (!inputValue ||
        String(districtname)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) !== -1) &&
      count < 10; // max. number of suggestion within list

    if (keep) {
      count += 1;
    }

    // return just the first ten,
    // helps improve performance
    return keep;
  });
};

const getVillageSuggestions = (address = [], inputValue) => {
  let count = 0;

  return address.filter(({ villagename }) => {
    const keep =
      (!inputValue ||
        String(villagename)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) !== -1) &&
      count < 10; // max. number of suggestion within list

    if (keep) {
      count += 1;
    }

    // return just the first ten,
    // helps improve performance
    return keep;
  });
};
