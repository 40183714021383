import React from 'react';
import clsx from 'clsx';
import { useFormState } from 'react-final-form';
import { SaveButton, Toolbar, useRefresh, useNotify } from 'react-admin';

import { ApproveButton } from '../../../layout';
import { apiFullCall } from '../../apiHelper';


/* Handling "reject" CREDIT request */


export const CreditOrderRejectToolbar = props => {

  // destructure to pass directly to <ApproveButton>
  const { isXSmall, ...rest } = props;
  const { handleCloseRejectDialog, ...other } = rest;

  return (
    <Toolbar {...other}>
      <ApproveButton
        label="No"
        icon={null}
        isXSmall={isXSmall}
        className={clsx('small--btn')}
        onClick={handleCloseRejectDialog}
      />
      <RejectButton {...rest} /> 
    </Toolbar>
  )  
};

const RejectButton = props => {
  const formState = useFormState();
  const refresh = useRefresh();
  const notify = useNotify();

  // destructure to pass "rest" to <SaveButton>
  const { handleCloseRejectDialog, onClose, mutationMode, record, validating, ...rest } = props;

  // destructure "reason" to pass to API call
  const { reason } = formState && formState.values;

  const token = sessionStorage.getItem('token');

  const [ loading, setLoading ] = React.useState(false); // eslint-disable-line

  let isLoaded = React.useRef(false);

  React.useEffect(() => {
    /* Cleanup useEffect */

    isLoaded.current = true; // set to true on mount...
    return () => { isLoaded.current = false; }; // ... and to false on unmount
  }, [isLoaded]); // eslint-disable-line react-hooks/exhaustive-deps
  

  const rejectionData = {
    reason: reason,
    status: 'rejected',
    request_id: record && record['id'],
  };

  const handleRejection = React.useCallback(() => {

    // Double-check to ensure that "reason" and "id" are defined or not empty
    if (!!reason && !!record['id']) {

      apiFullCall(
        rejectionData, 
        token, 
        'post', 
        `adminresponceonrequest/` 
      ).then(res => {
        if (res) {

          if (isLoaded.current) setLoading(true);

          const { status } = res;

          if (status === 200 || status === 201) {

            // when successfully submitted
            notify('The request has been rejected', 'info');

            // go back to requests list
            handleCloseRejectDialog();
            onClose();
            refresh();
            
          } else if (status === 400) {
            notify(`Please check again and resend.`, 'warning');
          } else if (status >= 500) {
            notify(`Server error, please try again later.`, 'warning');          
          };

          if (isLoaded.current) setLoading(false);

        };

      }).catch(
        error => console.error('Error while rejecting order:', error)
      );

    } else {

      // inform user to enter a "reason"
      notify(`Please provide a reason`, 'warning');
    };

  }, [rejectionData]); // eslint-disable-line react-hooks/exhaustive-deps

  // define a component that returns "null"
  // And pass it to <SaveButton> to override the "save" icon
  const NoIcon = () => null;

  return (
    <SaveButton
      {...rest}
      icon={<NoIcon />}
      label={loading ? "" : "Yes"}
      submitOnEnter={false}
      saving={loading}
      onSave={handleRejection} 
    />
  );
};
