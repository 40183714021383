import React from 'react';
import { ReferenceField, TextField } from 'react-admin';

export const NumberField = props => {
  const { record } = props;

  let m = 1;
  m = +m;

  return record ? (
    <span>{m}</span>
  ) : null;
};

NumberField.defaultProps = {
  label: 'No.',
  textAlign: 'left',
};

export const VillageField = props => {
  
  let Village = () => (
    <ReferenceField source="id" reference="usersview" link={false} {...props}>
      <TextField source="villagename" />
    </ReferenceField>
  );

  return <Village />;

};

VillageField.defaultProps = {
  label: 'Village',
  textAlign: 'left',
};


export const DistrictField = props => {
  
  let District = () => (
    <ReferenceField source="id" reference="usersview" link={false} {...props}>
      <TextField source="districtname" />
    </ReferenceField>
  );

  return <District />;

};

DistrictField.defaultProps = {
  label: 'District',
  textAlign: 'left',
};


export const CoordinatorDistrictField = props => {
  
  let CoordinatorDistrict = () => (
    <ReferenceField source="cordinator_id" reference="programcordinator" link={false} {...props}>
      <TextField source="cordinator_areas[0].district_name" />
    </ReferenceField>
  );

  return <CoordinatorDistrict />;

};

CoordinatorDistrictField.defaultProps = {
  label: 'Area(s)',
  textAlign: 'left',
};