import React from 'react';
import clsx from 'clsx';
import Downshift from 'downshift';
import { MenuItem, Paper } from '@material-ui/core';
import { AkbInput, apiFullCall, trim } from '../../../modules';

export const SearchDistrictField = (props: any) => {
  const token = sessionStorage.getItem('token');

  const { classes, setSelectedDistrict } = props;

  const districtObject = { districtid: '0', districtname: '' };
  const [showIcon, setShowIcon] = React.useState<boolean>(false);
  const [getDistrict, setGetDistrict] = React.useState<object | any>({ districtList: [] });

  // onChange method for the input field
  const inputOnChangeDistrict = React.useCallback(e => {
    if (!e.target.value) {
      return null;
    }
    searchCallDistrict(e.target.value);
  }, []); // eslint-disable-line
  
  const searchCallDistrict = value => {
    let district = trim(value);

    if (district.length > 2) {
      apiFullCall('', token, 'get', 'district/?search=' + district)
        .then(res => {

          if (res) {
            // destructure response
            const { status, body } = res;

            if (status === 200 || status === 201) {

              setGetDistrict(prevState => ({ ...prevState, districtList: body['results'] }))

            }
          };

        })
        .catch(error =>
          console.error('Error while searching for location:', error)
        );

    } else {
      return null
    }

  };
  
  // input field for the <Downshift /> component
  const downshiftOnChangeDistrict = React.useCallback(value => {
    setSelectedDistrict({ ...value });
    setShowIcon(!showIcon);
  }, []); // eslint-disable-line  
  
  const downshiftItem = item => item ? item.districtname : '';

  const handleReset = () => {
    setShowIcon(false);
    // restore initial state
    setSelectedDistrict(districtObject);
  };

  return (
    <Downshift /* Search by district */ 
      onChange={downshiftOnChangeDistrict}
      itemToString={downshiftItem}
    >
      {({ selectedItem, getInputProps, getItemProps, highlightedIndex, isOpen, inputValue, clearSelection }) => (
        <div className={clsx( 'main-filter' )}>
          {AkbInput({
            classes,
            show: showIcon,
            fullWidth: true,
            name: 'search-district',
            label: 'Search District',
            InputProps: getInputProps({
              onReset: handleReset,
              onClear: clearSelection,
              onChange: inputOnChangeDistrict
            }),
          })}
          {isOpen && (
            <Paper square className={clsx('select-location')}>
              {getDistrictSuggestions(getDistrict.districtList, inputValue).map(
                (item, index) => {
                  const { districtname } = item;

                  const isHighlighted = highlightedIndex === index;
                  const isSelected =
                    String(selectedItem || '').indexOf(districtname) >
                    -1;

                  return (
                    <MenuItem
                      {...getItemProps({ key: index, index, item })}
                      selected={isHighlighted}
                      component="div"
                      style={{
                        fontWeight: isSelected ? 500 : 400,
                      }}
                    >
                      {districtname}
                    </MenuItem>
                  );
                }
              )}
            </Paper>
          )}
        </div>
      )}
    </Downshift>
  );
};

const getDistrictSuggestions = (address = [], inputValue) => {
  let count = 0;

  return address.filter(({ districtname }) => {
    const keep =
      (!inputValue ||
        String(districtname)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) !== -1) &&
      count < 10; // max. number of suggestion within list

    if (keep) {
      count += 1;
    }

    // return just the first ten,
    // helps improve performance
    return keep;
  });
};
