import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';

import { FetchProgress } from '../../../layout';

const ProgramList = React.lazy(() => import("../programs/ProgramList"));
const FunderList = React.lazy(() => import("../funders/FunderList"));

const SettingList = props => {
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  let programid = sessionStorage.getItem('userProgramId');

  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState({});

  const { permissions, ...rest } = props;

  // handles "delete" model open/close
  const handleOpenModal = () => setOpen(!open);

  // handles fetch of single record
  const handleData = singleRecord => setData({ ...data, ...singleRecord });

  const moreProps = {
    handleOpenModal: handleOpenModal,
    permissions: permissions,
    handleData: handleData,
    programid: programid,
    isXSmall: isXSmall,
    ...rest,
  };

  return (
    <React.Suspense fallback={<FetchProgress title="Loading..." top={true} /> }>
      <Switch>
        <Route exact path="/programs">
          <ProgramList {...moreProps} />
        </Route>
        <Route path="/funders">
          <FunderList {...moreProps} />
        </Route>
      </Switch>
    </React.Suspense>
  )
};

export default SettingList;
