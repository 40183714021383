import React from 'react';
import clsx from 'clsx';
import format from 'date-fns/format';
import { CardContent, CardHeader } from '@material-ui/core';


/* Show 'farmer' contact */

export const RequestWithContactField = props => {

  const { farmer, location } = props; // eslint-disable-line

  return (
    <CardHeader
      className={clsx('requested-by-title')}
      title={
        <>
          <span className={clsx('intro')}>Names:&nbsp;</span>
          <span className={clsx('requester-names')}>
            {farmer.farmer_full_name}&nbsp;({farmer.farmer_username})
          </span>
        </>
      }
      subheader={
        <>
          <span className={clsx('intro')}>Location:&nbsp;</span>
          <span className={clsx('requester-location')}>
            {location.villagename}&nbsp;(Village)&#44; {location.districtname}&nbsp;(District)
          </span>
        </>
      }
    />
  )
};

/* Don't show 'farmer' contact */

export const RequestWithNoContactField = () => (
  <CardContent className={clsx('provider-requests-notice')}>
    <p className={clsx('details')}>
      <span className={clsx('intro')}>Note:&nbsp;</span>
      Farmer contacts are ONLY shown when you accept this order.
    </p>
  </CardContent>
);

/* Show 'approval' section */

export const RequestWithApprovedField = (props) => {

  const { approval_date, approved_by_contact, approved_by_full_name, reason } = props;

  if (!props) return null;

  return (
    <CardHeader
      className={clsx('requested-by-title')}
      title={
        <>
          <span className={clsx('intro')}>Names:&nbsp;</span>
          <span className={clsx('requester-names')}>
            {approved_by_full_name} ({approved_by_contact})
          </span>
        </>
      }
      subheader={
        <>
          <span className={clsx('intro')}>Date:&nbsp;</span>
          <span className={clsx('requester-location')}>
            {format(new Date(approval_date), 'dd/MM/yyyy')}
          </span>
          <p className={clsx('first')}>
            <span className={clsx('intro')}>Reason:&nbsp;</span>
            <span className={clsx('requester-location')}>
              {reason ? reason : 'none'}            
            </span>          
          </p>
        </>
      }
    />
  )
};

/* Show 'provider' section */

export const RequestWithProviderField = (props) => {

  const { business_name, primary_contact, village } = props;

  if (!props) return null;

  return (
    <CardHeader
      className={clsx('requested-by-title')}
      title={
        <>
          <span className={clsx('intro')}>Names:&nbsp;</span>
          <span className={clsx('requester-names')}>{business_name} ({primary_contact})</span>
        </>
      }
      subheader={
        <>
          <span className={clsx('intro')}>Location:&nbsp;</span>
          <span className={clsx('requester-location')}>{village}&nbsp;(Village)</span>
        </>
      }
    />
  )
};
