import React from 'react';
import clsx from 'clsx';
import { Link as CardLink } from 'react-admin';
import { Card, Box, Divider } from '@material-ui/core';

import { cardInnerStyles } from 'stylesheets/jss';
import { CircularProgress } from '../loaders';
import { AppContext } from 'context/app';

interface Props {
  to?: string;
  title?: string;
  loading?: boolean;
  icon: React.FC<any>;
  switching?: boolean;
  subtitle?: string | number;
  subtitle2?: string | number;
  children?: React.ReactNode;
}

export const CardWithIcon: React.FC<Props> = props => {
  const classes = cardInnerStyles(props);
  const { to, children } = props;
  
  return (
    <Card className={classes.card}>
      {!to
        ? <CardContent classes={classes} {...props} />
        : <CardLink to={to} className={clsx(classes.stretch, "MuiButton-textPrimary")}>
            <CardContent classes={classes} {...props} />
          </CardLink>}
      {children && <Divider />}
      {children}
    </Card>
  );
};

const CardContent = props => {
  const { switchCardProgram: switching } = React.useContext(AppContext);
  const { children, classes, icon, loading, title, subtitle, subtitle2 } = props;

  return (
    <div className={clsx(classes.main, !!children && (!!subtitle2?classes.pushDownDouble:classes.pushDownSingle))}>
      <Box className={clsx(classes.icon, !!subtitle2 && classes.icon2 )}>
        {React.createElement(icon, { fontSize: 'large' })}
      </Box>
      <Box textAlign="right">
        <div className={classes.title}>{title}</div>
        {(loading || switching)
          ? <CircularProgress className={clsx('sm-wide')} />
          : !subtitle2
            ? <div className={classes.header}>{subtitle || ' '}</div>
            : <>
                <span className={classes.header}>{subtitle || ' '}</span><br/>
                <span className={classes.subHeader}>{subtitle2 || ' '}</span>
              </>
        }          
      </Box>
    </div>
  );
};