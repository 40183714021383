import React from 'react';
import clsx from 'clsx';
import { Box } from '@material-ui/core';
import { useTranslate } from 'react-admin';

import { Stats } from './Stats';
import { TopFilters } from './filters';
import { Charts, LoadingGraphs } from './graphs';

import { UpdatesBar } from 'layout';
import { apiFullCall } from 'modules';
import { AppContext, SuperuserDashboardContext } from 'context';

import * as P from 'allPermissions';

const AllGraphs = React.lazy(() => import("./graphs"));

const SuperuserDashboard = props => {
  const {
    isSmall,
    isXSmall,
    anyUpdates,
    sidebarOpen,
    permissions,
    unapprovedProviderRequests,
    ...rest // eslint-disable-line
  } = props;

  const translate = useTranslate();

  const { setSwitchCardProgram, switchChartsProgram } = React.useContext(AppContext);

  const districtObject = { districtid: '0', districtname: '' };
  const [selectedDistrict, setSelectedDistrict] = React.useState(districtObject);
  const { districtid: activeDistrict } = selectedDistrict;

  const isSuperuser = P.superuser(permissions);
  const isSuperagent = P.superagent(permissions);
  const superagentId = sessionStorage.getItem('id');
  const [selectedAgent, setSelectedAgent] = React.useState('0');
  const [loading, setLoading] = React.useState(false);

  const totalsObject = { agents: 0, farmers: 0, providers: 0, transactions: 0, transactions_amount: 0 };
  const [totals, setTotals] = React.useState(totalsObject);

  /* To optimize performance, we target the first graph - with farmer trends */
  const [loadingChart, setLoadingChart] = React.useState(false);

  const token = sessionStorage.getItem('token');
  const programid = sessionStorage.getItem('userProgramId');

  let isLoaded = React.useRef(true);

  /* fetch card totals */

  React.useEffect(() => {

    if (!!activeDistrict && !!selectedAgent && !!programid && permissions) {
  
      // if superagent is logged in
      if ( isSuperagent ) {

        apiFullCall(
          '',
          token,
          'get',
          `programnumbers/?districtid=${activeDistrict}&programid=${programid}&agentid=${superagentId}`
        ).then(res => {
    
          if (res) {
    
            if (isLoaded.current) setLoading(true);
    
            const { status, body } = res;
    
            if (status === 200 || status === 201) {
    
              const { agents, farmers, providers, transactions, transactions_amount } = body;
    
              setTotals({
                ...totals,
                agents: agents,
                farmers: farmers,
                providers: providers,
                transactions: transactions,
                /* if null, display 0 */
                transactions_amount: !!transactions_amount ? transactions_amount : 0,
              });
    
              /* Reset to display values */
              setSwitchCardProgram(false);
    
            };
    
            if (isLoaded.current) setLoading(false);
    
          };
    
        }).catch(error =>
          console.error('Error while fetching the "program figures"', error)
        );

      } else {

        apiFullCall(
          '',
          token,
          'get',
          `programnumbers/?districtid=${activeDistrict}&programid=${programid}&agentid=${selectedAgent}`
        ).then(res => {
    
          if (res) {
    
            if (isLoaded.current) setLoading(true);
    
            const { status, body } = res;
    
            if (status === 200 || status === 201) {
    
              const { agents, farmers, providers, transactions, transactions_amount } = body;
    
              setTotals({
                ...totals,
                agents: agents,
                farmers: farmers,
                providers: providers,
                transactions: transactions,
                /* if null, display 0 */
                transactions_amount: !!transactions_amount ? transactions_amount : 0,
              });
    
              /* Reset to display values */
              setSwitchCardProgram(false);
    
            };
    
            if (isLoaded.current) setLoading(false);
    
          };
    
        }).catch(error =>
          console.error('Error while fetching the "program figures"', error)
        );

      };

    };

    // clean up API call, on unmount
    return function cleanup() { 
      isLoaded.current = false; 
    };

  }, [isLoaded, isSuperagent, activeDistrict, selectedAgent, programid, permissions]); // eslint-disable-line react-hooks/exhaustive-deps 
  
  return (
    <SuperuserDashboardContext.Provider value={{
        Charts: Charts,
        totals: totals,
        loading: loading,
        isSmall: isSmall,
        isXSmall: isXSmall,
        sidebarOpen: sidebarOpen,
        isSuperuser: isSuperuser,
        isSuperagent: isSuperagent,
        superagentId: superagentId,
        loadingChart: loadingChart,
        selectedAgent: selectedAgent,
        activeDistrict: activeDistrict,
        setLoadingChart: setLoadingChart,
        setSelectedAgent: setSelectedAgent,
        setSelectedDistrict: setSelectedDistrict,
        ...props
      }}
    >
      <UpdatesBar
        link="/providerservice" // formerly /systemproviderservices
        show={anyUpdates}
        cardMessage={translate(
          'pos.dashboard.pending_provider_requests.notice',
          { smart_count: unapprovedProviderRequests }
        )}
      />
      <Box width={{ xs: '100%' }} className={clsx({ 'with-closed-sidebar': !sidebarOpen })}>
        {!isSmall && <TopFilters />   /* Top filters */ }
        <Stats                        /* Main dashboard cards */ />
        {!isSmall && 
          <React.Suspense fallback={<LoadingGraphs />}>
            <AllGraphs switching={switchChartsProgram} />
          </React.Suspense>           /* All the graphs/charts */ }
      </Box>
    </SuperuserDashboardContext.Provider>
  );
};

export default SuperuserDashboard;
