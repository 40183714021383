import React, { useState } from 'react';
import { useRefresh } from 'react-admin';
import readXlsxFile from 'read-excel-file';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  CircularProgress,
} from '@material-ui/core';

import apiCall from 'modules/apiHelper';
import { requestStyles } from 'modules/stylesheets';
import Autocomplete from '@material-ui/lab/Autocomplete';


const UserUpload = () => {
  const refresh = useRefresh();
  const classes = requestStyles();

  const token = sessionStorage.getItem('token');

  var theJsonArray = [];

  const [loadTable, setLoaderState] = useState(false);
  const [famersrecord, setFarmerTable] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [program, setProgram] = useState({ id: 0 });
  const [superagents, setSuperagents] = useState([]);
  const [superagent, setSuperagent] = useState({ id: 0 });
  const [cooperatives, setCooperatives] = useState([]);
  const [cooperative, setCooperative] = useState([]); // eslint-disable-line
  const [coopBranchess, setCooperativeBranches] = useState([]);
  const [coopBranch, setCooperativeBranch] = useState([]);
  const [branchUser, setBranchUser] = useState([]);
  const [category, setCategory] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [buttontext, setButtontext] = React.useState("CHOOSE A FILE");

  //array if user names
  const [usernames, setUserNames] = React.useState([]);

  // File Upload
  const [file, SetFile] = useState(null);
  const [error2, SetError2] = useState(false);
  const [error1, SetError1] = useState(false);
  const [helperText, SetHelperText] = useState('');
  const hiddenFileInput = React.useRef(null);

  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  const handleFileChange = event => {
    const fileUploaded = event.target.files[0];
    SetFile(fileUploaded)
    setButtontext(fileUploaded.name);
  };
  // End of File Upload

  const handleSubmit2 = event => {

    if (program.id === 0 || file === null) {

      if (program.id === 0) {
        SetError2(true)
      }

      if (file === null) {
        SetHelperText('Please fill in this required field')
      }

    } else {

      theJsonArray = [];

      readXlsxFile(file).then((rows) => {
        // `rows` is an array of rows
        // each row being an array of cells.
        for (var cc = 1; cc < rows.length; cc++) {

          if (!!rows[cc][0] && !!rows[cc][1] && !!rows[cc][2] && !!rows[cc][3] && !!rows[cc][4] && !!rows[cc][5] && !!rows[cc][6]) {

            var birthDate = "";
            if (typeof (rows[cc][9]) === 'string') {
              birthDate = rows[cc][9].replaceAll('/', '-');
            }

            theJsonArray.push({
              phone_no: rows[cc][0],
              name: rows[cc][1],
              village: rows[cc][2],
              parish: rows[cc][3],
              sub_county: rows[cc][4],
              district: rows[cc][5],
              acres: rows[cc][6],
              gender: rows[cc][7],
              nin: rows[cc][8],
              dob: birthDate,
            });
          }
        }

        setFarmerTable(JSON.parse(JSON.stringify(theJsonArray)));

        //change n to 30(any number) or the number needed for each api call
        const n = 30

        const result = new Array(Math.ceil(theJsonArray.length / n))
          .fill()
          .map(_ => theJsonArray.splice(0, n))

        // loop through each array formed and send an api request for each
        var uploader_user = 0;
        var cooperative_branch_id = 'none';
        if (category) {
          uploader_user = superagent.id
        } else {
          uploader_user = branchUser.user_id
          cooperative_branch_id = coopBranch.id
        }

        setLoaderState(true);
        setLoading(true);
        setTimeout(function () {
          result.forEach(result => {
            const uploadData = {
              users: result,
              program_id: program.id,
              upload_user: uploader_user,
              cooperative_branch_id:cooperative_branch_id
            }

            // api call goes here where for each new array, an api call will be made.....
            apiCall({ 'data': uploadData }, token, 'post', 'userupload/').then(response => {
              refresh();
            }).catch(error => console.error('Error while Uploading:', error));

          });
          setLoading(false);
        }, 5000)

      }).catch(error => console.error('Error while reading:', error));
    };

  };

  // Fetch Programs
  React.useEffect(() => {
    apiCall('', token, 'get', `programs/`)
      .then(programData => {
        setPrograms(programData.results);
      })
      .catch(error => console.error('Error during fetch:', error));
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const selectCategory = (event) => {
    const value44 = event.target.value;
    setCategory(value44);
    SetError1(false);
  };

  const searchGroup = (search_value) => {
    setCooperatives([]);
    fetchGroups(search_value);
  };

  const fetchGroups = (searched_value) => {
    apiCall('', token, 'get', `cooperatives/?programid=${program.id}&search=${searched_value}`)
      .then(cooperativeData => {
        setCooperatives(cooperativeData.results)
      })
      .catch(error => console.error('Error during search for cooperative groups:', error));
  };

  // Handle selected Program
  const selectProgram = event => {
    setCooperatives([]);
    setCooperative([]);
    setCooperativeBranches([]);
    setCooperativeBranch([]);
    setCooperativeBranch([]);
    setCooperativeBranches([]);
    setUserNames([]);
    setBranchUser([]);

    const value2 = event.target.value;
    for (let i = 0; i < programs.length; i++) {
      if (programs[i].id === value2) {
        setProgram(programs[i]);
        SetError2(false);
        break;
      }
    }

    if (category) {
      // Fetch Super Agent
      apiCall('', token, 'get', `programsuperagent/?program_id=${value2}`)
        .then(superagentData => {
          setSuperagents(superagentData.results)
        })
        .catch(error => console.error('Error during fetch:', error));
    }
  };

  // Handle selected Super Agent
  const selectSuperagent = event => {
    const value = event.target.value;
    for (let i = 0; i < superagents.length; i++) {
      if (superagents[i].id === value) {
        setSuperagent(superagents[i]);
        break;
      }
    }
  };

  // Handle select cooperative
  const selectCooperative = (value) => {
    setCooperativeBranch([]);
    setCooperativeBranches([]);
    setCooperatives([]);
    setUserNames([]);
    setBranchUser([]);
    if (value) {
      const value23 = value.id;

      var grp = '';
      var grpObject = {}; // eslint-disable-line
      for (let i = 0; i < cooperatives.length; i++) {
        if (cooperatives[i].id === value23) {
          grp = cooperatives[i].id;
          grpObject = cooperatives[i]; // eslint-disable-line
          SetError2(false);
          break;
        }
      }

      setCooperative({ id: grp, cooperative_name: value23 });

      // Fetch cooperative branches by cooperative
      apiCall('', token, 'get', `cooperativebranches/?cooperative_id=${grp}`)
        .then(coopBranchData => {
          setCooperativeBranches(coopBranchData.results)
        })
        .catch(error => console.error('Error during fetch:', error));
    }

  };

  // Handle selected Cooperative Branch
  const selectCoopBranch = event => {
    setUserNames([]);
    setBranchUser([]);
    const value44 = event.target.value;
    for (let i = 0; i < coopBranchess.length; i++) {
      if (coopBranchess[i].id === value44) {
        setCooperativeBranch(coopBranchess[i]);
        break;
      }
    }

    // Fetch cooperative branches user by branch 
    apiCall('', token, 'get', `cooperativebranchusers/?cooperative_branch_id=${value44}`)
      .then(braUserData => {
        var result1 = braUserData.results;
        setUserNames(result1);
      })
      .catch(error => console.error('Error during fetch:', error));
  };

  // Handle selected Cooperative Branch User
  const selectCoopBranchUser = event => {
    const value45 = event.target.value;
    for (let i = 0; i < usernames.length; i++) {
      if (usernames[i].user_id === value45) {
        setBranchUser(usernames[i]);
        break;
      }
    }
  };

  return (
    loadTable 
      ? loading 
        ? <CircularProgress style={{ width: "100px", height: "100px", marginLeft: "40%", marginTop: "10%" }} /> 
        : <TableContainer component={Paper}>
            <Table className={classes.saccoServiceTable} aria-label="customized table" >
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell className={classes.saccoServiceTableCell}>
                    #
                  </TableCell>
                  <TableCell className={classes.saccoServiceTableCell}>
                    Phone No
                  </TableCell>
                  <TableCell className={classes.saccoServiceTableCell}>
                    Name
                  </TableCell>
                  <TableCell className={classes.saccoServiceTableCell}>Village</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>

                {famersrecord.map((tr, index) => (

                  <TableRow>
                    <TableCell></TableCell>

                    <TableCell>
                      {index + 1}
                    </TableCell>

                    <TableCell>
                      0{tr.phone_no}
                    </TableCell>

                    <TableCell>
                      {tr.name}
                    </TableCell>

                    <TableCell>
                      {tr.village}
                    </TableCell>

                  </TableRow>

                ))}

              </TableBody>
            </Table>
          </TableContainer>
      : <form autoComplete="off" style={{ width: '100%' }}>
          <div className={classes.formRow}>
            <div style={{ width: '10%' }} />
            <div className={classes.formFourColumn} style={{ width: '60%' }}>

              {/* Selecting Category */}
              <FormControl className={classes.formInput} variant="outlined" required>
                <InputLabel
                  required
                  id="category-field-label"
                  data-testid="category_field"
                >
                  Select Category
                </InputLabel>
                <Select
                  labelId="category-field-label"
                  id="category_field"
                  data-testid="category_field"
                  label="Select Variety"
                  onChange={selectCategory}
                  error={error1}
                  value={category}
                >
                  <MenuItem value={true}>Super Agents</MenuItem>
                  <MenuItem value={false}>Groups</MenuItem>
                </Select>
              </FormControl>
              <br /><br />

              {/* Selecting Program */}
              <FormControl className={classes.formInput} variant="outlined" required>
                <InputLabel
                  required
                  id="program-field-label"
                  data-testid="program_field"
                >
                  Select Program
                </InputLabel>
                <Select
                  labelId="program-field-label"
                  id="program_field"
                  data-testid="program_field"
                  label="Select Program"
                  onChange={selectProgram}
                  error={error2}
                  value={program.id}
                >
                  {programs.map((prog, index) => (
                    <MenuItem value={prog['id']} key={index}>
                      {prog['program_name']}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <br /><br />

              {category ?
                <>
                  {/* Selecting the Super agent by Program */}
                  <FormControl className={classes.formInput} variant="outlined" required>
                    <InputLabel
                      required
                      id="variety_field-label"
                      data-testid="super-agent-field"
                    >
                      Select Super Agent
                    </InputLabel>
                    <Select
                      labelId="variety_field-label"
                      id="super-agent-field"
                      data-testid="super-agent-field"
                      label="Select Super Agent"
                      onChange={selectSuperagent}
                      value={superagent.id}
                    >
                      {superagents.map((s_agent, index) => (
                        <MenuItem value={s_agent['id']}>
                          {s_agent['name']}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <br /><br />
                </>
                :
                <>
                  {/* Selecting Group */}
                  <Autocomplete
                    id="group-search-demo"
                    options={cooperatives}
                    // classes={{
                    //   // option: classes.option,
                    // }}
                    autoHighlight
                    getOptionLabel={(option) => option.cooperative_name}
                    onChange={(event, newValue) => {
                      event.preventDefault();
                      setCooperatives(newValue ? [newValue, ...cooperatives] : cooperatives);
                      selectCooperative(newValue);
                    }}
                    onInputChange={(event, newInputValue) => {
                      searchGroup(newInputValue);
                    }}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.cooperative_name}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search Group"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                  <br /><br />

                  {/* Selecting the Super agent by Program */}
                  <FormControl className={classes.formInput} variant="outlined" required>
                    <InputLabel
                      required
                      id="coopbranch_field-label"
                      data-testid="coopbranch-field"
                    >
                      Select Group Branch
                    </InputLabel>
                    <Select
                      labelId="coopbranch_field-label"
                      id="coopbranch-field"
                      data-testid="coopbranch-field"
                      label="Select Group Branch"
                      onChange={selectCoopBranch}
                      value={coopBranch.id}
                    >
                      {coopBranchess.map((bra, index) => (
                        <MenuItem value={bra['id']}>
                          {bra['cooperative_branch_name']}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <br /><br />

                  {/* Selecting the Super agent by Program */}
                  <FormControl className={classes.formInput} variant="outlined" required>
                    <InputLabel
                      required
                      id="user_field-label"
                      data-testid="user-field"
                    >
                      Select Group Admin
                    </InputLabel>
                    <Select
                      labelId="user_field-label"
                      id="user-field"
                      data-testid="user-field"
                      label="Select Group Branch User"
                      onChange={selectCoopBranchUser}
                      value={branchUser.user_id}
                    >
                      {usernames.map((usr, index) => (
                        <MenuItem value={usr.user_id}>
                          {usr.user_full_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <br /><br />
                </>}

              {/* Choose User File to Upload */}
              <FormControl className={classes.formInput} variant="outlined" required>
                <Button
                  onClick={handleClick}
                >
                  {buttontext}
                </Button>
                <input
                  id="upload_file"
                  type="file"
                  ref={hiddenFileInput}
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                  accept=".xlsx, .xls, .csv"
                />
              </FormControl>
              <FormHelperText color='red'>{helperText}</FormHelperText>
              <br /><br />
              <Button
                variant="contained"
                className={classes.addProgramFunderButton}
                onClick={handleSubmit2}
              >
                Upload
              </Button>
            </div>
          </div>
        </form>
  );
};

export default UserUpload;