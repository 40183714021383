import React from 'react';
import { makeStyles, useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: -8,
    marginBottom: -8,
  },
  chip: { margin: 4 },
});

export const NumberField = props => {
  const { record } = props;

  return record ? (
    <span>{record.id}</span>
  ) : null;
};

NumberField.defaultProps = {
  label: 'No.',
  textAlign: 'left',
};


export const VillageField = props => {
  const classes = useStyles();
  const { record } = props;

  return record ? (
    <span className={classes.main}>
      {record.provider_location &&
        record.provider_location[0] &&
          record.provider_location[0]['village_name'] &&
            ((record.provider_location[0]['village_name']).length > 10
              ? (record.provider_location[0]['village_name']).substr(0,10)+"\u2026"
              : record.provider_location[0]['village_name']
            )
      }
    </span>
  ) : null;
};

VillageField.defaultProps = {
  label: 'Village',
  textAlign: 'left',
};


export const ParishField = props => {
  const classes = useStyles();
  const { record } = props;

  return record ? (
    <span className={classes.main}>
      {record.provider_location &&
        record.provider_location[0] && 
        <span>{record.provider_location[0]['parish_name']}</span>
      }
    </span>
  ) : null;
};

ParishField.defaultProps = {
  label: 'Parish',
  textAlign: 'left',
};


export const DistrictField = props => {
  const classes = useStyles();
  const { record } = props;

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return record ? (
    <span className={classes.main}>
      {record.provider_location &&
        record.provider_location[0] && 
        <span>
          {isSmall ? (
            // manupilate screen if on small screen & more than 6 characters
            record.provider_location[0]['district_name'] && (record.provider_location[0]['district_name']).length > 6 
              ? (record.provider_location[0]['district_name']).substr(0,6)+"\u2026"
              : record.provider_location[0]['district_name']
          ) : (
            // don't manupilate string (if on bigger screen)
            record.provider_location[0]['district_name']
          )}
        </span>
      }
    </span>
  ) : null;
};

DistrictField.defaultProps = {
  label: 'District',
  textAlign: 'left',
};