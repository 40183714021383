import React from 'react';
import clsx from 'clsx';
import { Box, Card } from '@material-ui/core';

import { RequestWithApprovedField } from './CardHeaderField';

import { SectionTitle } from '../../../utils';

export const ApprovedByField = props => {

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const {
    classes, 
    classesBase,
    resource,
    record,
    // request status
    approved,
    rejected,
    ...rest // eslint-disable-line
  } = props;
  
  // destructure "record"
  const { approval, request_status } = record;

  // approved details
  const { approval_date, approved_by_contact, approved_by_full_name, reason } = approval;

  const ApprovedByTitle = () => (
    // allow styling for modal-requested-by
    <p className={clsx('details')}>
      <span className={clsx('intro')}>
        {approved ? 'Approved by:' : rejected ? 'Rejected by:' : null}
      </span>
    </p>
  );

  if (!request_status) return null;

  // passed to ...RequestWithApprovedField
  const approveProps = {
    approval_date: approval_date, 
    approved_by_contact: approved_by_contact,
    approved_by_full_name: approved_by_full_name, 
    reason: reason
  };

  /** 
   * if request was "approved" or "rejected", 
   * check if we're at CREDIT requests 
   */
  return (approved || rejected) && resource === "credit-orders"
    ? <>
        <Box
          display={{ xs: 'block' }} 
          width={{ xs: '100% !important' }}
        >
          <SectionTitle label="" show={<ApprovedByTitle />} />
        </Box>
        <Box
          display={{ xs: 'block' }} 
          width={{ xs: '100% !important' }}
        >
          <Card className={clsx('requested-by-card')}>
            {/* "approval" section */}
            <RequestWithApprovedField {...approveProps} />
          </Card>
        </Box>
      </>
    : null;
};
