import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useTranslate } from 'react-admin';
import GroupIcon from '@material-ui/icons/Group';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import { Button, Box, Grid, Typography } from '@material-ui/core';

import { ProviderDashboardContext } from 'context/dashboards';
import { apiFullCall, Separator } from 'modules';
import { cardStyles, moreStyles } from 'stylesheets/jss';
import { CardWithIcon  } from 'layout';

interface Style {
  flex?: string;
  bothCol?: string;
  leftCol?: string;
  rightCol?: string;
  spaceTop?: string;
  flexChange?: string;
  flexColumn?: string;
}

const ProviderDashboard = (props: any) => {
  const classes = moreStyles();
  const classesBase: Style = cardStyles();
  
  const { isSmall, isXSmall, isMediumRange } = props;

  return (
    <ProviderDashboardContext.Provider
      value={{
        classes: classes,
        classesBase: classesBase,
        isMediumRange: isMediumRange,
        isXSmall: isXSmall,
        isSmall: isSmall
      }}
    >
      <Box mt={{ xs: '24px' }} className={classesBase.flex}>
        <Box className={clsx(classesBase.leftCol, classesBase.bothCol)}>
          <RenderProviderTitle />
          {false && // hidden till further notice
            <>
              <RenderProviderButtons />
              <RenderProviderCards />
            </>
          }
        </Box>
        {!isSmall && <Box className={classesBase.rightCol}></Box>}
      </Box>
    </ProviderDashboardContext.Provider>
  );
};

const RenderProviderTitle = () => {
  const translate = useTranslate();

  const firstName = sessionStorage.getItem('firstName');
  const lastName = sessionStorage.getItem('lastName');

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={10}>
        <Typography variant="h5" component="h2">
          {translate('pos.dashboard.provider.title')} {firstName}&nbsp;{lastName}
        </Typography>
        <Separator />
        <Typography component="p">
          {translate('pos.dashboard.provider.subtitle')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={2}></Grid>
    </Grid>
  );
};

const RenderProviderButtons = () => {
  const classes = moreStyles();
  const classesBase = cardStyles();
  const translate = useTranslate();

  const { isSmall } = React.useContext(ProviderDashboardContext);

  return (
    <Box className={clsx(classesBase.flexColumn, classesBase.upDown )}>
      <Box flex={1} className={classesBase.flexChange}>
        <Button
          // farmer/user registration
          size="small"
          component={Link}
          to="/users/create"
          className={clsx(classes.button, classes.farmerButton)}
        >
          {translate('pos.dashboard.agent.farmer_button')}
        </Button>
      </Box>
      <Box flex={!isSmall ? 1.925 : 1} className={classesBase.flexChange}>
        <Button
          // provider registration
          size="small"
          component={Link}
          to="/provider/create"
          className={clsx(classes.button)}
        >
          {translate('pos.dashboard.agent.provider_button')}
        </Button>
      </Box>
    </Box>
  );
};

const RenderProviderCards = () => {  
  const classesBase = cardStyles();

  const userId = sessionStorage.getItem('id');
  const token = sessionStorage.getItem('token');

  const { isSmall } = React.useContext(ProviderDashboardContext);

  // used to trigger card loaders
  const [loading, setLoading] = React.useState(true);

  const [users, setUsers] = React.useState<any>(null); // eslint-disable-line
  const [provider, setProvider] = React.useState<any>(null);

  let isLoaded = React.useRef<boolean>(true);

  /* "branch" totals */

  React.useEffect(() => {

    if (isLoaded && userId) {

      /* branch-users */

      apiFullCall(
        '', 
        token, 
        'get', 
        `providerbranchuser/?user_id=${userId}` 
      ).then(res => {
        if (res) {

          const { status, body } = res;

          if (isLoaded.current) setLoading(true);

          if (status === 200 || status === 201) {

            if (body.results && body.results.length > 0) {

              // extract provider_location_id from query
              const { provider_location_id } = body.results && body.results[0];            
              
              if (provider_location_id) {
                // pass provider_location_id, if it's not "undefined", to fetch branch-details
                apiFullCall(
                  '', 
                  token, 
                  'get', 
                  `providerbranch/${provider_location_id}/` 
                ).then(res => {
                  if (res) {

                    const { status, body } = res;

                    if (status === 200 || status === 201) {
                      const {
                        provider_id,
                        ...rest // eslint-disable-line
                      } = body;
                      
                      setProvider(provider_id);
                      // save the provider-id in session
                      sessionStorage.setItem('providerId', provider_id);
                    }
                  }      
                }).catch(
                  error => console.error('Error while fetching data:', error)
                );
              }
            }
          };

          if (isLoaded.current) setLoading(false);

        }      
      }).catch(
        error => console.error('Error while fetching data:', error)
      );

    };

    // clean up API call, on unmount
    return function cleanup() { 
      isLoaded.current = false; 
    };

  }, [isLoaded, userId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box className={clsx(classesBase.flexColumn, classesBase.upDown)}>
      <Box flex={1} className={classesBase.right}>
        <CardWithIcon 
          to={`/users`}
          title="FARMER(S)"
          loading={loading}
          icon={GroupIcon}
          subtitle={users}
        />
      </Box>
      <Box flex={1} className={classesBase.spaceTop}>
        <CardWithIcon 
          to={`/provider`}
          title="SERVICE PROVIDER(S)"
          loading={loading}
          icon={TouchAppIcon}
          subtitle={provider}
        />
      </Box>
      {!isSmall && <Box flex={1}></Box>}
    </Box>
  );
};

export default ProviderDashboard;
