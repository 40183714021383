import React from 'react';
import clsx from 'clsx';
import { SaveButton, Toolbar, useRefresh, useNotify } from 'react-admin';

import { ApproveButton } from 'layout';
import { apiFullCall } from 'modules/apiHelper';


/* Handling "accept" CREDIT request */


export const CreditOrderAggregateToolbar = props => {

  // destructure to pass directly to <ApproveButton>
  const { isXSmall, ...rest } = props;
  
  const { handleDialogClose, ...other } = rest;

  return (
    <Toolbar {...other}>
      <ApproveButton
        label="No"
        icon={null}
        isXSmall={isXSmall}
        className={clsx('small--btn')}
        onClick={handleDialogClose}
      />
      <ConfirmButton {...rest} /> 
    </Toolbar>
  )  
};

const ConfirmButton = props => {
  const refresh = useRefresh();
  const notify = useNotify();

  // destructure to pass "rest" to <SaveButton>
  const {
    handleDialogClose,
    mutationMode, 
    record,
    resource,
    validating, 
    // from <AggregateButton>
    unselectAll,
    selectedIds,
    ...rest 
  } = props;

  const token = sessionStorage.getItem('token');

  const [ loading, setLoading ] = React.useState(false); // eslint-disable-line

  let isLoaded = React.useRef(false);

  React.useEffect(() => {
    /* Cleanup useEffect */

    isLoaded.current = true; // set to true on mount...
    return () => { isLoaded.current = false; }; // ... and to false on unmount
  }, [isLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAggregate = React.useCallback(() => {

    // Ensure we have a list of selected "requests"
    if (!!selectedIds && selectedIds.length > 0) {

      apiFullCall(
        selectedIds, 
        token, 
        'post', 
        `cooperativeaggrequests/` 
      ).then(res => {

        if (res) {

          if (isLoaded.current) setLoading(true);

          const { status } = res;

          if (status === 200 || status === 201) {

            // go back to requests list
            refresh();
            unselectAll(resource);

            // when successfully submitted
            notify(`Successfully aggregated ${selectedIds.length} request(s)`, 'info');
            
          } else if (status === 400) {
            notify(`Please check again and resend.`, 'warning');
          } else if (status >= 500) {
            notify(`Server error, please try again later.`, 'warning');          
          };

          if (isLoaded.current) setLoading(false);

        };

      }).catch(
        error => console.error('Error while aggregating orders:', error)
      );

    } else {

      // inform user to select a "request"
      notify(`Please select at least one request`, 'warning');
    };

  }, [selectedIds]); // eslint-disable-line react-hooks/exhaustive-deps

  // define a component that returns "null"
  // And pass it to <SaveButton> to override the "save" icon
  const NoIcon = () => null;

  return (
    <SaveButton
      {...rest}
      icon={<NoIcon />}
      label={loading ? "" : "Yes"}
      submitOnEnter={false}
      saving={loading}
      onSave={handleAggregate} 
    />
  );
};
