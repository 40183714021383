import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {Button } from '@material-ui/core';

const StyledTableRow = withStyles((theme) => ({
	root: {
		'&:hover, &:focus': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const useStyles = makeStyles({
	tableheader: {
		marginTop: 2,
		marginLeft: 8,
		fontSize: '.925rem'
	},
	tableheadCell: {
		backgroundColor: '#722F37',
		color: '#ffff',
		textAlign: 'left !important'
	},
  tableMiddleCell: {
		textAlign: 'left !important',
    width:"80% !important"
	},
  tableFristCell: {
    width:"20px !important",
		textAlign: 'left !important'
	},
	tableLastCell: {
		textAlign: 'left !important'
	},
	tablecontainer: {
		marginTop: 10,
		padding: 0,
		paddingTop: 10,
		paddingBottom: 30
	},
	root: {
		flexGrow: 1,
	},
	card: {
		marginTop: 10,
		padding: 0,
		borderLeft: '4px solid #722F37',
		height: '87% !important'
	},
	cardHeader: {
		padding: 10,
		paddingBottom: 0,
		fontSize: '.925rem'
	},
	cardSubHeader: {
		FontSize: '0.875rem',
		FontWeight: 500
	},
	cardVerticalSpliter: {
		marginBottom: 20,
		width: 1,
		backgroundColor: '#722F37'
	},
	cardHorizontalSpliter: {
		marginBottom: 20,
		height: 1,
		backgroundColor: '#722F37'
	}
});

const RMFReport2 = props => {
  const classes = useStyles();
  
  const reports = [
    {"id":"01", "quarter":"All","title":"RMF Master File","link":"https://uncdf-reports.s3.eu-west-3.amazonaws.com/DATA+MASTER+RMF+FILE+UNCDF-QUEST%2BReviewed+Fostering+Digital+access+Project+(1).xlsx"},
    {"id":"02", "quarter":"Q3,2021", "title":"User Audit Log","link":"https://uncdf-reports.s3.eu-west-3.amazonaws.com/User+Audit+Log+Q3_2021.xlsx"},
    {"id":"03", "quarter":"Q2,2021", "title":"Service Proiders Report","link":"https://uncdf-reports.s3.eu-west-3.amazonaws.com/Service+Proiders+ReportQ2%3A2021.xlsx"},
    {"id":"04", "quarter":"Q3,2021", "title":"Recruited and active Input dealers Report","link":"https://uncdf-reports.s3.eu-west-3.amazonaws.com/Recruited+and+active+Input+dealers+Report+Q3_2021.xlsx"},
    {"id":"05", "quarter":"Q3,2021", "title":"Providers report","link":"https://uncdf-reports.s3.eu-west-3.amazonaws.com/Providers+report+Q3%3A2021.xlsx"},
    {"id":"06", "quarter":" Q2,2021 ", "title":"Profiled Farmers Report","link":"https://uncdf-reports.s3.eu-west-3.amazonaws.com/Profiled+Farmers+Report+Q2%3A2021.xlsx"},
    {"id":"07", "quarter":"Q2,2022", "title":"Profiled Farmers","link":"https://uncdf-reports.s3.eu-west-3.amazonaws.com/Profiled+Farmers+as+at+Q2%3A2022.xlsx"},
    {"id":"08", "quarter":"Q4,2021", "title":"Number of Staff and Service Providers profiled","link":"https://uncdf-reports.s3.eu-west-3.amazonaws.com/Number+of+Staff+and+Service+Providers+profiled+Q4%3A2021.xlsx"},
    {"id":"09", "quarter":"Q3,2021", "title":"New Enrolled Customers of Digital Extension","link":"https://uncdf-reports.s3.eu-west-3.amazonaws.com/New+Enrolled+Customers+of+Digital+Extension+Q3_2021.xlsx"},
    {"id":"10", "quarter":"Q2,2022", "title":"New enrolled customers of digital Extension , agro advisory services","link":"https://uncdf-reports.s3.eu-west-3.amazonaws.com/new+enrolled+customers+of+digital+Extension+%2C+agro+advisory+services+Q2%3A2024.xlsx"},
    {"id":"11", "quarter":"Q3,2021", "title":"New enrolled & active customers accessing Inputs digitally","link":"https://uncdf-reports.s3.eu-west-3.amazonaws.com/new+enrolled+%26+active+customers+accessing+Inputs+digitally+Q3_2021.xlsx"},
    {"id":"12", "quarter":"Q2,2022", "title":"New enrolled & active customers accessing Inputs digitally","link":"https://uncdf-reports.s3.eu-west-3.amazonaws.com/new+enrolled+%26+active+customers+accessing+Inputs+digitally+Q2%3A2022.xlsx"},
	{"id":"13", "quarter":"Q4,2022", "title":"Farmers using the system","link":" https://uncdf-reports.s3.eu-west-3.amazonaws.com/Q4%2C+2022.xls"},
  ];
 
  const handleDownload = (url) => {
    window.open(url, '_blank');
  }
  return (
    <>
   <Grid container spacing={3}>
					<Grid item xs={12}>
						<Paper className={classes.tablecontainer}>
							<Typography variant="h6" component="h6" className={classes.tableheader}>
                Downloadable Reports
							</Typography>
							<TableContainer>
								<Table className={classes.table} size="small" aria-label="a dense table">
									<TableHead>
										<TableRow>
											<TableCell className={classes.tableheadCell}></TableCell>
											<TableCell className={classes.tableheadCell} style={{width:'20px !important'}}>ID</TableCell>
                      <TableCell className={classes.tableheadCell} style={{width:'20px !important'}}>Quarter</TableCell>
                      <TableCell className={classes.tableheadCell} style={{width:'80% !important'}} >Title</TableCell>
                      <TableCell className={classes.tableheadCell}></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{reports.map((row) => (
											<StyledTableRow key={row.id}>
												<TableCell className={classes.tableFristCell}></TableCell>
                        <TableCell className={classes.tableFristCell}>{row.id}</TableCell>
                        <TableCell className={classes.tableFristCell}>{row.quarter}</TableCell>
												<TableCell className={classes.tableMiddleCell}>
                        {row.title}
                      </TableCell>
                      <TableCell className={classes.tableLastCell}>
                        <Button
                          style={{color:'#722F37', background:'#fff',float: 'right'}} 
                          variant="contained"
                          onClick={() =>handleDownload(row.link)}
                        >
                          Download
                      </Button>
                      </TableCell>
											</StyledTableRow>
										))} 
									</TableBody>
								</Table>
							</TableContainer>
						</Paper>
					</Grid>
				</Grid>
    </>
  );
};

export default RMFReport2;
