import React from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom'
import { TextField } from 'react-admin';
import { Avatar, Card, CardHeader, CardContent } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/PeopleOutline';

import { CircularProgress } from '../../../layout';

const ProviderReportCards = props => {
	const history = useHistory();

	const { ids, data, filters, loading } = props;

	const viewDetails = (classification) => {
		sessionStorage.setItem('_providers_filter', JSON.stringify(filters))
		sessionStorage.setItem('_providers_classification', JSON.stringify(classification))

		history.push({ 
			pathname: '/providersreport-details', 
			state: { classification: classification, filters: filters } 
		});
	};

	return (
		<div className={clsx('report-section')}>
			{ids.map(id =>
				<Card
					key={id}
					raised={false}
					className={clsx('report-card')} 
					onClick={() => viewDetails(data[id])}
				>
					<CardHeader
						title={<TextField record={data[id]} source="name" />}
						avatar={<Avatar icon={<PersonIcon />} />}
					/>
					{loading
						? <CircularProgress className={clsx('sm-wide')} />
						: <CardContent>
								<TextField record={data[id]} source="providers_count" />
								<div>
									<div>
										<h3>MALE</h3>
										<TextField record={data[id]} source="male" />
									</div>
									<div>
										<h3>FEMALE</h3>
										<TextField record={data[id]} source="female" />
									</div>
								</div>
							</CardContent>}				
				</Card>
			)}
		</div>
	);
};

ProviderReportCards.defaultProps = {
	data: {},
	ids: [],
};

export default ProviderReportCards;