import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Identifier } from 'ra-core';
import { Button, useUnselectAll } from 'react-admin';
import { Dialog, DialogContent, DialogTitle, Slide, makeStyles } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { ButtonProps } from '@material-ui/core/Button';

import { SectionTitle } from 'modules/utils';


const useStyles = makeStyles(() =>
  ({
    bulkButton: {
      color: '#ffffff',
      fontSize: '0.95rem',
      backgroundColor: '#3f51b5',
      padding: '3px 14.5px 3px 10px',
      '&:hover': {
        backgroundColor: '#3f51b5',
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: '#3f51b5',
        },
      },
    },
  }),
  { name: 'AkBulkSaveWithConfirmButton' }
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const BulkSaveWithConfirmButton: React.FC<BulkSaveWithConfirmButtonProps> = props => {

  const {
    basePath,
    classes: classesOverride,
    confirmTitle,
    mainTitle,
    content1,
    Question,
    icon,
    label,
    content2,
    onClick,
    resource,
    selectedIds,
    ...rest
  } = props;

  const classes = useStyles(props);
  const unselectAll = useUnselectAll();
  
  const [open, setOpen] = React.useState(false);

  const handleClick = e => {
    setOpen(true);
    e.stopPropagation();
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        label={label}
        onClick={handleClick}
        className={classes.bulkButton}
        {...sanitizeRestProps(rest)}
      >
        {icon}
      </Button>
      <Dialog
        maxWidth="xs"
        open={open}
        onClose={handleDialogClose}
        TransitionComponent={Transition}
        className={clsx('AkDialog-root','profile--modal')}
        aria-labelledby="confirm-dialog-title"
      >
        <DialogTitle
          disableTypography={true} // makes this a <div> not a default <h2>
          id="confirm-dialog-title"
        >
          <SectionTitle label={mainTitle} />
        </DialogTitle>
        <DialogContent dividers>
          {React.cloneElement(content1, { 
            confirmTitle,
            selectedIds, 
          })}
          {React.cloneElement(content2, {
            resource,
            selectedIds,
            handleDialogClose,
            unselectAll,
          })}
        </DialogContent>
      </Dialog>
    </>
  );
};

const sanitizeRestProps = ({
  basePath,
  classes,
  filterValues,
  label,
  ...rest
}: Omit<
  BulkSaveWithConfirmButtonProps,
  'resource' | 'selectedIds' | 'icon' | 'content2' | 'content1'
>) => rest;

interface Props {
  basePath?: string;
  Question?: any;
  confirmTitle?: string;
  mainTitle?: string;
  filterValues?: any;
  label?: string;
  content1?: any;
  content2?: any;
  icon?: React.ReactElement;
  resource?: string;
  selectedIds?: Identifier[];
}

export type BulkSaveWithConfirmButtonProps = Props & ButtonProps;

BulkSaveWithConfirmButton.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.object,
  confirmTitle: PropTypes.oneOfType([PropTypes.element, PropTypes.any]),
  mainTitle: PropTypes.oneOfType([PropTypes.element, PropTypes.any]),
  Question: PropTypes.string,
  label: PropTypes.string,
  content1: PropTypes.oneOfType([PropTypes.element, PropTypes.any]),
  content2: PropTypes.oneOfType([PropTypes.element, PropTypes.any]),
  resource: PropTypes.string.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.any]).isRequired,
};

BulkSaveWithConfirmButton.defaultProps = {
  // confirmTitle: 'ra.message.bulk_delete_title',
  // Question: 'ra.message.bulk_delete_content',
  label: 'ra.action.delete',
  // icon: <ActionDelete />,
};

