import React from 'react';
import clsx from 'clsx';
import { useMediaQuery } from '@material-ui/core';
import { SimpleForm } from 'react-admin';

import { Aggregate } from '../requestops';

/* Used in <AggregateButton> */

export const RequestAggregateForm = props => {
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const {
    handleDialogClose,
    unselectAll,
    ...rest
  } = props;

  // passed to <Aggregate />
  const aggregateProps = {
    isXSmall: isXSmall,
    ...props
  };

  return (
    <SimpleForm
      {...rest}
      submitOnEnter={false}
      className={clsx('request', 'bulk')}
      toolbar={<Aggregate {...aggregateProps} />}
    />
  );
};
