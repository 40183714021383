import React from 'react';
import clsx from 'clsx';
import { LinearProgress } from 'react-admin';
import { Card, Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '420px',
  },
  st: {
    marginTop: '72px',
    minHeight: 'unset',
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  sb: {
    marginBottom: '250px',
  },
  sblarge: {
    marginBottom: '145px',
  },
  description: {
    fontSize: '120%',
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.54)',
  },
}));

export const FetchProgress = ({ title, top=false, bottom=false, btlarge=false }) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.card, { [classes.st]: top, [classes.sb]: bottom, [classes.sblarge]: btlarge })}>
      <Box className={classes.description}>{title}</Box>
      <LinearProgress />
    </Card>
  );
};