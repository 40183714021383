import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Layout, Sidebar } from 'react-admin';
import { AbAppBar } from './bars/AppBar';
import { AppState } from 'types';
import { Menu } from './menus';

import { AppContext } from '../context/app';

import { AbTheme, layoutStyles, sidebarStyles } from '../stylesheets';

export const AbLayout = (props: any) => {
  const classes = layoutStyles();
  const history = useHistory();

  const { cropSidemenu } = React.useContext(AppContext);
  const permissions = sessionStorage.getItem('permissions');

  const openSidebar = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
  useSelector((state: AppState) => state.theme); // force rerender on theme change

  return (
    <Layout
      {...props}
      appBar={AbAppBar}
      sidebar={CustomSidebar}
      menu={Menu}
      theme={AbTheme}
      className={clsx(
        classes.root, { 
          "my-profile": history.location.hash.includes(`#my-profile`) || history.location.hash.includes(`#my-wallet`),
          "farmer-info": cropSidemenu,
          'partner': permissions && permissions.includes('devpartner'),
          "expand" : openSidebar
        }
      )}
    />
  );
};

const CustomSidebar = (props: any) => {
  const classes = sidebarStyles();
  const history = useHistory();

  const { cropSidemenu } = React.useContext(AppContext);
  const permissions = sessionStorage.getItem('permissions')

  return (
    <Sidebar
      {...props}
      size={200} 
      className={clsx(
        classes.root, { 
          "my-profile": history.location.hash.includes(`#my-profile`) || history.location.hash.includes(`#my-wallet`),
          "farmer-info": cropSidemenu,
          'hidden': permissions && permissions.includes('devpartner'),
        }
      )}
    />
  );
};
