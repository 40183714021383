import Highcharts from 'highcharts/highcharts.js'
import highchartsMore from "highcharts/highcharts-more.js"
import solidGauge from "highcharts/modules/solid-gauge.js";

highchartsMore(Highcharts);
solidGauge(Highcharts);

export const plotUserTrends = (plotData) => {
  var series = [];
  let validateData = plotData && plotData['series'];

  if (!!plotData) { // ensure "plotData" isn't null

    for (var i = 0; i < validateData.length; i++) {

      var episode = { name: validateData[i]['name'] };

      episode['data'] = [validateData[i]['data'][0]];

      for (var j = 1; j < validateData[i]['data'].length; j++) {
        episode['data'].push(
          validateData[i]['data'][j] - validateData[i]['data'][j - 1]
        );
      }

      series.push(episode);
    };

  };

  Highcharts.chart('usertrends', {
    chart: {
      type: 'line'
    },
    title: {
      text: 'Farmer Enrollment Trends for the past 12 Months'
    },
    subtitle: {
      text: plotData && plotData['subtitle']
    },
    yAxis: {
      title: {
        text: 'Farmers Enrolled'
      }
    },
    xAxis: {
      categories: plotData && plotData['labels']
    },
    series: series
  });
};

export const drawGauge = (chartData, anchor, label) => { // eslint-disable-line
  chartData['max'] = 20000;
  Highcharts.chart(anchor, {
    chart: {
      type: 'gauge',
      plotBackgroundColor: null,
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false
    },
    title: {
      text: label
    },
    pane: {
      startAngle: -150,
      endAngle: 150,
    },

    // the value axis
    yAxis: {
      min: 0,
      max: chartData['max'],
      minorTickInterval: 'auto',
      minorTickWidth: 1,
      minorTickLength: 10,
      minorTickPosition: 'inside',
      minorTickColor: '#666',

      tickPixelInterval: 30,
      tickWidth: 2,
      tickPosition: 'inside',
      tickLength: 10,
      tickColor: '#666',
      labels: {
        step: 2,
        rotation: 'auto'
      },
      plotBands: [
        {
          from: 0,
          to: Math.round(chartData['max'] * 0.75),
          color: '#DF5353'
        }, {
          from: Math.round(chartData['max'] * 0.75),
          to: Math.round(chartData['max'] * 0.90),
          color: '#DDDF0D'
        }, {
          from: Math.round(chartData['max'] * 0.90),
          to: chartData['max'],
          color: '#55BF3B'
        }
      ]
    },

    series: [
      {
        name: label,
        data: [chartData['value']],
        tooltip: {
          valueSuffix: ' of ' + chartData['max']
        }
      }
    ]
  });
};

export const drawVUMeter = (chartData, anchor, label) => {
  Highcharts.chart(anchor, {
    chart: {
      type: 'gauge',
      plotBackgroundColor: {
        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
        stops: [
          [0, '#fffbed'],
          [0.9, '#FFFFFF'],
          [1, '#ffffff']
        ]
      },
      plotBackgroundImage: null,
    },
    title: {
      text: label
    },
    pane: [
      {
        startAngle: -85,
        endAngle: 85,
        background: null
      }
    ],
    yAxis: [
      {
        min: 0,
        max: chartData['max'],
        minorTickPosition: 'outside',
        tickPosition: 'outside',
        labels: {
          rotation: 'auto',
          distance: 20
        },
        plotBands: [
          {
            from: 0,
            to: Math.round(chartData['max'] * 0.75),
            color: '#DF5353'
          }, {
            from: Math.round(chartData['max'] * 0.75),
            to: Math.round(chartData['max'] * 0.90),
            color: '#DDDF0D'
          }, {
            from: Math.round(chartData['max'] * 0.90),
            to: chartData['max'],
            color: '#55BF3B'
          }
        ],
        title: {
          text: '<span style="font-size:10px">' + chartData['value'] + '/' + chartData['max'] + '</span>'
        }
      }
    ],

    series: [
      {
        name: label,
        data: [chartData['value']],
        yAxis: 0
      }
    ]

  });
};

export const plotUserGrowth = (plotData, totalFarmers) => {
  Highcharts.chart('usergrowth', {
    chart: {
      type: 'line'
    },
    title: {
      text: 'Farmer Growth for the past 12 Months'
    },
    subtitle: {
      text: plotData && plotData['subtitle']
    },
    yAxis: {
      title: {
        text: 'Cumulative Farmers Enrolled'
      }
    },
    xAxis: {
      categories: plotData && plotData['labels']
    },
    series: plotData && plotData['series']
  });
};

export const plotUserGrouping = (groupingData) => {
  Highcharts.chart('total', {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie'
    },
    title: {
      text: 'Farmer Distribution Over Groups'
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false
        },
        showInLegend: true
      }
    },
    series: [
      {
        name: 'Farmers',
        colorByPoint: true,
        data: groupingData && groupingData['series']
      }
    ]
  });
};

export const plotProviderGrowth = (providerGrowth) => {
  Highcharts.chart('service_providers', {
    chart: {
      type: 'column'
    },
    title: {
      text: 'Service Providers Enrolled'
    },
    subtitle: {
      text: 'Last 6 Months'
    },
    xAxis: {
      categories: providerGrowth && providerGrowth['labels'],
    },
    legend: {
      enabled: false
    },
    yAxis: {
      title: {
        text: 'Service Providers Enrolled'
      }
    },
    series: providerGrowth && providerGrowth['series']
  });
};

export const plotRequests = (requestsData) => {
  Highcharts.chart('requests', {

    title: {
      text: 'Request Processing Trends'
    },
    subtitle: {
      text: requestsData && requestsData['subtitle']
    },
    yAxis: {
      title: {
        text: 'Number of Requests'
      }
    },

    xAxis: {
      categories: requestsData && requestsData['labels']
    },
    series: requestsData && requestsData['series']
  });
};

export const serviceProviderGrowthCumulative = (plotData, providerTotals) => {
  var sum = providerTotals - (plotData && plotData['totals']);
  var series = [];

  let validateData = plotData &&
    plotData['series'] &&
    plotData['series'][0] &&
    plotData['series'][0]['data'];

  if (!!plotData) { // ensure "plotData" isn't null

    for (let i = 0; i < validateData.length; i++) {
      sum = sum + validateData[i];
      series.push(sum);
    };

  };

  Highcharts.chart('provider_growth_cumulative', {
    chart: {
      type: 'line'
    },
    title: {
      text: 'Service Provider Cumulative Enrollments.'
    },
    subtitle: {
      text: 'Past 6 Months'
    },
    yAxis: {
      title: {
        text: 'Cumulative Enrollment'
      }
    },
    xAxis: {
      categories: plotData && plotData['labels']
    },
    series: [
      {
        name: 'Service Providers',
        data: series
      }
    ]
  });
};

export const plotProviderGrouping = (groupingData) => {
  var series = [];
  let validateData = groupingData && groupingData['results'];

  if (!!groupingData) { // ensure that "groupingData" isn't null

    for (var x = 0; x < validateData.length; x++) {
      series.push(
        {
          name: validateData && validateData[x] && validateData[x]['name'],
          y: parseInt(validateData[x]['male']) + parseInt(validateData[x]['female'])
        }
      );
    };

  };

  Highcharts.chart('provider_distribution', {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie'
    },
    title: {
      text: 'Service Provider Classes'
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false
        },
        showInLegend: true
      }
    },
    series: [
      {
        name: 'Service Providers',
        colorByPoint: true,
        data: series
      }
    ]
  });
};
