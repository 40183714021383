import React from 'react';
import clsx from 'clsx';
import { Box, Card } from '@material-ui/core';

import { RequestWithContactField, RequestWithNoContactField } from './CardHeaderField';

import { SectionTitle } from '../../../utils';

export const RequestedByField = props => {

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const {
    record,
    resource,
    // request status
    accepted,
    delivered
  } = props;
  
  // destructure "record"
  const { farmer, location, request_status } = record;

  const RequestedByTitle = () => (
    // allow styling for modal-requested-by
    <p className={clsx('details')}>
      <span className={clsx('intro')}>Requested by:</span>
    </p>
  );

  return (
    <>
      <Box
        display={{ xs: 'block' }} 
        width={{ xs: '100% !important' }}
      >
        <SectionTitle label="" show={<RequestedByTitle />} />
      </Box>
      <Box
        display={{ xs: 'block' }} 
        width={{ xs: '100% !important' }}
      >
        <Card className={clsx('requested-by-card')}>
          {/* confirm request status */
            request_status
              /* check if it's not a CASH request (it's either CREDIT or AGGREGATED) */
              && resource !== "cash-orders"
                /* if yes, show "farmer contacts" directly to admin */
                ? <RequestWithContactField farmer={farmer} location={location} />
                /* if no, then assume we're handling CASH requests */
                : (accepted || delivered)
                  /* show "farmer contacts" since provider took on the order */
                  ? <RequestWithContactField farmer={farmer} location={location} />
                  /* otherwise, hide the "farmer contacts" */
                  : <RequestWithNoContactField />
          }
        </Card>
      </Box>
    </>
  );
};
