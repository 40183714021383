import React from 'react';
import { LoginForm } from './LoginForm';

const AbLoginPage = () => {

  return (
    <div className="signup flex justify-center w-full h-full-screen">
      <div className="p-10">
        <LoginForm />
      </div>
    </div>
  );
};

export default AbLoginPage;
