import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { FetchProgress } from 'layout';
import { Transition } from 'modules/utils';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { Dialog, DialogContent, Button, Box } from '@material-ui/core';
import { DialogTitle } from 'layout';
import { apiFileUploadCallPrediction } from 'modules/apiHelper';

const PredictionsList = props => {
    const { isOpen } = props;
    const [open, setOpen] = React.useState(isOpen);
    const [isLoading, setIsLoading] = React.useState(false);
    const [state, setState] = React.useState({ fileName: '', file: '' });
    const [predictionResponse, setPredictionResponse] = React.useState({ score: '', image: '' });
    const history = useHistory();
    const handleClose = () => {
        setOpen(false);
        history.goBack();
    }
    const fileUploadChange = e => {
        setPredictionResponse({
            ...predictionResponse,
            score: '',
            image: ''
        });

        if (e.target.files[0]) {
            setState({ ...state, fileName: e.target.files[0].name, file: e.target.files[0] });
        }
    };

    const fileUpload = () => {
        if (state.file.name) {
            setIsLoading(true);

            setPredictionResponse({
                ...predictionResponse,
                score: '',
                image: ''
            });
            
            const token = sessionStorage.getItem('token');
            let formData = new FormData();
            formData.append('file', state.file, state.file.name);

            apiFileUploadCallPrediction(
                formData,
                token,
                'post',
                `predict/`
            ).then(res => {
                setIsLoading(false);
                if (res) {
                    setPredictionResponse({
                        ...predictionResponse,
                        score: res.score,
                        image: res.encoded_string
                    });
                }
            })
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            // TransitionComponent={Transition}
            className={clsx('AkDialog-root', 'profile--modal')}
            aria-labelledby="customized-dialog-title"
        >
            <DialogTitle
                id="customized-dialog-title"
                onClose={handleClose}
            >
                Select Image
            </DialogTitle>
            <DialogContent dividers >
                <input
                    id="file_upload"
                    type="file"
                    accept=".png,.jpeg"
                    onChange={fileUploadChange}
                />
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={fileUpload}
                >
                    {isLoading ? 'Please wait...' : 'Upload'}
                </Button> <br />
                {console.log(predictionResponse)}
                {predictionResponse.score &&
                    <>
                        <div>
                            <h4>Score: <span>{predictionResponse.score}</span></h4><br />
                        </div>
                        {predictionResponse.image &&
                            <div>
                                <img src={`data:image/png;base64,${predictionResponse.image}`} />
                            </div>
                        }
                    </>
                }
            </DialogContent>
        </Dialog>

    )
};

export default PredictionsList;
