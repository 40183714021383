import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Redirect } from 'react-router';
import { Edit } from 'react-admin';

import { ProviderTitle } from '../providerops';
import { FetchProgress } from '../../../layout';

import * as P from '../../../allPermissions';

const ProviderMultiForm = React.lazy(() => import("../providerforms/ProviderMultiForm"));

const ProviderEdit = props => {

  const { permissions } = props;

  const UserIsPermitted = P.authCreateProviders(permissions);
  const editSuperagent = P.authCreateSuperagents(permissions);
  const editProdSup = P.authCreateProSup(permissions);

  if (!permissions) { // if no permissions
    return null;
  };

  const moreProps = {
    editProdSup: editProdSup,
    editSuperagent: editSuperagent,
    UserIsPermitted: UserIsPermitted,
    ...props
  };

  return <EditComponent {...moreProps} />
};

const EditComponent = props => {
  const history = useHistory();

  const {
    classes,
    hasCreate,
    hasEdit,
    hasList,
    hasShow,
    editProdSup,
    editSuperagent,
    UserIsPermitted,
    ...rest
  } = props;

  const { basePath } = rest;

  const handleClose = React.useCallback(() => {
    history.push(basePath);
  }, [history, basePath]);

  const moreProps = {
    handleClose: handleClose,
    editProdSup: editProdSup,
    editSuperagent: editSuperagent,
    ...rest
  };

  return (
    <React.Suspense fallback={<FetchProgress title="Loading..." top={true} /> }>
      {UserIsPermitted
        ? <Route path="/provider/:id">
            <EditServiceprovider {...moreProps} />
          </Route> 
        : editSuperagent
          ? <Route path="/superagents/:id">
              <EditSuperagent {...moreProps} />
            </Route>      
          : editProdSup
            ? <Switch>
                <Route exact path="/provider/:id">
                  <EditServiceprovider {...moreProps} />
                </Route> 
                <Route path="/superagents/:id">
                  <EditSuperagent {...moreProps} />
                </Route>
              </Switch>  
            : <Redirect to="/" />}
    </React.Suspense>
  )
};

const EditServiceprovider = (props: any) => {
  const {
    handleClose,
    editProdSup,
    editSuperagent,
    ...rest 
  } = props;

  const {
    basePath,
    hasCreate,
    hasEdit,
    hasList,
    hasShow,
    permissions,
    ...other
  } = rest;

  const moreProps = {
    handleBothSP: editProdSup,
    handleSuperagent: editSuperagent,
    ...other
  };

  return (
    <Edit title={<ProviderTitle />} {...rest}>
      <ProviderMultiForm onCancel={handleClose} redirect={hasCreate ? 'list' : basePath} {...moreProps} />
    </Edit>
  );
};

const EditSuperagent = (props: any) => {
  const {
    handleClose,
    editProdSup,
    editSuperagent,
    ...rest 
  } = props;

  const {
    basePath,
    hasCreate,
    hasEdit,
    hasList,
    hasShow,
    permissions,
    ...other
  } = rest;

  const redirect = hasCreate ? 'list' : basePath;

  const moreProps = {
    handleBothSP: editProdSup,
    handleSuperagent: editSuperagent,
    ...other
  };

  return (
    <Edit title="Super-agent Update" {...rest}>
      <ProviderMultiForm onCancel={handleClose} redirect={redirect} {...moreProps} />
    </Edit>
  );
};

export default ProviderEdit;
