import ProviderIcon from '@material-ui/icons/People';

import ProviderList from '../providercrud/ProviderList';
import ProviderCreate from '../providercrud/ProviderCreate';
import ProviderEdit from '../providercrud/ProviderEdit';

const serviceproviders = {
  list: ProviderList,
  create: ProviderCreate,
  edit: ProviderEdit,
  icon: ProviderIcon,
};

export default serviceproviders; 