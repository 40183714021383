import React from 'react';
import clsx from 'clsx';
import { Box } from '@material-ui/core';
import { DateInput, FormDataConsumer, SelectInput, TextInput } from 'react-admin';
import { SectionTitle, Separator } from '../../../utils';
import { Email, Needed, phoneNumber } from '../../../validation';


export const RenderProgramDetails = (props: any) => {

  const { classes, isRegistered, listParentPrograms } = props;

  return isRegistered ?
    /**
     * EDIT
     */
    <Box>
      <TextInput
        autoFocus={!isRegistered ? true : false}
        source="program_name"
        className={classes.input}
        validate={Needed}
      />
      <TextInput
        source="short_name"
        className={classes.input}
        validate={Needed}
      />
      <Box></Box>
      <SelectInput
        label="Parent program"
        source="parent_program_id"
        optionText="name"
        choices={listParentPrograms}
        className={clsx('add-to-program')}
      />
      <DateInput
        label="Start Date"
        source="start_date"
        className={classes.input}
        validate={Needed}
      />
      <DateInput
        label="End Date"
        source="end_date"
        className={classes.input}
        validate={Needed}
      />
      <TextInput
        label="Describe the program (briefly)"
        source="description"
        className={clsx(classes.input, 'rich-text')}
      />
    </Box>
  : /**
     * CREATE
     * This data is sent within a "program" object as follows:
     * program: {
     *    program_name: ...,
     *    short_name: ...,
     *    ...rest
     * }
     */
    <Box>
      <TextInput
        autoFocus={true}
        label="Program name"
        source="program_name"
        className={classes.input}
        validate={Needed}
      />
      <TextInput
        label="Short name"
        source="short_name"
        className={classes.input}
        validate={Needed}
      />
      <Box></Box>
      <SelectInput
        label="Add parent program"
        source="parent_program_id"
        optionText="name"
        choices={listParentPrograms}
        className={clsx('add-to-program')}
        validate={Needed}
      />
      <DateInput
        label="Start Date"
        source="start_date"
        className={classes.input}
        validate={Needed}
      />
      <DateInput
        label="End Date"
        source="end_date"
        className={classes.input}
        validate={Needed}
      />
      <TextInput
        label="Describe the program (briefly)"
        source="description"
        className={clsx(classes.input, 'rich-text')}
      />
    </Box>
};

export const SelectFunderField = (props: any) => {
  
  const { isRegistered, funders, listFunders } = props;
  
  return !isRegistered /* Register funder */
    ? <SelectInput  /* Add funder */
        label="Add funder"
        source="program_funders.funder"
        optionText="name"
        choices={listFunders}
        className={clsx('add-to-program')}
        validate={Needed}
      />
    : !(funders.length > 0)
      ? <SelectInput /* Add funder [edit-mode], if none */
          label="Add funder"
          source="program_funders[0].funder"
          optionText="name"
          choices={listFunders}
          className={clsx('add-to-program')}
          validate={Needed}
        />
      : null /* TODO: Allow adding "new funders" [edit-mode] */
};

export const RenderFunderDetails = (props: any) => {

  const { isRegistered, funders } = props;

  return !isRegistered /* Allow adding "contact" */
    ? <FormDataConsumer>
        {({ formData, ...rest }) => // eslint-disable-line
          formData.program_funders && 
             formData.program_funders['funder'] && 
               !(formData.program_funders['funder'] === '0') && <FunderDetails {...props} />}
      </FormDataConsumer>
    : !(funders.length > 0)
      ? <FormDataConsumer>
         {({ formData, ...rest }) => // eslint-disable-line
           formData.program_funders && 
             formData.program_funders[0] && 
               formData.program_funders[0]['funder'] && 
                 !(formData.program_funders[0]['funder'] === '0') && <FunderDetails {...props} />}
       </FormDataConsumer>
      : <FunderDetails {...props} />
};

const FunderDetails = (props: any) => {

  const { classes, isSmall, isRegistered, funders, listFunders } = props;

  /* required fields */
  const mainContact = [ ...Needed, ...phoneNumber ];
  const eMail = [ ...Needed, ...Email ];

  return (
    <Box>
      <Separator />
      <SectionTitle label="Program Lead (funder related)" className={clsx('AkRegisterForm-title')} />
      <Separator />
      {isSmall && <Box mb={{ xs: '12px' }}></Box>}
      {isRegistered ?
        <>{/**
            * EDIT 
            */}
          <TextInput
            label="Full names"
            source="program_funders[0].contact_person_name"
            className={classes.input}
            validate={Needed}
          />
          <TextInput
            label="Role"
            source="program_funders[0].contact_person_desgination"
            className={classes.input}
            validate={Needed}
          />
          {funders && funders.length > 0 &&
            <SelectInput
              label="Funder"
              source="program_funders[0].funder"
              emptyText="None"
              choices={listFunders}
              className={clsx('add-to-program')}
            />}
          <Box></Box>
          <TextInput
            label="Phone contact (Main)"
            source="program_funders[0].contact_person_contact_1"
            className={classes.input}
            validate={mainContact}
          />
          <TextInput
            label="Phone contact (Other)"
            source="program_funders[0].contact_person_contact_2"
            className={classes.input}
            validate={phoneNumber}
          />
          <TextInput
            label="Email"
            type="email"
            source="program_funders[0].contact_person_email_address"
            className={classes.input}
            validate={Email}
          />
          <TextInput
            label="Any comment?"
            source="program_funders[0].description"
            className={clsx(classes.input, 'rich-text')}
          />
        </>
      : <>{/**
            * CREATE
            * This data is sent within a "funders" list of objects as follows:
            * funders: [{
            *    contact_person_name: ...,
            *    contact_person_desgination: ...,
            *    ...rest
            * },
            * { ... }
            * ]
            */}
          <TextInput
            label="Full names"
            source="program_funders.contact_person_name"
            className={classes.input}
            validate={Needed}
          />
          <TextInput
            label="Role"
            source="program_funders.contact_person_desgination"
            className={classes.input}
            validate={Needed}
          />
          <Box></Box>
          <TextInput
            label="Phone contact (Main)"
            source="program_funders.contact_person_contact_1"
            className={classes.input}
            validate={mainContact}
          />
          <TextInput
            label="Phone contact (Other)"
            source="program_funders.contact_person_contact_2"
            className={classes.input}
            validate={phoneNumber}
          />
          <TextInput
            label="Email"
            type="email"
            source="program_funders.contact_person_email_address"
            className={classes.input}
            validate={eMail}
          />
          <TextInput
            label="Any comment?"
            source="program_funders.description"
            className={clsx(classes.input, 'rich-text')}
          />
        </>}
    </Box>
  )
};

export const RenderFunderDialogDetails = (props: any) => {

  const { classes, isRegistered } = props;

  return isRegistered ?
    /**
     * EDIT - TODO-> add more fields in edit mode
     */
    <Box>
      <TextInput
        autoFocus={true}
        label="Funder name"
        source="funder_name"
        className={classes.input}
        validate={Needed}
      />
      <TextInput
        label="Short name"
        source="short_name"
        className={classes.input}
        validate={Needed}
      />
      <TextInput
        label="Describe the funder (briefly)"
        source="description"
        className={classes.input}
      />
    </Box>
  : /* CREATE  */
    <Box>
      <TextInput
        autoFocus={true}
        label="Funder name"
        source="funder_name"
        className={classes.input}
        validate={Needed}
      />
      <TextInput
        label="Short name"
        source="short_name"
        className={classes.input}
        validate={Needed}
      />
      <TextInput
        label="Describe the funder (briefly)"
        source="description"
        className={classes.input}
      />
    </Box>
};