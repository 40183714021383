import React from 'react';
import { Link } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import { FieldProps, Program } from '../../../../types';

const useStyles = makeStyles({
  root: {
    color: '#3f51b5',
  },
});

export const ProgramLinkField: React.FC<FieldProps<Program>> = props => {
  const classes = useStyles();
  
  const { record, resource } = props;

	return record ? (
    <Link to={`/${resource}/${record.id}`} className={classes.root}>
			{record.program_name}
    </Link>
  ) : null;
};

ProgramLinkField.defaultProps = {
  source: 'program_name',
  label: 'Program name',
};

export const FunderLinkField: React.FC<FieldProps<Program>> = props => {
  const classes = useStyles();
  
  const { record, resource } = props;

	return record ? (
    <Link to={`/${resource}/${record.id}`} className={classes.root}>
			{record.funder_name}
    </Link>
  ) : null;
};

FunderLinkField.defaultProps = {
  source: 'funder_name',
  label: 'Funder name',
};
