import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';

import { PosListContext } from 'context/pos';
import { FetchProgress } from 'layout';

const InventoryList = React.lazy(() => import("../inventory/InventoryList"));
const SalesList = React.lazy(() => import("../sales/SalesList"));

const PosList = props => {
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const openSidebar = useSelector(state => state.admin.ui.sidebarOpen); // eslint-disable-line
  useSelector(state => state.theme); // force rerender on theme change

  const programid = sessionStorage.getItem('userProgramId');

  const [open, setOpen] = React.useState(false);
  const [openDetail, setOpenDetail] = React.useState(false);
  const [data, setData] = React.useState({});

  // open "create" via "Register button"
  const handleOpen = () => setOpen(!open);

  // handles "create" modal open/close
  const handleCreateModal = () => setOpen(!open);

  // handles "Detail" modal open/close
  const handleOpenDetailModal = () => setOpenDetail(!openDetail); // eslint-disable-line

  // handles fetch of single record
  const handleData = singleRecord => setData({ ...data, ...singleRecord });
  
  return (
    <PosListContext.Provider 
      value={{
        handleCreateModal: handleCreateModal,
        handleOpen: handleOpen,
        handleData: handleData,
        programid: programid,
        isXSmall: isXSmall,
        open: open,
        data: data,
        ...props
      }}
    >
      <React.Suspense fallback={<FetchProgress title="Loading..." top={true} /> }>
        <Switch>
          <Route exact path="/inventory">
            <InventoryList />
          </Route>
          <Route exact path="/sales">
            <SalesList />
          </Route>
        </Switch>
      </React.Suspense>
    </PosListContext.Provider>
  );
};

export default PosList;
