import React from 'react';
import { Route } from 'react-router-dom';

import { DashboardSettings } from './dashboard';
import { cooperativesreportview, ManageSaccoServices, reports } from './modules';

import { Uploads } from './modules/requests/uploads/Upload';
import UserUpload from './modules/users/usercrud/UserUpload';
import GroupUpload from './modules/users/groupupload/GroupUpload';
const customRoutes = [
  <Route exact path="/providersreport-details" component={reports.serviceproviders.detail} />,
  <Route exact path="/cooperativesreport-details" component={cooperativesreportview} />,
  <Route exact path="/farmersreport-details" component={reports.farmers.detail} />,
  <Route exact path="/dashboardsettings" component={DashboardSettings} />,
  <Route exact path="/saccoservices" component={ManageSaccoServices} />,
  <Route exact path="/bulkuploads" component={Uploads} />,
  <Route exact path="/farmertool" component={UserUpload} />,
  <Route exact path="/grouptool" component={GroupUpload}/>,
];


export default customRoutes;