import React from 'react';
import { CashOrderDeliverToolbar as CashDT } from '../cashorders/CashOrderDeliver';


/**
 * For use within the RequestDeliverForm "toolbar" 
 */

export const Deliver = (props: any) => {

  const { resource } = props;

  // cash-orders
  const cashOrders = resource === 'cash-orders';

  return cashOrders ? /* refuse CASH orders */ <CashDT {...props} /> : null;
};