import { makeStyles } from '@material-ui/core/styles';

const minWidth = 960;      // md (up)
const maxWidth = 959.99;   // md (down)

// const minSmWidth = 600;    // sm (up)
const maxSmWidth = 599.98; // sm (down)

const uStyle = {
  input: {
    '& label': {
      '&:not([class*=ControlLabel])': {
        color: '#3f51b5',
        '&[class*=focused]': {
          color: '#3f51b5',
        },
      },
    },
    '& [class*=formControl]': {
      '& [class*=input]': {
        backgroundColor: 'rgba(0, 0, 0, 0.001)',
      },
      '&[class*=underline]': {
        '&:after': {
          borderColor: '#3f51b5',
        },
      },
    },   
  }, 
  check: {
    display: 'inline-flex !important',
    flexFlow: 'row wrap !important',
    '&:first-child': { width: 160 },
    '&:nth-child(2)': { width: 108 },
    '&:last-child': { width: 192 },
    '& [class*=switchBase]': {
      '&[class*=checked]': {
        color: '#3f51b5',
        '& + [class*=track]': {
          backgroundColor: '#3f51b5',
        },
      },
    },
  },
  [`@media (min-width: ${minWidth}px)`]: {
    check: {
      margin: '0.625em 1em 1.25em 0',
    },
  },
  [`@media (max-width: ${maxWidth}px)`]: {
    check: {
      margin: '0.625em 1em 0.625em 0',
    },
  },
  [`@media (max-width: ${maxSmWidth}px)`]: {
    check: {
      '&:last-child': { width: '102 !important' },
    },
  },
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  title: {
    marginTop: '0.875em !important',
  },
  details: {
    fontSize: '12.85px !important',
    fontWeight: '400 !important',
    color: 'rgba(0, 0, 0, 0.65) !important',
  },
  photo: {
    '& [class*=dropZone]': {
      backgroundColor: 'rgba(0, 0, 0, 0.09) !important',
    },    
  },
  [`@media (max-width: ${maxWidth}px)`]: {
    photo: {
      padding: '3.75em 0',
    },
  },
  [`@media (max-width: ${maxSmWidth}px)`]: {
    photo: {
      padding: '.75em 0',
    },
  },
  files: {
    '& [class*=dropZone]': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
  },
  container: {
    flexGrow: 1,
    position: 'relative',
    display: 'inline-flex !important',
  },
  none: {
    display: 'none',
  }
};

const userEditStyles = makeStyles(
  theme => ({
    ...uStyle,
  }),
  { name: 'AkbEditForm' }
);

const userDatagridStyles = makeStyles({
  total: { fontWeight: 'bold' },
  heading: { fontWeight: 'bold' },
  drawerContent: { width: 300 },
  hiddenOnSmallScreens: {
    [`@media (max-width: ${maxSmWidth}px)`]: {
      display: 'none',
    },
  },
});

export {
  userEditStyles,
  userDatagridStyles,
};
