import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import { FetchProgress } from '../../../layout';

const ProviderDelete = React.lazy(() => import("./ProviderDelete"));
const SuperagentList = React.lazy(() => import("../superagents/SuperagentList"));
const ServiceProviderList = React.lazy(() => import("../serviceproviders/ServiceProviderList"));

const ProviderList = props => {
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const { permissions, ...rest } = props;

  const programid = sessionStorage.getItem('userProgramId');

  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState({});

  // handles "delete" model open/close
  const handleOpenModal = () => setOpen(!open);

  // handles fetch of single record
  const handleData = singleRecord => setData({ ...data, ...singleRecord });

  if (!permissions) { // if no permissions
    return null;
  };
  
  const moreProps = {
    isXSmall: isXSmall,
    handleData: handleData,
    handleOpenModal: handleOpenModal,
    permissions: permissions,
    programid: programid,
    ...rest,
  };

  return (
    <React.Suspense fallback={<FetchProgress title="Loading..." top={true} /> }>
      <Switch>
        <Route exact path="/provider">
          <ServiceProviderList {...moreProps} />
        </Route>
        <Route path="/superagents">
          <SuperagentList {...moreProps} />
        </Route>
      </Switch>
      <ProviderDelete        
        isXSmall={isXSmall}
        onClose={handleOpenModal}
        open={open}
        record={data} 
        {...props} 
      />
    </React.Suspense>
  );
};

export default ProviderList;
