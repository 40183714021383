import React from 'react';
import clsx from 'clsx';
import { DateInput, SimpleForm } from 'react-admin';


export const DateFields = (props: any) => {

  const { classes } = props;

  const NoToolbar = () => null;

  return (
    <SimpleForm
      submitOnEnter={false}
      className={clsx(classes.first, 'date-filter')}
      toolbar={<NoToolbar />}
    >
      <DateInput
        label="Start date"
        source="start_date"
      />
      <DateInput
        label="End date"
        source="end_date"
      />
    </SimpleForm>
  );
};
