import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  success: {
    color: '#3f51b5',
  },
  failed: {
    color: '#3f51b5',
  },
});

export const UserMessageField = props => {
  // returns "message"
  const { handleData, onClick, record } = props;
	let message = record && record.message;

  if (!record) {
    return null;
  }

  return (
    <span onClick={()=>(onClick(),handleData(record))}> {/* eslint-disable-line */}
      {message.slice(0,32)}{message.length>32?'...':''}
    </span>
  );
};

UserMessageField.defaultProps = {
  label: 'Message',
  textAlign: 'left',
};

export const UserMessageSentField = ({ record }) => {
  const classes = useStyles();

  // returns "message"
	let status = record && record.is_sent;

  if (!record) {
    return null;
  }

  return (
    <span>{status?<span className={classes.success}>success</span>:<span className={classes.failed}>failed</span>}</span>
  );
};

UserMessageSentField.defaultProps = {
  label: 'Status',
  textAlign: 'left',
};
