import coordinators from './coordinators';
import groupmembers from './groupmembers';
import accountants from './accountants';
import farmers from './farmers';
import agents from './agents';

const users = {
  agents: agents,
  farmers: farmers,
  accountants: accountants,
  groupmembers: groupmembers,
  coordinators: coordinators,
};

export default users;
