
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Button } from 'react-admin';
import { Link } from 'react-router-dom';

const None = () => null;

export const LinkButton = ({
  url,
  size = 'small',       /* small   | medium | large */
  color = 'primary',    /* primary | secondary */
  label = <None />,
  icon = <None />,
  classname = '',
  ...props
}) => {
  return (
    <Button
      to={url}
      size={size}
      color={color}
      component={Link}
      className={clsx('linkButton', classname)}
      {...props}
    >
      {!icon ? label : <>{icon}&nbsp;{label}</>}
    </Button>
  );
};

LinkButton.propTypes = {
	url: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
	size: PropTypes.string,
  color: PropTypes.string,
	label: PropTypes.oneOfType([ PropTypes.element, PropTypes.string ]),
	icon: PropTypes.oneOfType([ PropTypes.element, PropTypes.any ]),
  classname: PropTypes.string,
};
