import React from 'react';
import clsx from 'clsx';
import format from 'date-fns/format';
import formatDistance from 'date-fns/formatDistance';
import differenceInHours from 'date-fns/differenceInHours';
import { Box, DialogContent, makeStyles } from '@material-ui/core';

import CreditOrderEdit from '../creditorders/CreditOrderEdit';
import CashOrderEdit from '../cashorders/CashOrderEdit';

import { ApprovedByField, DeliveryField, RequestedByField, OrderTableField, ProviderField } from '../requestfields';
import { RequestDeliverForm } from './RequestDeliverForm';
import { RequestAcceptForm } from './RequestAcceptForm';
import { RequestRejectForm } from './RequestRejectForm';

import { Separator, SectionTitle } from '../../utils';
import { requestStyles } from '../../stylesheets';

const useStyles = makeStyles({
	withInTime: {
		color: '#3f51b5',
	},
	outOfTime: {
		color: '#df1f26',
	},
});

const RequestDetailForm = props => {
  const classes = requestStyles();
  const classesBase = useStyles();

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const {
    hasCreate, 
    hasEdit, 
    hasList, 
    hasShow,
    record,
    resource,
    permissions,
    // from the direct parent component
    isXSmall,
    deliverDialog,
    rejectionDialog,
    acceptanceDialog,
    handleOpenAcceptDialog,
    handleOpenRejectDialog,
    handleCloseAcceptDialog,
    handleCloseRejectDialog,
    handleDeliverDialog,
    // passed to the <SimpleForm>
    ...rest
  } = props;
  
  // destructure "record"
  const { completion, request_status } = record;

  /* request status */
  const pending = request_status && request_status === "pending";  
  const approved = request_status && request_status === "approved";
  const rejected = request_status && request_status === "rejected";  
  const accepted = request_status && request_status === "accepted";
  const delivered = request_status && request_status === "delivered";  

  // returns "hours"
	const timeSinceRequest = record &&
    record.request_date &&
      differenceInHours(new Date(), new Date(record['request_date']));

  const RequestDateDetails = () => (
    // allow styling for modal-request-date    
    <p className={clsx('details')}>
      <span className={clsx('intro')}>Date requested:&nbsp;</span>
      {format(new Date(record['request_date']), 'dd/MM/yyyy')}
      {resource !== "aggregated-orders"
        && <>
            <br/>
            <span className={clsx({
              [classesBase.withInTime]: timeSinceRequest < 24,
              [classesBase.outOfTime]: timeSinceRequest >= 24,
            })}>
              ({formatDistance(new Date(record['request_date']), new Date(), { addSuffix: true } )})
            </span>
           </>
      }
    </p>
  );

  // passed to ...OrderEdit
  const orderProps = {
    isXSmall: isXSmall,
    requestStatus: request_status,
    handleDeliverDialog: handleDeliverDialog,
    handleOpenAcceptDialog: handleOpenAcceptDialog,
    handleOpenRejectDialog: handleOpenRejectDialog,
  };

  // passed to ...dialogs
  const dialogProps = {
    record: record,
    isXSmall: isXSmall,
    resource: resource,
    ...rest
  };

  // passed to ...OrderTableField
  const generalProps = {
    classesBase: classesBase,
    resource: resource,
    classes: classes,
    record: record,
  };

  // passed to ...RequestedByField
  const requestByProps = {
    // request status
    delivered: delivered,
    accepted: accepted,
    ...generalProps
  };

  // passed to ...ApprovedByField
  const approveByProps = {
    // request status
    approved: approved,
    rejected: rejected,
    ...generalProps
  };

  return (
    <DialogContent dividers>
      <Box
        display={{ xs: 'block' }} 
        width={{ xs: '100% !important' }}
      >
        <SectionTitle label="" show={<RequestDateDetails/>} />
      </Box>
      {resource !== "aggregated-orders" && <Separator />}
      {/* "approved by" section */} <ApprovedByField  {...approveByProps} />
      <Separator />
      {/* "requested by" section */} <RequestedByField  {...requestByProps} />
      <Separator />
      {/* "service provider" section */} <ProviderField  {...requestByProps} />
      <Separator />
      {/* "items requested" table */} <OrderTableField {...generalProps} />
      {request_status       
        && /* if it's CREDIT request */ resource === "cash-orders"         
          && /* check if request was "accepted" */ accepted           
            && /* check if we're at "completion" */ completion 
              && <DeliveryField completion={completion} classesBase={classesBase} />
      }
      <Box
        display={{ xs: 'flex' }}
        width={{ xs: '100% !important' }}
        className={clsx(
          {
            'two--btns': approved || pending,
            'one--btn': accepted,
          },
        )}
      >
        {/* confirm request status */ request_status           
            && /* if it's CREDIT request */ resource === "credit-orders"              
              ? /* Show the CREDIT request buttons */ <CreditOrderEdit {...orderProps} />             
              : /* else if it's CASH request */ resource === "cash-orders"
                ? /* Show the CASH request buttons */ <CashOrderEdit {...orderProps} />
                : /* else don't show buttons */ null
        }
        {rejectionDialog &&
          <RequestRejectForm
            handleCloseRejectDialog={handleCloseRejectDialog}
            rejectionDialog={rejectionDialog}
            {...dialogProps} 
          />
        }          
        {acceptanceDialog &&
          <RequestAcceptForm
            handleCloseAcceptDialog={handleCloseAcceptDialog}
            acceptanceDialog={acceptanceDialog}
            {...dialogProps}
          />
        }
        {deliverDialog &&
          <RequestDeliverForm
            handleDeliverDialog={handleDeliverDialog}
            deliverDialog={deliverDialog}
            {...dialogProps}
          />
        }
      </Box>    
    </DialogContent>
  );
};

export default RequestDetailForm;