import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useNotify } from 'react-admin';
import CooperativeAcionList from './CustomField';
import { apiFullCall } from '../../apiHelper';

const useStyles = makeStyles({
  root: {
    width: '100%',
    marginTop: '4px'
  },
  container: {
    maxHeight: 540,
  },
});

const CooperativeReportList = props => {

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [cooperatives, setCooperatives] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = React.useState(true);
  const [load, setLoader] = React.useState(true);
  const notify = useNotify();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const updateState = () => {
    setLoading(false)
  };

  useEffect(() => {
    var cooperativesclass = props.location.state ? props.location.state.classification : JSON.parse(sessionStorage.getItem('_cooperatives_classification'));
    var filter = props.location.state ? props.location.state.filters : JSON.parse(sessionStorage.getItem('_cooperatives_filter'))

    apiFullCall(
      '',
      sessionStorage.getItem('token'),
      'get',
      `cooperativesreportview/?filter_1=${filter.filter_1}&filter_2=${filter.filter_2}&filter_3=${filter.filter_3}&filter_4=${filter.filter_4}&filter_5=${filter.filter_5}&filter_6=${cooperativesclass.id}`
    ).then(res => {
      const { status, body } = res;

      if (status === 200 || status === 201) {
        setLoader(false)
        setCooperatives(body.results)

        const timer = setTimeout(() => {
          updateState()
        }, 5000);
        return () => clearTimeout(timer);

      } else {
        notify(`Experienced an error, please try again later.`, 'warning')
      };

    }).catch(
      error => console.error('Error while getting programs:', error)
    );

  }, [notify, props.location.state]);

  return (
    <Paper className={classes.root}>
      <div style={{ textAlign: 'center' }}><h3>{props.location.state ? props.location.state.classification.coop_service_name : JSON.parse(sessionStorage.getItem('_cooperatives_classification'))["coop_service_name"]}</h3></div>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                align={'left'}
                style={{ minWidth: '10' }}
              >
                check all
              </TableCell>
              <TableCell
                align={'left'}
                style={{ minWidth: '100' }}
              >
                No
              </TableCell>
              <TableCell
                align={'left'}
                style={{ minWidth: '170' }}
              >
                Cooperative Name
              </TableCell>
              <TableCell
                align={'left'}
                style={{ minWidth: '170' }}
              >
                Primary Contact
                </TableCell>
              <TableCell
                align={'left'}
                style={{ minWidth: '50' }}
              >
                Active
                </TableCell>
                <TableCell
                align={'left'}
                style={{ minWidth: '50' }}
              >
                Branch(es)
              </TableCell>
              <TableCell
                align={'left'}
                style={{ minWidth: '170' }}
              >
                Village
                </TableCell>
              <TableCell
                align={'left'}
                style={{ minWidth: '170' }}
              >
                Subcounty
               </TableCell>
              <TableCell
                align={'left'}
                style={{ minWidth: '170' }}
              >
                District
              </TableCell>
              <TableCell
                align={'left'}
                style={{ minWidth: '170' }}
              >
                Manage
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cooperatives.length > 0 && cooperatives.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((cooperative, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  <TableCell align={'left'}>
                    {index + 1}
                  </TableCell>
                  <TableCell align={'left'}>
                    {index + 1}
                  </TableCell>
                  <TableCell align={'left'}>
                    {cooperative.cooperative_branch}
                  </TableCell>
                  <TableCell align={'left'}>
                    {cooperative.primary_contact}
                  </TableCell>
                  <TableCell align={'center'}>
                    {cooperative.is_active === true ? `Yes` : `No`}
                  </TableCell>
                  <TableCell align={'center'}>
                    {cooperative.branches}
                  </TableCell>
                  <TableCell align={'left'}>
                    {loading ? <LinearProgress color="primary" /> : cooperative.location.village}
                  </TableCell>
                  <TableCell align={'left'}>
                    {loading ? <LinearProgress color="primary" /> : cooperative.location.subcountyname}
                  </TableCell>
                  <TableCell align={'left'} >
                    {loading ? <LinearProgress color="primary" /> : cooperative.location.districtname}
                  </TableCell>
                  <TableCell align={'center'} >
                    <CooperativeAcionList {...cooperative} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {!load &&
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={cooperatives.length > 0 ? cooperatives.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      }
      {load &&
        <div style={{ textAlign: 'center', padding: '20px' }}><CircularProgress color="primary" /></div>
      }
    </Paper>
  )
};

export default CooperativeReportList;