import React from 'react';
import PropTypes from 'prop-types';
import { Identifier } from 'ra-core';
import SendIcon from '@material-ui/icons/Send';
import { BulkSaveWithConfirmButton, BulkSaveWithConfirmButtonProps } from './BulkSaveWithConfirmButton';
import {ConfirmAssignment } from 'modules/groups';
const None = () => null;

export const FarmerGroupAssigmentButton: React.FC<FarmerGroupAssigmentButtonProps> = ({ undoable, ...props }) => {
  const moreProps = {
    label: 'Farmer Assignment',
    confirmTitle: 'Groups',
    mainTitle: `Farmer Group Assignment`,
    ...props
  };
  return undoable ? (
    <BulkSaveWithConfirmButton {...moreProps} />
  ) : null;
};

interface Props {
  undoable?: boolean;
  basePath?: string;
  label?: string;
  icon?: React.ReactElement;
  resource?: string | any;
  selectedIds?: Identifier[] | any;
};

type FarmerGroupAssigmentButtonProps = Props & BulkSaveWithConfirmButtonProps;

FarmerGroupAssigmentButton.propTypes = {
  basePath: PropTypes.string,
  label: PropTypes.string,
  resource:  PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  selectedIds: PropTypes.arrayOf(PropTypes.any),
  undoable: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.any]),
};

FarmerGroupAssigmentButton.defaultProps = {
  undoable: true,
  content1: <None />,
  content2: <ConfirmAssignment/>,
  icon: <SendIcon />,
};
