import React from 'react';
import clsx from 'clsx';
import Progress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    root: {
      margin: `${theme.spacing(1)}px 0`,
      width: `${theme.spacing(20)}px`,
    },
  }),
  { name: 'RaCircularProgress' }
);

/**
 * Progress bar formatted to replace an input or a field in a form layout
 *
 * Avoids visual jumps when replaced by value or form input
 *
 * @see ReferenceField
 * @see ReferenceInput
 *
 * @param {Object} classes CSS class names
 */
export const CircularProgress = props => {
  const { classes: classesOverride, className, ...rest } = props;
  const classes = useStyles(props);

  return (
    <Progress className={clsx(classes.root, className)} {...rest} />
  );
};

CircularProgress.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
};

// wat? TypeScript looses the displayName if we don't set it explicitly
CircularProgress.displayName = 'CircularProgress';

