import React from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom'
import { TextField } from 'react-admin';
import { Avatar, Card, CardHeader, CardContent } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/PeopleOutline';

import { CircularProgress } from '../../../layout';

const TransactionReportCard = props => {
  const history = useHistory();

  const { ids, data, filters, basePath, loading } = props;

  const viewDetails = transactionclass => {
    sessionStorage.setItem('_transactions_filter', JSON.stringify(filters));
    sessionStorage.setItem('_transactions_classification', JSON.stringify(transactionclass));

    history.push({ 
      basePath: basePath, 
      pathname: '/transactionsreport-details', 
      state: { transactionclass: transactionclass, filters: filters } 
    });
  };

  return (
    <div className={clsx('report-section')}>
      {ids.map((id, i) =>
        <Card
          key={i}
          raised={false}
					className={clsx('report-card', 'transactions')} 
          onClick={() => viewDetails(data[id])}
        >
          <CardHeader
            title={<TextField record={data[id]} source="name" />}
            avatar={<Avatar icon={<PersonIcon />} />}
          />
          {loading
						? <CircularProgress className={clsx('sm-wide')} />
            : <CardContent>
                <TextField record={data[id]} source="count" /><br />
                <TextField record={data[id]} source="total" />
              </CardContent>}
        </Card>
      )}
    </div>
  );
};

TransactionReportCard.defaultProps = {
  data: {},
  ids: [],
};

export default TransactionReportCard;