import React from 'react';
import { List, Datagrid, TextField, DateField } from 'react-admin';

import ViewTransactionDetails from './customField';

const TransactionReportList = props => {
  const { location } = props;
  const { state } = location && location;

  // These are set when the card are loaded
  const transactionClassification = sessionStorage.getItem('_transactions_classification');
  const farmersFilter = sessionStorage.getItem('_transactions_filter');

  const [filters, setfilters] = React.useState({}); // eslint-disable-line
  const [transactionclass, setClassification] = React.useState({}); // eslint-disable-line

  let isLoaded = React.useRef(true);

  React.useEffect(() => {

    if (isLoaded) {

      var classification = state ? state.transactionclass : JSON.parse(transactionClassification);
      var farmerfilters = state ? state.filters : JSON.parse(farmersFilter);

      setfilters(farmerfilters);
      setClassification(classification);

    };

    // clean up API call, on unmount
    return function cleanup() {
      isLoaded.current = false;
    };

  }, [isLoaded, filters, state]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <List
      {...props}
      perPage={15}
      title="Transactions Report"
      filterDefaultValues={{ /* mitigate to avoid "Not found" */
        filter_1: filters.filter_1 ? filters.filter_1 : 'none',
        filter_2: filters.filter_2 ? filters.filter_2 : 0,
        filter_3: filters.filter_3 ? filters.filter_3 : 'none',
        filter_4: filters.filter_4 ? filters.filter_4 : 'none',
        filter_5: filters.filter_5 ? filters.filter_5 : 'none',
        filter_6: transactionclass.id ? transactionclass.id : 'A'
      }}
    >
      <Datagrid {...props}>
        <TextField source="request_number" label="Request Number" />
        <TextField source="provider.business_name" label="Service Provider" />
        <TextField source="provider.primary_contact" label="Contact" />
        <TextField source="farmer.farmer_full_name" label="Farmer" />
        <TextField source="farmer.farmer_username" label="Contact" />
        <TextField source="total" label="Total" />
        <TextField source="request_status" label="Status" />
        <TextField source="payment_method" label="Payment Method" />
        <DateField source="request_date" label="Added" showTime />
        <ViewTransactionDetails source="request_date" label="Action"/>
      </Datagrid>
    </List>
  );
};

export default TransactionReportList;