import React from 'react';
import { Route } from 'react-router-dom';

import { FetchProgress } from '../../../layout';

const CoopBranchList = React.lazy(() => import("../coopbranches/CoopBranchList"));

const GroupList = props => {
  
  const { permissions } = props;
  
  if (!permissions) { // if no permissions
    return null;
  };

  return (
    <React.Suspense fallback={<FetchProgress title="Loading..." top={true} /> }>
      <Route path="/groups">
        <CoopBranchList {...props} />
      </Route>
    </React.Suspense>
  )
};

export default GroupList;
