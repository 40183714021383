import React from 'react';

export const FullNameField = ({ record = {}, source }) => (
  <div>
    {record['first_name']} {record['last_name']} {record['middle_name']}
  </div>
);

export const CustomBooleanField = ({ record = {}, source }) => (
  <div>{record[source] ? 'Yes' : 'No'}</div>
);

export const GenderField = ({ record = {}, source }) => (
  <div>
    {record[source] === 'M'
      ? 'Male'
      : record[source] === 'F'
      ? 'Female'
      : 'Other'}
  </div>
);
