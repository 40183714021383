import React from 'react';
import { Redirect } from 'react-router';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Create } from 'react-admin';

import { ProviderTitle } from '../providerops';
import { FetchProgress } from '../../../layout';

import * as P from '../../../allPermissions';

const ProviderMultiForm = React.lazy(() => import("../providerforms/ProviderMultiForm"));

const ProviderCreate = (props: any) => {

  const history = useHistory();
  const { basePath, permissions } = props;

  const UserIsPermitted = P.authCreateProviders(permissions);
  const createSuperagent = P.authCreateSuperagents(permissions);
  const createProdSup = P.authCreateProSup(permissions);
  
  const handleClose = React.useCallback(() => {
    history.push(basePath);
  }, [history, basePath]);

  if (!permissions) { // if no permissions
    return null;
  };

  const moreProps = {
    onCancel: handleClose,
    createSuperagent: createSuperagent,
    createProdSup: createProdSup,
    ...props,
  };

  return (
    <React.Suspense fallback={<FetchProgress title="Loading..." top={true} /> }>
      {UserIsPermitted
        ? <Route path="/provider">
            <NewServiceprovider {...moreProps} />
          </Route>
        : createSuperagent
          ? <Route path="/superagents">
              <NewSuperagent {...moreProps} />
            </Route>
          : createProdSup
            ? <Switch>
                <Route exact path="/provider/create">
                  <NewServiceprovider {...moreProps} />
                </Route> 
                <Route path="/superagents">
                  <NewSuperagent {...moreProps} />
                </Route>
              </Switch> 
            : <Redirect to="/" />}
    </React.Suspense>
  )
};


const NewServiceprovider = (props: any) => {
  const {
    createSuperagent,
    createProdSup,
    ...rest 
  } = props;

  const {
    basePath,
    hasCreate,
    hasEdit,
    hasList,
    hasShow,
    permissions,
    ...other
  } = rest;

  const moreProps = {
    handleSuperagent: createSuperagent,
    handleBothSP: createProdSup,
    ...other
  };

  return (
    <Create title={<ProviderTitle />} {...rest}>
      <ProviderMultiForm {...moreProps} />
    </Create>
  );
};


const NewSuperagent = (props: any) => {
  const {
    createSuperagent,
    createProdSup,
    ...rest 
  } = props;

  const {
    basePath,
    hasCreate,
    hasEdit,
    hasList,
    hasShow,
    permissions,
    ...other
  } = rest;

  const moreProps = {
    handleSuperagent: createSuperagent,
    handleBothSP: createProdSup,
    ...other
  };

  return (
    <Create title="Super-agent Registration" {...rest}>
      <ProviderMultiForm {...moreProps} />
    </Create>
  );
};


export default ProviderCreate;
