import React, { useState} from 'react';
import readXlsxFile from 'read-excel-file';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  CircularProgress,
} from '@material-ui/core';

import apiCall from 'modules/apiHelper';
import { requestStyles } from 'modules/stylesheets';

const GroupUpload = () => {
  const classes = requestStyles();

  const token = sessionStorage.getItem('token');

  var theJsonArray = [];
  const [loading, setLoading] = React.useState(false);
  const [buttontext, setButtontext] = React.useState("CHOOSE A FILE");
  // File Upload
  const [file, SetFile] = useState(null);
  const [helperText, SetHelperText] = useState('');
  const hiddenFileInput = React.useRef(null);
  const [programs, setPrograms] = useState([]);
  const [program, setProgram] = useState({ id: 0 });
  const [superagents, setSuperagents] = useState([]);
  const [superagent, setSuperagent] = useState({ id: 0 });
  const [listServices, setListServices ] = React.useState([]);
  const [service, setService] = useState({ id: 0 });
  const [errors, setErrors] = useState({program:false,superagent:false ,service:false});
  // Fetch Programs
  React.useEffect(() => { 
    apiCall('', sessionStorage.getItem('token'), 'get', `cooperativeservices/`)
    .then(serviceData => {
      setListServices(serviceData.results);
    }).catch(error => console.error('Error during fetch:', error));

    apiCall('', sessionStorage.getItem('token'), 'get', `programs/`)
      .then(programData => {
        setPrograms(programData.results);
      })
      .catch(error => console.error('Error during fetch:', error));
  }, []) 
  
  const validateForm = () => {
    const errorDetails = {};
    let valid = true;
    if (program.id === 0) {
      errorDetails.program = true;
      valid = false;
    }else{
      errorDetails.program = false;
    }
   
    if (superagent.id === 0) {
      errorDetails.superagent = true;
      valid = false;
    }
    else{
      errorDetails.superagent = false;
    }

    if (service.id === 0) {
      errorDetails.service = true;
      valid = false;
    }else{
      errorDetails.service = false;
    }

    if (!(program.id === 0 || superagent.id === 0 || service.id === 0)) {
      errorDetails.program = false;
      errorDetails.superagent = false;
      errorDetails.service = false;
    }
    if (file === null) {
      SetHelperText('Please fill in this required field')
      valid = false;
    }
    setErrors(errorDetails);
    return valid;
  };

  // Handle selected Service
  const selectService = event => {
    const value2 = event.target.value;
    for (let i = 0; i < programs.length; i++) {
      if (listServices[i].id === value2) {
        setService(listServices[i]);
        break;
      }
    }
  }

  // Handle selected Program
  const selectProgram = event => {
    setSuperagents([])
    setSuperagent({ id: 0 });
    const value2 = event.target.value;
    for (let i = 0; i < programs.length; i++) {
      if (programs[i].id === value2) {
        setProgram(programs[i]);
        break;
      }
    }

    // Fetch Super Agent
    apiCall('', token, 'get', `programsuperagent/?program_id=${value2}`)
      .then(superagentData => {
        setSuperagents(superagentData.results)
      })
    .catch(error => console.error('Error during fetch:', error));
  };

  // Handle selected Super Agent
  const selectSuperagent = event => {
    const value = event.target.value;
    for (let i = 0; i < superagents.length; i++) {
      if (superagents[i].id === value) {
        setSuperagent(superagents[i]);
        break;
      }
    }
  };

  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  const handleFileChange = event => {
    const fileUploaded = event.target.files[0];
    SetFile(fileUploaded)
    setButtontext(fileUploaded.name);
  };

  // End of File Upload
  const handleSubmit2 = event => {
    theJsonArray = [];
    if (validateForm()) {
      readXlsxFile(file).then((rows) => {
        for (var cc = 2; cc < rows.length; cc++) {
          if (
              !!rows[cc][0]  
              && !!rows[cc][5]  && !!rows[cc][6]
              && !!rows[cc][7] && !!rows[cc][10] && !!rows[cc][11]
              ) {
              var birthDate = "";
              var month = 0;
              var date = new Date('12/05/1978');
              if (typeof (rows[cc][10]) === 'string') {
                birthDate = rows[cc][10].replaceAll('/', '-');
              }else if (!isNaN(rows[cc][10])) {
                date = new Date(rows[cc][10]);
                month = date.getMonth() + 1;
                birthDate = date.getDate() + '/' + month + '/' + date.getFullYear();
              }else if(!rows[cc][10]){
                month = date.getMonth() + 1;
                birthDate = date.getDate() + '/' + month + '/' + date.getFullYear();
              }
              
            theJsonArray.push({
              group_ame: rows[cc][0],
              short_name: rows[cc][1],
              branch_name: rows[cc][2],
              income: rows[cc][3],
              physical_address: rows[cc][4],
              village: rows[cc][5],
              full_name: rows[cc][6],
              phone_no: rows[cc][7],
              other_phone_no: rows[cc][8],
              email: rows[cc][9],
              dob: birthDate,
              gender: rows[cc][11],
              nin : rows[cc][12],
              manager_role:rows[cc][13]
            });
          }
        }
        //change n to 30(any number) or the number needed for each api call
        const n = 30

        const result = new Array(Math.ceil(theJsonArray.length / n))
          .fill()
          .map(_ => theJsonArray.splice(0, n))
        setLoading(true);
        setTimeout(function () {
          result.forEach(result => {
            const uploadData = {
              users: result,
              programid:program.id,
              superagent:superagent.id,
              service:service.id
            }
            apiCall({ 'data': uploadData }, token, 'post', 'agentgroupupload/').then(response => {
              setLoading(false);
              SetFile(null)
              setButtontext("CHOOSE A FILE")
              setProgram({ id: 0 })
              setSuperagent({ id: 0 })
              setService({ id: 0 })
              setErrors({program:false,superagent:false ,service:false})
            }).catch(error => console.error('Error while Uploading:', error));
          });
        }, 5000)

      }).catch(error => console.error('Error while reading:', error));
    }
  }

  return (
    loading 
    ? <CircularProgress style={{ width: "100px", height: "100px", marginLeft: "40%", marginTop: "10%" }} /> 
    : <form autoComplete="off"  style={{marginTop: "5%" }} >
          <div className={classes.formRow}>
            <div className={classes.formFourColumn} style={{ width: '60%', display:'block', margin:'auto' }}>
               {/* Selecting Program */}
               <FormControl className={classes.formInput} variant="outlined" required>
                <InputLabel
                  required
                  id="program-field-label"
                  data-testid="program_field"
                >
                  Select Program
                </InputLabel>
                <Select
                  labelId="program-field-label"
                  id="program_field"
                  data-testid="program_field"
                  label="Select Program"
                  onChange={selectProgram}
                  error={errors.program}
                  value={program.id}
                >
                  {programs.map((prog, index) => (
                    <MenuItem value={prog['id']} key={index}>
                      {prog['program_name']}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <br /><br />
              <FormControl className={classes.formInput} variant="outlined" required>
                    <InputLabel
                      required
                      id="variety_field-label"
                      data-testid="super-agent-field"
                    >
                      Select Super Agent
                    </InputLabel>
                    <Select
                      labelId="variety_field-label"
                      id="super-agent-field"
                      data-testid="super-agent-field"
                      label="Select Super Agent"
                      onChange={selectSuperagent}
                      value={superagent.id}
                      error={errors.superagent}
                    >
                      {superagents.map((s_agent, index) => (
                        <MenuItem value={s_agent['id']}>
                          {s_agent['name']}
                        </MenuItem>
                      ))}
                    </Select>
               </FormControl>
                <br /><br />
               {/* Selecting Service*/}
               <FormControl className={classes.formInput} variant="outlined" required>
                <InputLabel
                  required
                  id="service-field-label"
                  data-testid="service_field"
                >
                  Select Service
                </InputLabel>
                <Select
                  labelId="service-field-label"
                  id="service_field"
                  data-testid="service_field"
                  label="Select Program"
                  onChange={selectService}
                  error={errors.service}
                  value={service.id}
                >
                  {listServices.map((service, index) => (
                    <MenuItem value={service['id']} key={index}>
                      {service['coop_service_name']}
                    </MenuItem>
                  ))}
                </Select>
               </FormControl>
                <br /><br />
              {/* Choose User File to Upload */}
              <FormControl className={classes.formInput} variant="outlined" required>
                <Button
                  onClick={handleClick}
                >
                  {buttontext}
                </Button>
                <input
                  id="upload_file"
                  type="file"
                  ref={hiddenFileInput}
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                  accept=".xlsx, .xls, .csv"
                />
              </FormControl>
              <FormHelperText style={{borderBottom:'1px solid #722F37', color:'#722F37'}} >{helperText}</FormHelperText>
              <br /><br />
              <Button
                style={{background:'#722F37', color:'#fff'}} 
                variant="contained"
                className={classes.addProgramFunderButton}
                onClick={handleSubmit2}
              >
                Upload
              </Button>
            </div>
          </div>
        </form>
    ); 
};

export default GroupUpload;