import React from 'react';
import clsx from 'clsx';
import { Box, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { RenderFunderDialogDetails } from './DetailFields';
import { SectionTitle } from '../../../utils';


export const FunderFields = (props: any) => {
  const { classes, isSmall, onCancel, isRegistered } = props;

  const generalProps = {
    isSmall: isSmall,
    classes: classes,
    isRegistered: isRegistered,
  };

  return (
    <Box>
      <Box display={{ xs: 'inline-flex' }} width={{ xs: '100% !important' }} mb={{ xs: '10px' }}>
        <Box flex={1}>
          <SectionTitle label="Funder details" className={clsx('AkRegisterForm-title')} />
        </Box>
        <Box m={{ xs: '-10px -12px 10px 0' }}>
          <IconButton onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>   
      {/* 
        * SimpleForm expects Input or Field components as children.
        * Explained here: https://marmelab.com/react-admin/CreateEdit.html#the-simpleform-component. 
        * So it's normal that you see warnings because we passed <Box>
        */}
      <Box
        display={{ md: 'flex' }}
        className={clsx('AkRegisterForm-body')}
      >
        <RenderFunderDialogDetails {...generalProps} />
      </Box>    
    </Box>
  )
};
