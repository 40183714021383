import React from 'react';
import { Redirect } from 'react-router';
import { Route, useHistory } from 'react-router-dom';
import { Edit } from 'react-admin';

import { GroupTitle } from '../groupfields';
import { FetchProgress } from '../../../layout';

import * as P from '../../../allPermissions';

const GroupMultiForm = React.lazy(() => import("../groupforms/GroupMultiForm"));

const GroupEdit = (props: any) => {
  const { basePath, permissions } = props;

  const history = useHistory();

  const addEditGroups = P.authEditGroups(permissions);

  const handleClose = React.useCallback(() => {
    history.push(basePath);
  }, [history, basePath]);

  if (!permissions) { // if no permissions
    return null;
  };

  const moreProps = {
    onCancel: handleClose,
    addEditGroups: addEditGroups,
    ...props
  };

  return <EditComponent {...moreProps} />;
};

const EditComponent = (props: any) => {
  const {
    onCancel,
    addEditGroups,
    ...other
  } = props;

  const { 
    basePath, 
    hasCreate, 
    hasEdit, 
    hasList, 
    hasShow,
    match,
    permissions,
    ...rest 
  } = other;

  const moreProps = {
    onCancel: onCancel,
    permissions: permissions,
    redirect: hasCreate ? 'list' : basePath,
    ...rest
  };

  return (  
    <React.Suspense fallback={<FetchProgress title="Loading section..." top={true} /> }>
      {addEditGroups
        ? <Route path="/groups/:id">
            <Edit {...other} title={<GroupTitle />}>
              <GroupMultiForm {...moreProps} />
            </Edit>
          </Route>
        : <Redirect to="/" />}
    </React.Suspense>
  )
};

export default GroupEdit;
