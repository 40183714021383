import React from 'react';
import PropTypes from 'prop-types';
import { Identifier } from 'ra-core';
import SendIcon from '@material-ui/icons/Send';
import { BulkSaveWithConfirmButton, BulkSaveWithConfirmButtonProps } from './BulkSaveWithConfirmButton';

import { SmsCreate } from 'modules/sms';

const None = () => null;

export const SmsButton: React.FC<SmsButtonProps> = ({ undoable, ...props }) => {

  const { selectedIds, resource } = props;
  const total = selectedIds && selectedIds.length;

  const sendToWho = resource==="users"?'farmer':resource==="groups"?'Group':resource==="programs"?'Program':'';

  const moreProps = {
    label: 'Send sms',
    confirmTitle: 'message(s)',
    mainTitle: `Bulk sms ... ${total} ${sendToWho}${total>1?'s':''}`,
    ...props
  };

  return undoable ? (
    <BulkSaveWithConfirmButton {...moreProps} />
  ) : null;
};

interface Props {
  undoable?: boolean;
  basePath?: string;
  label?: string;
  icon?: React.ReactElement;
  resource?: string | any;
  selectedIds?: Identifier[] | any;
};

type SmsButtonProps = Props & BulkSaveWithConfirmButtonProps;

SmsButton.propTypes = {
  basePath: PropTypes.string,
  label: PropTypes.string,
  resource:  PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  selectedIds: PropTypes.arrayOf(PropTypes.any),
  undoable: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.any]),
};

SmsButton.defaultProps = {
  undoable: true,
  content1: <None />,
  content2: <SmsCreate />,
  icon: <SendIcon />,
};
