import React from 'react';
import clsx from 'clsx';
import { useFormState } from 'react-final-form';

import { SaveButton, Toolbar, useRefresh, useNotify } from 'react-admin';

import { ApproveButton } from '../../../layout';
import { apiFullCall } from '../../apiHelper';


// Handling "create" mode
export const CashOrderDeliverToolbar = props => {

  // destructure to pass directly to <ApproveButton>
  const { isXSmall, ...rest } = props;
  const { handleDeliverDialog, setErrorStatus, ...other } = rest;

  return (
    <Toolbar {...other}>
      <ApproveButton
        label="No"
        icon={null}
        isXSmall={isXSmall}
        className={clsx('small--btn')}
        onClick={handleDeliverDialog}
      />
      <DeliverButton {...rest} /> 
    </Toolbar>
  )  
};

const DeliverButton = props => {
  const formState = useFormState();
  const refresh = useRefresh();
  const notify = useNotify();

  // destructure to pass "rest" to <SaveButton>
  const { handleDeliverDialog, mutationMode, setErrorStatus, onClose, record, validating, ...rest } = props;

  // destructure "date" and "reason" to pass to API call
  const { completion_code } = formState && formState.values;

  const token = sessionStorage.getItem('token');
  
  const [ loading, setLoading ] = React.useState(false); // eslint-disable-line

  let isLoaded = React.useRef(false);

  React.useEffect(() => {
    /* Cleanup useEffect */

    isLoaded.current = true; // set to true on mount...
    return () => { isLoaded.current = false; }; // ... and to false on unmount
  }, [isLoaded]); // eslint-disable-line react-hooks/exhaustive-deps
  

  const validateCode = () => {
    const code = document.getElementById('completion_code-helper-text');
    code.focus();
    triggerEvent(code, 'keyup');
  };

  const triggerEvent = (el, type) => {
    if ('createEvent' in document) {
      // modern browsers, IE9+
      let e = document.createEvent('HTMLEvents');
      e.initEvent(type, false, true);
      el.dispatchEvent(e);
    } else {
      // IE 8
      let e = document.createEventObject();
      e.eventType = type;
      el.fireEvent('on'+e.eventType, e);
    }
  }

  const deliveryData = {
    request_id: record && record['id'],
    completion_code: completion_code,
  };

  const handleDelivery = React.useCallback(() => {

    // Double-check to ensure that "date", "reason" and "id" are defined or not empty
    if (!!completion_code && !!record['id']) {

      apiFullCall(
        deliveryData, 
        token, 
        'post', 
        `completerequest/` 
      ).then(res => {

        if (res) {

          if (isLoaded.current) setLoading(true);

          const { status } = res;

          if (status === 200 || status === 201) {

            // when successfully submitted
            notify('Delivery has been confirmed', 'info');

            // go back to requests list
            handleDeliverDialog();
            onClose();
            refresh();
            
          } else if (status === 406) {
            // notify(body['message'], 'warning');
            validateCode();
            setErrorStatus(true);
            setLoading(!!loading);

          } else if (status >= 500) {
            notify(`Server error, please try again later.`, 'warning');          
          };

          if (isLoaded.current) setLoading(false);

        };

      }).catch(
        error => console.error('Error while confirming delivery:', error)
      );

    } else {

      // inform user to enter a "date" and/or "comment"
      notify(`Please add a delivery code`, 'warning');
    }

  }, [deliveryData]); // eslint-disable-line react-hooks/exhaustive-deps

  // define a component that returns "null"
  // And pass it to <SaveButton> to override the "save" icon
  const NoIcon = () => null;

  return (
    <SaveButton
      {...rest}
      icon={<NoIcon />}
      label={loading ? "" : "Yes"}
      submitOnEnter={false}
      saving={loading}
      onSave={handleDelivery} 
    />
  );
};
