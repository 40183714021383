import React,{useState}  from 'react';
import clsx from 'clsx';
import { Datagrid, DateField, List, TextField } from 'react-admin';
import { Divider, Tabs, Tab } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { UserAgeField} from './UserAgeField';
import {FullNameField} from './CustomFields';
import {ListActions,FarmerGroupAssigmentButton } from 'layout';
import { userDatagridStyles } from 'modules/stylesheets';
import {apiCall } from '../../../modules';

import * as P from 'allPermissions';

const FarmersFilters = ({
  agentprograms,
  allsuperagents,
  allcoopBranches,
  selectProgram, 
  selectAgent,
  selectGroup
}) => {

  return (
    <form noValidate autoComplete="off" style={{ width: '100%', marginTop: '30px', display: 'flex', justifyContent: 'space-around',marginBottom: '-10px'  }}>
      <FormControl variant="outlined" style={{ float: 'left', width: '30%', marginLeft: '10px' }}>
        <InputLabel id="demo-simple-select-outlined-label">Programs</InputLabel>
        <Select style={{height: '40px' }}
          labelId="demo-simple-select-outlined-label"
          id="district-select-outlined"
          value={agentprograms.selected}
          onChange={selectProgram}
          label="Select Program"
        >
          <MenuItem value="0">
            All
          </MenuItem>
          {agentprograms.programs && agentprograms.programs.map((program, index) =>
            <MenuItem value={program.id}>
              {`${program.program_name}`}
            </MenuItem>
          )}
        </Select>
      </FormControl>
      <FormControl variant="outlined" style={{ float: 'left', width: '30%', marginLeft: '10px' }}>
        <InputLabel id="demo-simple-select-outlined-label">Super Agents</InputLabel>
        <Select style={{height: '40px' }}
          labelId="demo-simple-select-outlined-label"
          id="district-select-outlined"
          value={allsuperagents.selected}
          onChange={selectAgent}
          label="Select Agent"
        >
          <MenuItem value="0">
          Select Agent
          </MenuItem>
          {allsuperagents.superagents && allsuperagents.superagents.map((superagent, index) =>
            <MenuItem value={superagent.id}>
              {`${superagent.name}`}
            </MenuItem>
          )}
        </Select>
      </FormControl>
      <FormControl variant="outlined" style={{ float: 'left', width: '30%', marginLeft: '10px' }}>
            <InputLabel id="demo-simple-select-outlined-label">Agent Groups</InputLabel>
            <Select style={{ width: '100%',height: '40px' }}
            labelId="demo-simple-select-outlined-label"
            id="district-select-outlined"
            value={allcoopBranches.selected}
            onChange={selectGroup}
            label="Select Group"
            >
            <MenuItem value="0">
              Un-Assigned Farmers
            </MenuItem>
            {allcoopBranches.coopBranches && allcoopBranches.coopBranches.map((coopBranch, index) =>
                <MenuItem value={coopBranch.id}  key ={`${index}-new-group`}>
                {`${coopBranch.cooperative_branch_name}`}
                </MenuItem>
            )}
            </Select>
      </FormControl>
    </form>
  );
};

class TabbedDatagrid extends React.Component {
  /**
   * @Returns Users / farmers
   */
  tabs = [{ id: 'farmer', name: 'Farmers' }];

  state = {
    farmer: [], 
    new_users: [],
    active: [],
    inactive: [],
  };

  static getDerivedStateFromProps(props, state) {
    if (props.ids !== state[props.filterValues.status]) {
      return { ...state, [props.filterValues.status]: props.ids };
    }
    return null;
  };

  render() {
    const { 
      classes, 
      filterValues, 
      handleOpen,
      hasCreate,
      hasList, 
      hasEdit, 
      hasShow,
      total,
      syncWithLocation,
      handleData,
      onClick,
      allsuperagents,
      agentprograms,
      selectProgram,
      selectAgent,
      allcoopBranches,
      setCooperativeBranches,
      selectGroup,
      ...props
    } = this.props;

    const HideOnMobile = classes.hiddenOnSmallScreens;
    const CenterAndHideOnMobile = clsx(classes.hiddenOnSmallScreens, 'center');
    const Center = clsx('center');

    return (
      <>
       <FarmersFilters
        allsuperagents={allsuperagents}
        agentprograms={agentprograms}
        allcoopBranches = {allcoopBranches}
        selectProgram={selectProgram}
        selectAgent={selectAgent}
        selectGroup={selectGroup}
      />
        {!!total && total !== 0 &&
          <>
            <Tabs
              variant="fullWidth"
              centered
              value={filterValues.status}
              indicatorColor="primary"
              onChange={this.handleChange}
            >
              {this.tabs.map(choice => (
                <Tab key={choice.id} label={choice.name} value={choice.id} />
              ))}
            </Tabs>
            <Divider />
            <div className={clsx(
                'font-sm',
                'narrow-cell',
                'unassigned-farmers',
              )}
            >
             <Datagrid
                {...props}
                ids={props.ids}
                optimized
                classes={{ headerCell: classes.heading }}
                className={clsx('receiver')}
                >
                  <FullNameField source="first_name" label="Name"/>
                  <TextField
                    source="gender"
                    label="Gender"
                    cellClassName={CenterAndHideOnMobile}
                    headerClassName={CenterAndHideOnMobile}
                  />
                  <UserAgeField
                    cellClassName={HideOnMobile}
                    headerClassName={HideOnMobile}
                  />
                  <TextField 
                    source="username" 
                    label="Contact"
                    cellClassName={Center}
                    headerClassName={Center}
                  />
                  <TextField 
                    source="user_number" 
                    label="User ID"
                    cellClassName={CenterAndHideOnMobile}
                    headerClassName={CenterAndHideOnMobile}
                  />
                  <DateField 
                        source="date_added" 
                        label="Reg. date"
                        cellClassName={HideOnMobile}
                        headerClassName={HideOnMobile}
                  />  
                </Datagrid>
            </div>
          </>
        }
      </>
    );
  }
};

const StyledTabbedDatagrid = props => {
  const classes = userDatagridStyles();
  return <TabbedDatagrid classes={classes} {...props} />;
};

const GroupAssignmentList = props => {
  const {
    handleData,
    handleOpenDelete,
    hasCreate,
    hasEdit,
    permissions, 
    programid, 
    ...rest
  } = props;

  const superuser = P.superuser(permissions);
  const [agentprograms, setAgentPrograms] = useState({programs:[], selected:'0'});
  const [allsuperagents, setAllsuperagents] = useState({superagents:[], selected:'0'});
  const [allcoopBranches, setCooperativeBranches] = useState({coopBranches:[], selected:'0'});
  const token = sessionStorage.getItem('token');

  React.useEffect(() => {
  apiCall('', sessionStorage.getItem('token'), 'get', `programs/`)
      .then(programData => {
        const res = {programs: programData.results,selected:0}
        setAgentPrograms(res);
      })
  .catch(error => console.error('Error during fetch:', error));
  }, []) 

  const selectProgram = event => {
    const value = event.target.value;
    setAgentPrograms({ ...agentprograms, selected:value });
    setCooperativeBranches({ ...allcoopBranches, coopBranches:[]});
    setCooperativeBranches({ ...allcoopBranches, selected:0});
    setAllsuperagents({ ...allsuperagents, superagents:[] });
    setAllsuperagents({ ...allsuperagents, selected:0 });
    apiCall('', token, 'get', `programsuperagent/?program_id=${value}`)
      .then(superagentData => {
        setAllsuperagents({ ...allsuperagents, superagents: superagentData.results });
      })
    .catch(error => console.error('Error during fetch:', error));
  };

  const selectAgent = event => {
    const value = event.target.value;
    setAllsuperagents({ ...allsuperagents, selected:value });
    setCooperativeBranches({ ...allcoopBranches, coopBranches:[]});
    setCooperativeBranches({ ...allcoopBranches, selected:0});
    apiCall('', token, 'get', `cooperativebranches/?programid=${agentprograms.selected}&agentid=${value}&status=cooperative_branch_name`)
    .then(groups => {
      setCooperativeBranches({ ...allcoopBranches, coopBranches:groups.results });
    }).catch(error => console.error('Error during fetch:', error));
  };

  const selectGroup = event => {
    const value = event.target.value;
    setCooperativeBranches({ ...allcoopBranches, selected:value });
  };
  
  const bulkActionButtons = <FarmerGroupAssigmentButton />;

  const actionProps = {
    label: '',
    superuser: superuser,
    maxResults: 100000 
  };

  const moreProps = {
    hasCreate: false,
    hasEdit: false,
    allsuperagents:allsuperagents,
    agentprograms:agentprograms,
    allcoopBranches:allcoopBranches,
    selectProgram:selectProgram,
    selectAgent:selectAgent,
    setCooperativeBranches:setCooperativeBranches,
    selectGroup:selectGroup,
    ...rest
  };

  const ListOfFarmers = () => (
    <List
      {...moreProps}
      title="Group Assignment"
      filterDefaultValues={{programid:agentprograms.selected,agentid:allsuperagents.selected,groupid:allcoopBranches.selected}}
      actions={<ListActions {...actionProps} />}
      bulkActionButtons={bulkActionButtons}
      sort={{ field: 'id', order: 'DESC' }}
      perPage={15}
    >
      <StyledTabbedDatagrid
        onClick={handleOpenDelete}
        handleData={handleData}
        superuser={superuser}
        {...moreProps}
      />     
    </List>
  );

  return (
    <ListOfFarmers />
  ) 
};

export default GroupAssignmentList;
