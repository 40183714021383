import React from 'react';
import clsx from 'clsx';
import { Box, Button } from '@material-ui/core';

import { SuperuserDashboardContext } from '../../../context';
import { requestStyles } from '../../../modules';
import { FetchProgress } from '../../../layout';

import { moreStyles } from '../../../stylesheets/jss';

export * as Charts from './Charts';

const DelayedFarmerGraphs = React.lazy(() => import("./DelayedFarmerGraphs"));
const DelayedOtherGraphs = React.lazy(() => import("./DelayedOtherGraphs"));

export const LoadingGraphs = () => <FetchProgress title="Loading charts..." />;

const AllGraphs = ({ switching }) => {
  const classes = requestStyles();
  const classesBase = moreStyles();

  let isLoaded = React.useRef(true);

  const { loadingChart, isSuperuser } = React.useContext(SuperuserDashboardContext);

  const [show, setShow] = React.useState(false);

  const handleClick = () => setShow(!show);

  React.useEffect(() => {
    /* Cleanup useEffect */

    isLoaded.current = true; // set to true on mount...
    return function cleanup() { isLoaded.current = false; }

  }, [isLoaded, loadingChart, switching]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    switching
      ? <LoadingGraphs />
      : loadingChart
        ? <LoadingGraphs />
        : <Box>
            <div className={classes.statisticsRow}>
              <div className={classes.formOneColumn}>
                <div id="usertrends"></div>
              </div>
            </div>
            {show && 
              <React.Suspense fallback={<LoadingGraphs />}>
                <DelayedFarmerGraphs classes={classes} /> 
              </React.Suspense>}
            <div className={classes.statisticsRow}>
              <div className={clsx(
                  classes.space, 
                  { 
                    'two-thirds-column': isSuperuser, 
                    'full-column': !isSuperuser
                  })
              }>
                <div id="service_providers"></div>
              </div>
              {isSuperuser &&
                <div className={classes.formThreeColumn}>
                  <div id="farmer_targets"></div>
                </div>}
            </div>
            {show && 
              <React.Suspense fallback={<LoadingGraphs />}>
                <DelayedOtherGraphs classes={classes} /> 
              </React.Suspense>}
            {isSuperuser &&
              <Box display={{ xs: 'flex' }} justifyContent={{ xs: 'flex-end' }}>
                <Button
                  size="small"
                  onClick={handleClick}
                  className={clsx(classesBase.button, classesBase.farmerButton)}
                >
                  {show ? 'Hide charts' : 'More charts'}
                </Button>
              </Box>}  
          </Box>
  );
};

export default AllGraphs; // for lazy-loading