import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: -8,
    marginBottom: -8,
    justifyContent: 'center',
  },
  chip: { margin: 4 },
});

export const ProgFunderField = ({ record }) => {
  const classes = useStyles();
	const funders = record && record.program_funders && record.program_funders.length;

  if (!record) {
    return null;
  };

  return (
    <span className={classes.main}>
    {funders > 0 ? <Chip label={funders} className={classes.chip} /> : '--'}
    </span>
  );
};

ProgFunderField.defaultProps = {
  label: 'Funder(s)',
  textAlign: 'center',
};


export const ProgSaccoField = ({ record }) => {
  const classes = useStyles();
	const groups = record && record.program_saccos && record.program_saccos.length;

  if (!record) {
    return null;
  };

  return (
    <span className={classes.main}>
      {groups > 0 ? <Chip label={groups} className={classes.chip} /> : '--'}      
    </span>
  );
};

ProgSaccoField.defaultProps = {
  label: 'Group(s)',
  textAlign: 'center',
};


export const ProgParentField = ({ record }) => {
  const classes = useStyles();
	const parentShortName = record && record.parent_details && record.parent_details.parent_program_short_name;

  if (!record) {
    return null;
  };

  return (
    <span className={classes.main}>
      {!!parentShortName
        ? <Chip label={parentShortName} className={classes.chip} />
        : '--'
      }      
    </span>
  );
};

ProgParentField.defaultProps = {
  label: 'Parent',
  textAlign: 'center',
};


export const FunderShortNameField = ({ record }) => {
  const classes = useStyles();
	const shortName = record && record.short_name;

  if (!record) {
    return null;
  };

  return (
    <span className={classes.main}>
      {shortName ? <Chip label={shortName} className={classes.chip} /> : '--'}      
    </span>
  );
};

FunderShortNameField.defaultProps = {
  label: 'Short name',
  textAlign: 'center',
};


export const FunderAllProgramsField = ({ record }) => {
  const classes = useStyles();
	const active = record && record.active_programs && record.active_programs.length;
	const inactive = record && record.inactive_programs && record.inactive_programs.length;
  const total = active + inactive;

  if (!record) {
    return null;
  };

  return (
    <span className={classes.main}>
      {total ? <Chip label={total} className={classes.chip} /> : '--'}      
    </span>
  );
};

FunderAllProgramsField.defaultProps = {
  label: 'Total programs',
  textAlign: 'center',
};


export const FunderActiveProgramsField = ({ record }) => {
  const classes = useStyles();
	const active = record && record.active_programs && record.active_programs.length;

  if (!record) {
    return null;
  };

  return (
    <span className={classes.main}>
      {active ? <Chip label={active} className={classes.chip} /> : '--'}      
    </span>
  );
};

FunderActiveProgramsField.defaultProps = {
  label: 'Active programs',
  textAlign: 'center',
};


export const FunderInactiveProgramsField = ({ record }) => {
  const classes = useStyles();
	const inactive = record && record.inactive_programs && record.inactive_programs.length;

  if (!record) {
    return null;
  };

  return (
    <span className={classes.main}>
      {inactive ? <Chip label={inactive} className={classes.chip} /> : '--'}      
    </span>
  );
};

FunderInactiveProgramsField.defaultProps = {
  label: 'Inactive programs',
  textAlign: 'center',
};