import ProgramIcon from '@material-ui/icons/PhonelinkSetupOutlined';

import SettingList from '../settingscrud/SettingList';
import SettingCreate from '../settingscrud/SettingCreate';
import SettingEdit from '../settingscrud/SettingEdit';

const programs = {
  list: SettingList,
  create: SettingCreate,
  edit: SettingEdit,
  icon: ProgramIcon,
};

export default programs;
