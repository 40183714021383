import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Avatar, Box, useMediaQuery, Theme, Typography } from '@material-ui/core';
import { DashboardMenuItem, MenuItemLink, usePermissions, useTranslate } from 'react-admin';
/* start - icons */
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import GeneralUsers from '@material-ui/icons/PeopleAltOutlined';
import ReportsIcon from '@material-ui/icons/AssignmentOutlined';
import EcommerceIcon from '@material-ui/icons/NextWeekOutlined';
import SmsUserIcon from '@material-ui/icons/MailOutlineTwoTone';
import AccountBalance from '@material-ui/icons/AccountBalance';
import ProgramsIcon from '@material-ui/icons/SettingsTwoTone';
import OUTPUTMarketsIcon from '@material-ui/icons/Storefront';
import SmsGroupIcon from '@material-ui/icons/EmailRounded';
import RequestIcon from '@material-ui/icons/AttachMoney';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import SmsIcon from '@material-ui/icons/EmailTwoTone';
import PosIcon from '@material-ui/icons/ShoppingCart';
import BackupIcon from '@material-ui/icons/Backup';
import LoanIcon from '@material-ui/icons/BarChart';
import FaceIcon from '@material-ui/icons/Face';
import PredictionsList from 'modules/prediction/predictions';

/* end - icons */
import { loans, requests, settings, sms, pos, providers, reports, users } from 'modules';

import { sideMenuStyles } from 'stylesheets';

import { SubMenu } from './SubMenu';
import { AppState } from 'types';

import * as P from 'allPermissions';

type MenuName = 'menuRequests' | 'menuLoans' | 'menuUsers' | 'menuSms' | 'menuEcommerce' | 'menuReports' | 'menuPrograms' | 'menuUploads';

interface Props {
  dense: boolean;
  logout: () => void;
  onMenuClick: () => void;
}

export const Menu: React.FC<Props> = ({ onMenuClick, dense, logout }) => {
  const classes = sideMenuStyles();
  const history = useHistory();

  const [state, setState] = React.useState({
    menuRequests: false,
    menuLoans: false,
    menuUsers: false,
    menuSms: false,
    menuEcommerce: false,
    menuReports: false,
    menuPrograms: false,
    menuUploads: false
  });

  const translate = useTranslate();
  const { permissions } = usePermissions();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  let providerId = sessionStorage.getItem('providerId')!;
  let firstName = sessionStorage.getItem('firstName');
  let lastName = sessionStorage.getItem('lastName');
  let userId = sessionStorage.getItem('id')!;
  const programid = sessionStorage.getItem('userProgramId');

  const canSendSms = (userId === '41043' || userId === '40991') ? true : false;
  const accessSMSMenu = P.superuser(permissions) || (P.superagent(permissions) && canSendSms) || P.accessProviderMenu(permissions);

  let initials = firstName && lastName ? `${firstName[0]}${lastName[0]}` : null;

  let providerIcon = P.superuser(permissions) ? <providers.serviceproviders.icon /> : <BusinessCenterOutlinedIcon />;

  const isOyamDFAManager = (userId === '87459') ? true : false;

  const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
  useSelector((state: AppState) => state.theme); // force rerender on theme change

  const handleToggle = (menu: MenuName) => {
    setState(state => ({ ...state, [menu]: !state[menu] }));
  };

  if (!permissions) { // if no permissions
    return null;
  };

  return (
    <div className="mt-80">
      {isSmall && (
        <>
          <Avatar className={classes.root}>{initials}</Avatar>
          <Typography component="div" gutterBottom>
            <Box fontSize="1.25rem" ml='22px'>
              {firstName}&nbsp;{lastName}
            </Box><hr />
          </Typography>
        </>
      )}
      <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
      <div className="general-sidemenu">
        {/* Requests */}
        {P.accessRequestsMenu(permissions) &&
          <SubMenu
            handleToggle={() => handleToggle('menuRequests')}
            isOpen={state.menuRequests}
            sidebarIsOpen={open}
            name="pos.menu.requests.name"
            icon={<RequestIcon />}
            dense={dense}
          >
            {P.admin(permissions) &&
              <MenuItemLink
                to={`/credit-orders`}
                primaryText={translate(`pos.menu.requests.sacco.name`, { smart_count: 2 })}
                leftIcon={<requests.creditorders.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            }
            {P.authAggregatedReq(permissions) &&
              <MenuItemLink
                to={`/aggregated-orders`}
                primaryText={translate(`pos.menu.requests.sacco.aggregated`, { smart_count: 2 })}
                leftIcon={<requests.aggregatedorders.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            }
            {P.provider(permissions) &&
              <MenuItemLink
                to={`/cash-orders`}
                primaryText={translate(`pos.menu.requests.provider.name`, { smart_count: 2 })}
                leftIcon={<requests.cashorders.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            }
            {P.superagent(permissions) &&
              <MenuItemLink
                to={`/cordinated-orders`}
                primaryText={translate(`pos.menu.requests.superagent.name`, { smart_count: 2 })}
                leftIcon={<requests.cordinatedorders.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            }
          </SubMenu>}

        {/* Users */}
        {P.accessUserMenu(permissions) &&
          /**
           * - Cordinators, 
           * - Superagents,
           * - Accountants,
           * - Agents, 
           * - Farmers, 
           * - Service providers 
           */
          <SubMenu
            handleToggle={() => handleToggle('menuUsers')}
            isOpen={state.menuUsers}
            sidebarIsOpen={open}
            name="pos.menu.users.name"
            icon={<GeneralUsers />}
            dense={dense}
          >
            {/* {P.accessCoordinatorMenu(permissions) &&
              <MenuItemLink
                to={`/coordinators`}
                primaryText={translate(`pos.menu.users.coordinators`, { smart_count: 2 })}
                leftIcon={<users.coordinators.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            } */}
            {P.accessSuperagentMenu(permissions) &&
              <MenuItemLink
                to={`/superagents`}
                primaryText={translate(`pos.menu.users.superagents`, { smart_count: 2 })}
                leftIcon={<providers.superagents.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            }
            {/* {P.accessAccountantsMenu(permissions) &&
              <MenuItemLink
                to={`/accountants`}
                primaryText={translate(`pos.menu.users.accountants`, { smart_count: 2 })}
                leftIcon={<users.accountants.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            } */}
            {P.accessAgentMenu(permissions) &&
              <MenuItemLink
                to={`/agents`}
                primaryText={translate(`pos.menu.users.agents`, { smart_count: 2 })}
                leftIcon={<users.agents.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            }
            {P.accessFarmerMenu(permissions) &&
              <MenuItemLink
                to={`/users`}
                primaryText={translate(`pos.menu.users.farmers`, { smart_count: 2 })}
                leftIcon={<users.farmers.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            }
            {P.accessProviderMenu(permissions) &&
              <MenuItemLink
                to={`/provider`}
                primaryText={translate(`pos.menu.users.providers`, { smart_count: 2 })}
                leftIcon={<providers.serviceproviders.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            }
            {P.accessSaccoMembersMenu(permissions) &&
              <MenuItemLink
                to={`/members`}
                primaryText={translate(`pos.menu.users.saccousers`, { smart_count: 2 })}
                leftIcon={<users.farmers.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            }
          </SubMenu>}

        {/* Groups */}
        {P.accessGroupMenu(permissions) &&
          <MenuItemLink
            to={`/groups`}
            primaryText={translate(`pos.menu.users.groups`, { smart_count: 2 })}
            leftIcon={<GroupAddIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />}

        {/* PoS */}
        {/* {P.accessPosMenu(permissions) &&
          <>
            <a
              href="https://pos.akellobanker.com/"
              className={clsx(`MuiButtonBase-root MuiListItem-root MuiListItem-gutters`)}
            >
              <div className={clsx(`MuiListItemIcon-root AkbMenuItemLink`)}><PosIcon/></div>
              Point of Sales
            </a>
            <a
              href="https://markets.akellobanker.com/"
              className={clsx(`MuiButtonBase-root MuiListItem-root MuiListItem-gutters`)}
            >
              <div className={clsx(`MuiListItemIcon-root AkbMenuItemLink`)}><OUTPUTMarketsIcon/></div>
              Markets
            </a>
          </>} */}

        {/* Loans */}
        {/* {P.accessLoansMenu(permissions) &&
          <SubMenu
            handleToggle={() => handleToggle('menuLoans')}
            isOpen={state.menuLoans}
            sidebarIsOpen={open}
            name="pos.menu.loans.name"
            icon={<LoanIcon />}
            dense={dense}
          >
            <MenuItemLink
              to={`/loan-applications`}
              primaryText={translate(`pos.menu.loans.apps`, { smart_count: 2 })}
              leftIcon={<loans.loanpapps.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/loan-payments`}
              primaryText={translate(`pos.menu.loans.pays`, { smart_count: 2 })}
              leftIcon={<loans.loanpays.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            {!P.admin(permissions) &&
              <MenuItemLink
                to={`/loan-products`}
                primaryText={translate(`pos.menu.loans.products`, { smart_count: 2 })}
                leftIcon={<loans.loanproducts.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />}
          </SubMenu>} */}

        {/* Stock */}
        {(P.accessStockMenu(permissions) && programid === '6') &&
          <SubMenu
            handleToggle={() => handleToggle('menuEcommerce')}
            isOpen={state.menuEcommerce}
            sidebarIsOpen={open}
            name="pos.menu.ecommerce.name"
            icon={<EcommerceIcon />}
            dense={dense}
          >
            <MenuItemLink
              to={`/inventory`}
              primaryText={translate(`pos.menu.ecommerce.inventory`, { smart_count: 2 })}
              leftIcon={<pos.inventory.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/sales`}
              primaryText={translate(`pos.menu.ecommerce.sales`, { smart_count: 2 })}
              leftIcon={<pos.sales.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          </SubMenu>}

        {/* Reports */}
        {P.accessReportsMenu(permissions) &&
          <SubMenu
            handleToggle={() => handleToggle('menuReports')}
            isOpen={state.menuReports}
            sidebarIsOpen={open}
            name="pos.menu.reports.name"
            icon={<ReportsIcon />}
            dense={dense}
          >
            <MenuItemLink
              to={`/farmersreport`}
              primaryText={translate(`pos.menu.reports.farmers`, { smart_count: 2 })}
              leftIcon={<users.farmers.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/providersreport`}
              primaryText={translate(`pos.menu.reports.providers`, { smart_count: 2 })}
              leftIcon={<providers.serviceproviders.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/cooperativesreport`}
              primaryText={translate(`pos.menu.reports.cooperatives`, { smart_count: 2 })}
              leftIcon={<GroupAddIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/transactionsreport`}
              primaryText={translate(`pos.menu.reports.transactions`, { smart_count: 2 })}
              leftIcon={<reports.transactions.cards.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            {(P.superuser(permissions) && !isOyamDFAManager) &&
              <MenuItemLink
                to={`/systemusagereport`}
                primaryText={translate(`pos.menu.reports.systemusagereport`, { smart_count: 2 })}
                leftIcon={<reports.transactions.cards.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            }
            {(P.superuser(permissions) && !isOyamDFAManager) &&
              <MenuItemLink
                to={`/digitallinkfarmers`}
                primaryText={translate(`pos.menu.reports.digitallinkfarmers`, { smart_count: 2 })}
                leftIcon={<reports.transactions.cards.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            }
          </SubMenu>}

        {/* SMS */}
        {accessSMSMenu &&
          <SubMenu
            handleToggle={() => handleToggle('menuSms')}
            isOpen={state.menuSms}
            sidebarIsOpen={open}
            name="pos.menu.sms.name"
            icon={<SmsIcon />}
            dense={dense}
          >
            <MenuItemLink
              to={`/users#sms`}
              primaryText={translate(`pos.menu.sms.users`, { smart_count: 2 })}
              leftIcon={<SmsUserIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/groups#sms`}
              primaryText={translate(`pos.menu.sms.groups`, { smart_count: 2 })}
              leftIcon={<SmsGroupIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            {/* {P.superuser(permissions) &&
              <MenuItemLink
                to={`/programs#sms`}
                primaryText={translate(`pos.menu.sms.programs`, { smart_count: 2 })}
                leftIcon={<SmsUserIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            } */}
            <MenuItemLink
              to={`/sms`}
              primaryText={translate(`pos.menu.sms.report`, { smart_count: 2 })}
              leftIcon={<sms.report.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          </SubMenu>}

        {/* Transactions */}
        {(P.superuser(permissions) && !isOyamDFAManager) &&
          <SubMenu
            handleToggle={() => handleToggle('menuUploads')}
            isOpen={state.menuUploads}
            sidebarIsOpen={open}
            name="pos.menu.uploads.name"
            icon={<AccountBalance />}
            dense={dense}
          >
            <MenuItemLink
              to={`/bulkuploads`}
              primaryText={translate(`pos.menu.uploads.transactions`, { smart_count: 2 })}
              leftIcon={<BackupIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />

            <MenuItemLink
              to={`/farmertool`}
              primaryText={translate(`pos.menu.uploads.users`, { smart_count: 2 })}
              leftIcon={<BackupIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/grouptool`}
              primaryText={translate(`pos.menu.uploads.groups`, { smart_count: 2 })}
              leftIcon={<BackupIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/groupassignments`}
              primaryText={translate(`pos.menu.uploads.groupasignment`, { smart_count: 2 })}
              leftIcon={<BackupIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          </SubMenu>}

        {/* Programs */}
        {(P.superuser(permissions) && !isOyamDFAManager) &&
          <SubMenu
            handleToggle={() => handleToggle('menuPrograms')}
            isOpen={state.menuPrograms}
            sidebarIsOpen={open}
            name="pos.menu.programs.name"
            icon={<ProgramsIcon />}
            dense={dense}
          >
            <MenuItemLink
              to={`/funders`}
              primaryText={translate(`pos.menu.programs.managefunders`, { smart_count: 2 })}
              leftIcon={<settings.funders.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/programs`}
              primaryText={translate(`pos.menu.programs.manageprograms`, { smart_count: 2 })}
              leftIcon={<settings.programs.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          </SubMenu>
        }

        <MenuItemLink
          to={`#disease-diagnosis`}
          primaryText="Disease Diagnosis"
          leftIcon={<LockOpenIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
          className={clsx({
            "activated": history.location.hash === `#disease-diagnosis`
          })}
        />

        {P.accessMyDetailsMenu(permissions) &&
          <MenuItemLink
            to={`/provider/${providerId}`}
            primaryText={`My details`}
            leftIcon={providerIcon}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />}

      </div>

      <div className="my-profile-sidemenu">
        <MenuItemLink
          to={`#my-wallet`}
          primaryText="Wallet"
          leftIcon={<AccountBalanceWalletIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
          className={clsx({
            "activated": history.location.hash === `#my-wallet`
          })}
        />
        <MenuItemLink
          to={`#my-profile`}
          primaryText="Profile"
          leftIcon={<FaceIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
          className={clsx({
            "activated": history.location.hash === `#my-profile`
          })}
        />
        <MenuItemLink
          to={`#my-profile-password`}
          primaryText="Manage password"
          leftIcon={<LockOpenIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
          className={clsx({
            "activated": history.location.hash === `#my-profile-password`
          })}
        />

        {(history.location.hash === "#disease-diagnosis") && <div>
          <PredictionsList isOpen={(history.location.hash === "#disease-diagnosis")} />
        </div>
        }

      </div>

      {isSmall && logout}
    </div>
  )
};
