import React from 'react';
import clsx from 'clsx';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';

import { ApproveButton } from '../../../layout';

const CashOrderEdit = (props) => {

  const {
    isXSmall,
    requestStatus,
    handleDeliverDialog,
    handleOpenAcceptDialog,
    handleOpenRejectDialog,
    ...rest // eslint-disable-line
  } = props;

  const buttonProps = {
    size: 'medium',
    isXSmall: isXSmall,
    variant: 'contained',
    color: isXSmall ? 'secondary' : 'primary',
  };

  return requestStatus === "approved"
    /* if CASH request is "pending" */
    ? <>
        <ApproveButton
          // to reject
          label="Reject"
          icon={<CancelOutlinedIcon />}
          handleClick={handleOpenRejectDialog}
          className={clsx('reject small--btn')} // "small--btn" -> for mobile styling
          {...buttonProps}
        />
        <ApproveButton
          // to accept
          label="Accept"
          icon={<CheckCircleOutlinedIcon />}
          handleClick={handleOpenAcceptDialog}
          className={clsx('confirm small--btn')} // "small--btn" -> for mobile styling        
          {...buttonProps}
        />
      </>
    /* if CASH request is "accepted" */
    : requestStatus === "accepted"
      ? <ApproveButton
          // for delivery
          label="Confirm delivery"
          icon={<CheckCircleOutlinedIcon />}
          handleClick={handleDeliverDialog}
          className={clsx('confirm small--btn')} // "small--btn" -> for mobile styling
          {...buttonProps}
        />
      : null;
};

export default CashOrderEdit;
