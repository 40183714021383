import React from 'react';
// import clsx from 'clsx';
import { SelectInput, TextInput } from 'react-admin';

import { TrueOrFalse, Precise } from 'modules/validation';
import { SectionTitle } from 'modules/utils';
import { apiFullCall } from 'modules/apiHelper';

interface SelectObject {
  id?: string;
  name?: string;
}

export const EnterpriseFields = props => {
  const { classes, token } = props;

  // Input "category"
  const [inputCategory, setInputCategory] = React.useState<object>({ id: '' });
  // Seed "main-subcategory"
  const [mainSubcategory, setMainSubcategory] = React.useState<object>({ id: '' });
  // Crops "list"
  const [cropList, setCropList] = React.useState<Array<SelectObject>>([]);
  const [selectedCrop, setSelectedCrop] = React.useState<object>({ crop_id: '' });
  // Variety "list"
  const [varietyList, setVarietyList] = React.useState<Array<SelectObject>>([]);

  let isLoaded = React.useRef<boolean>(true);

  // fetch "input" id
  React.useEffect(() => {

    if (isLoaded) {

      // fetch "inputs" id
      apiFullCall(
        '',
        token,
        'get',
        `categories/`
      ).then(res => {
        if (res) {
          const { status, body } = res;
          if (status === 200 || status === 201) {
            const { results } = body;

            if (results.length > 0) {
              let data = results.filter(
                ({ id, category_name }) => {
                  return (category_name === 'Inputs' || category_name === 'inputs') && 
                    ({ id: id, name: category_name })
                }
              );

              // populate "id" for "input" category
              setInputCategory({ ...inputCategory, id: data[0]['id'] });
            }
          }
        }
      }).catch(
        error => console.error('Error while fetching "Inputs":', error)
      );

    };

    // clean up API call, on unmount
    return function cleanup() {
      isLoaded.current = false;
    };

  }, [isLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  // fetch "seed" id
  React.useEffect(() => {

    if (isLoaded && inputCategory['id']) {

      apiFullCall(
        '',
        token,
        'get',
        `subcategories/?categoryid=${inputCategory['id']}&is_farmer=true`
      ).then(res => {
        if (res) {
          const { status, body } = res;
          if (status === 200 || status === 201) {
            const { results } = body;

            if (results.length > 0) {
              let data = results.filter(
                ({ id, sub_category_name }) => {
                  return (sub_category_name === 'Seed' || sub_category_name === 'seed' || sub_category_name === 'seeds') && 
                    ({ id: id, name: sub_category_name })
                }
              );

              // populate "selection" for main sub-category
              setMainSubcategory({ ...mainSubcategory, id: data[0]['id']});
            }
          }
        }
      }).catch(
        error => console.error('Error while fetching "Seed":', error)
      );

    };

    // clean up API call, on unmount
    return function cleanup() {
      isLoaded.current = false;
    };

  }, [isLoaded, inputCategory]); // eslint-disable-line react-hooks/exhaustive-deps

  // fetch "crops" list
  React.useEffect(() => {

    if (mainSubcategory['id']) {

      apiFullCall(
        '',
        token,
        'get',
        `subcategories/?parent_id=${mainSubcategory['id']}`
      ).then(res => {
        if (res) {
          const { status, body } = res;
          if (status === 200 || status === 201) {
            const { results } = body;

            if (results.length > 0) {
              let data = results.map(
                ({ id, sub_category_name }) => ({ id: id, name: sub_category_name })
              );

              // populate "crop" list
              setCropList(data);

            };

          }
        }
      }).catch(
        error => console.error('Error while fetching "crops":', error)
      );

    };

    // clean up API call, on unmount
    return function cleanup() {
      isLoaded.current = false;
    };

  }, [isLoaded, mainSubcategory]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {

    if (isLoaded && !!selectedCrop['crop_id']) {
  
      apiFullCall(
        '',
        token,
        'get',
        `services/?subcategoryid=${selectedCrop['crop_id']}`
      ).then(res => {

        if (res) {
          const { status, body } = res;

          if (status === 200 || status === 201) {

            const { results } = body;

            if (results.length > 0) {
              let data = results.map(                
                ({ id, service_name }) => ({ id: id, name: service_name })
              );

              // populate the services list
              setVarietyList(data);
            };

          };

        };

      }).catch(
        error => console.error('Error while fetching "varieties":', error)
      );

    };

    // clean up API call, on unmount
    return function cleanup() {
      isLoaded.current = false;
    };

  }, [isLoaded, selectedCrop['crop_id']]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCropChange = e => {
    setSelectedCrop({ [e.target.name]: e.target.value });
  };

  return (
    <>
      <SectionTitle label="resources.users.fieldGroups.enterprise" className='AkRegisterForm-title' />
      
      <TextInput
        label="How many years a farmer?"
        source="years_as_farmer"
        className={classes.input}
        validate={Precise}
      />
      <TextInput
        label="Total acres (owned)"
        source="acreage"
        className={classes.input}
        validate={Precise}
      /><br/>
      <SelectInput
        label="Crop"
        source="crop_id"
        optionText="name"
        choices={cropList}
        onChange={handleCropChange}
        className={classes.input}
      />
      {!selectedCrop['crop_id']
        ? <SelectInput
            allowEmpty
            label="Variety"
            source="variety"
            choices={[]}
            className={classes.input}
            helperText="First, select a 'crop'"
          />
        : <SelectInput
            label="Variety"
            source="crop_variety_id"
            optionText="name"
            choices={varietyList}
            className={classes.input}
          />}
      <SelectInput
        label="First time to grow crop?"
        source="first_time_crop_grown"
        choices={TrueOrFalse}
        className={classes.input}
      />
    </>
  );
}; 
