import React from 'react';
import clsx from 'clsx';
import { Box } from '@material-ui/core';

import { Stats } from './Stats';

import { SuperuserDashboardContext } from 'context';

import * as P from 'allPermissions';

const DevPartnerDashboard = props => {
  const {
    isSmall,
    isXSmall,
    anyUpdates,
    sidebarOpen,
    permissions,
    unapprovedProviderRequests,
    ...rest // eslint-disable-line
  } = props;

  const districtObject = { districtid: '0', districtname: '' };
  const [selectedDistrict, setSelectedDistrict] = React.useState(districtObject);
  const { districtid: activeDistrict } = selectedDistrict;

  const isSuperuser = P.superuser(permissions);
  const isSuperagent = P.superagent(permissions);
  const superagentId = sessionStorage.getItem('id');
  const [selectedAgent, setSelectedAgent] = React.useState('0');
  const [loading, setLoading] = React.useState(false); //eslint-disable-line

  const totalsObject = { agents: 0, farmers: 0, providers: 0, transactions: 0, transactions_amount: 0 };
  const [totals, setTotals] = React.useState(totalsObject); //eslint-disable-line

  /* To optimize performance, we target the first graph - with farmer trends */
  const [loadingChart, setLoadingChart] = React.useState(false);

  return (
    <SuperuserDashboardContext.Provider value={{
        totals: totals,
        loading: loading,
        isSmall: isSmall,
        isXSmall: isXSmall,
        sidebarOpen: sidebarOpen,
        isSuperuser: isSuperuser,
        isSuperagent: isSuperagent,
        superagentId: superagentId,
        loadingChart: loadingChart,
        selectedAgent: selectedAgent,
        activeDistrict: activeDistrict,
        setLoadingChart: setLoadingChart,
        setSelectedAgent: setSelectedAgent,
        setSelectedDistrict: setSelectedDistrict,
        ...props
      }}
    >
      <Box width={{ xs: '100%' }} className={clsx({ 'with-closed-sidebar': !sidebarOpen })}>
        <Stats                        /* Main dashboard cards */ />
      </Box>
    </SuperuserDashboardContext.Provider>
  );
};

export default DevPartnerDashboard;
