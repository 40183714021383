import englishMessages from 'ra-language-english';

const en = {
  ...englishMessages,
  login: {
    auth: {
      phone: 'Username',
      password: 'Password',
    },
  },
  pos: {
    search: 'Search',
    configuration: 'Configuration',
    language: 'Language',
    theme: {
      name: 'Theme',
      light: 'Light',
      dark: 'Dark',
    },
    dashboard: {
      monthly_revenue: 'MONTHLY REVENUE',
      total_requests: 'TOTAL REQUESTS',
      pending_approval: 'Requests that need approval',
      services_offered: 'Services Offered',
      new_members: 'NEW MEMBERS',
      all_members: 'ALL MEMBERS',
      order: {
        items: '%{service_name}, qty - %{nb_items} by %{customer_name}',
        requestNo: 'No. %{request_number} on %{request_date}',
      },
      pending_provider_requests: {
        notice:
          '%{smart_count} provider service, needs approval |||| %{smart_count} provider services, need approval',
      },
      newSaccoUsers: {
        notice:
          'you have %{smart_count}  new sacco users |||| you have %{smart_count} new sacco users',
      },
      provider: {
        title: 'Hello,',
        subtitle:
          // 'This is your admin center as a Service Provider. Feel free to add or update the services that you offer, and check on any requests that need your response.',
          "We are working together to help increase farm production and productivity, as you boost your income too!",
        aor_button: 'Requests',
        demo_button: 'My Services',
      },
      agent: {
        title: 'Hello,',
        cta: "Sign-up or Register",
        subtitle:
          "We're supporting local farmers to increase their farm production and productivity. Click a button to sign-up (register) a farmer or service provider.",
        farmer_button: 'farmer',
        provider_button: 'Service provider',
      },
      accountant: {
        title: 'Welcome,',
        subtitle:
          "We're supporting local farmers to increase their farm production and productivity. Click a below to manage your loan applications, products or payments.",
        loan_app_button: 'Loan Applications',
        loan_product_button: 'Loan Products',
        loan_pay_button: 'Loan Payments',
      },
      superuser: {
        title: 'Welcome,',
        subtitle:
          'This is your admin center as a Superuser.',
        aor_button: 'Requests',
        demo_button: 'My Services',
      },
      welcome: {
        title: 'Welcome to AkelloBanker',
        subtitle:
          'This is your admin center as a Service Provider. Feel free to add or update the services that you offer, and check on any requests that need your response.',
        aor_button: 'Requests',
        demo_button: 'My Services',
      },
      farmers: {
        count: 'FARMER |||| FARMERS',
      },
      providers: {
        count: 'SERVICE PROVIDER |||| SERVICE PROVIDERS',
      },
      users: {
        count: 'APP USER |||| APP USERS',
      },
    },
    menu: {
      requests: {
        name: 'Requests',
        provider: {
          name: 'Cash',
        },
        superagent: {
          name: 'Cordinated',
        },
        sacco: {
          name: 'Credit',
          aggregated: 'Aggregated',
        },
      },
      providers: 'Service Provider |||| Service Providers',
      users: {
        name: 'Users',
        accountants: 'Accountant |||| Accountants',
        agents: 'Agent |||| Agents',
        farmers: 'Farmer |||| Farmers',
        providers: 'Service Provider |||| Service Providers',
        coordinators: 'Coordinator |||| Coordinators',
        saccousers: 'Member |||| Members',
        superagents: 'Super Agent |||| Super Agents',
        groups: 'Group |||| Groups',
      },
      loans: {
        name: 'Loans',
        apps: 'Application |||| Applications',
        pays: 'Payments',
        products: 'Products',
      },
      ecommerce: {
        name: 'eCommerce',
        inventory: 'Inventory',
        sales: 'Sales',
      },
      reports: {
        name: 'Reports',
        providers: 'Service Provider |||| Service Providers',
        farmers: 'Farmers |||| Farmers',
        cooperatives: 'Cooperatives |||| Cooperatives',
        transactions: 'Transactions |||| Transactions',
        systemusagereport:'RMF |||| RMF',
        digitallinkfarmers:'Digital Link Farmers |||| Digital Link Farmers',
      },
      sms: {
        name: 'Digital Agronomy',
        users: 'User |||| Users',
        groups: 'Group |||| Groups',
        programs: 'Program |||| Programs',
        report: 'Report',
      },
      uploads: {
        name: 'Uploads',
        transactions: 'Transaction |||| Transactions',
        users: 'Farmer |||| Farmers',
        groups: 'Group |||| Groups',
        groupasignment: 'Group Assignment |||| Group Assignments',
      },
      programs: {
        name: 'Settings',
        managefunders: 'Funders',
        manageprograms: 'Programs',
      },
      dashboard: {
        name: 'Dashboard Settings'
      },
    },
  },
  post: {
    action: {
      save: 'save',
      save_and_continue: 'Save and Continue',
      save_and_exit: 'Save and Exit',
      submit: 'submit',
    },
    notification: {
      created_user: 'User has been created',
      updated_user: 'User details have been updated',
      include_address: 'Please complete the user\'s address',
      location_add: "User's location has been added",
      location_update: "User's location has been updated",
      provider_is_approved: 'Submitted for approval. Thank you.',
      provider_is_created: 'Service Provider has been registered.',
      provider_is_updated: 'Service Provider details have been updated.',
      provider_also_updated: 'And Service Provider details have been updated.',
      branch_user_is_updated: 'Branch user details have been updated.',
      branch_is_updated: 'Branch details have been updated.',
      provider_location_updated: 'Provider location has been updated.',
    },
  },
  ra: {
    ...englishMessages.ra,          // avoid overriding other "ra" fields
    action: {
      ...englishMessages.ra.action, // avoid overriding other "ra.action" fields
      add_filter: 'Search',         // rename the "Add filter" button for lists
    },   
  },
  resources: {
    users: {
      name: 'Farmers',
      fields: {
        name: 'Name',
      },
      fieldGroups: {
        identity: 'Basic details',
        enterprise: 'Enterprise details',
        home_address: 'Your address',
        home_district: "Your district",
        farm_address: "Location of farm",
        other_info: 'Other',
        relations: 'Relations',
        crops: 'Crops',
        animals: 'Animals',
        services: 'Offered Services',
        question: 'I operate as',
        qualified: 'Certification',
        more: 'Other Requirements',
      },
      errors: {
        username: 'Please add a Valid Phone number',
      },
    },
    provider: {
      name: 'Service Provider |||| Service Providers',
      fields: {
        create_title: 'Service Provider Registration',
        edit_title: 'Service Provider Update',
      },
      fieldGroups: {
        create: 'Please complete the form',
        edit: 'Please update the relevant fields',
      },
    },
    programs: {
      fields: {
        create_title: 'Program Registration',
        edit_title: 'Program Update',
      },
    },
    funders: {
      fields: {
        create_title: 'Funder Registration',
        edit_title: 'Funder Update',
      },
    },
    cooperatives: {
      name: 'Cooperative |||| Sub Clusters',
      identityCreated: 'Create',
      identityEdit: 'Update',
      fields: {
        id: 'Cooperative',
        cooperatives: 'View List',
        added_by: 'Created By',
        date_added: 'Date Created',
        cooperative_name: 'Cooperative name',
        is_active: 'true',
        short_name: 'Short Name',
        coop_service_id: 'Cooperative Service',
        create_title: 'Group Registration',
        edit_title: 'Group Update',
      },
    },
    loans: {
      fieldGroups: {
        form: 'Application details'
      }
    }
  },
  errors: {
    password_mismatch: 'Passwords don\'t match',
    password_not_changed: 'Password can\'t be same as old',
  },
};

export default en;