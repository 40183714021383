import FundersIcon from '@material-ui/icons/PermDataSettingTwoTone';

import SettingList from '../settingscrud/SettingList';
import SettingCreate from '../settingscrud/SettingCreate';
import SettingEdit from '../settingscrud/SettingEdit';

const funders = {
  list: SettingList,
  create: SettingCreate,
  edit: SettingEdit,
  icon: FundersIcon,
};

export default funders;
