import React from 'react';
import * as P from '../../../allPermissions';

const UserTransactionsList = React.lazy(() => import("./UserTransactionsList"));
const SuperTransactionsList = React.lazy(() => import("./SuperTransactionsList"));

const UserWallet = props => {
  const { onlyEditFarmers, ...rest} = props;
  const { permissions } = rest;
  const superuser = P.superuser(permissions);

  return superuser ? <SuperTransactionsList {...rest} /> : <UserTransactionsList {...rest} />;
};

export default UserWallet;
