import React from 'react';
import clsx from 'clsx';
import { Edit } from 'react-admin';
import { useSelector } from 'react-redux';
import { Dialog, Slide } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions'

import { UserWallet } from '../_wallet';
import { FullNameField } from '../userfields';
import { AppState, User, FieldProps } from 'types';
import { FetchProgress } from 'layout';

const UserFormBasic = React.lazy(() => import("../userforms/UserFormBasic"));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const UserProfile = props => {
  const [open, setOpen] = React.useState(true);

  const openSidebar = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
  useSelector((state: AppState) => state.theme); // force rerender on theme change

  const { history } = props;

  /* hold the "prevPath" state */
  const referrerPath = React.useRef(
    history && history.location && history.location.state && history.location.state.prevPath
  );

  const handleClose = React.useCallback(() => {
    setOpen(!open);

    if (!!referrerPath.current) {
      /* if "prevPath" is defined, then user clicked "my-profile" link */
      history.push(referrerPath.current);
    } else {
      /* Otherwise, user could have refreshed while here, so on close... goto dashboard */
      history.push('/');
    }

  }, [history, open]);

  return (
    <Dialog
      fullScreen
      open={open} 
      onClose={handleClose}
      className={clsx("my-profile", "full-width", { "expand" : openSidebar } )}
      TransitionComponent={Transition}
    >
      <EditProfile handleClose={handleClose} {...props} />
    </Dialog>
  );
};

/* Handling the user-tabbed-form */
const EditProfile = (props: any) => {
  const {
    agent,
    history,
    superagent,
    superuser,
    state,
    userRole,
    filterValue,
    handleClose,
    setUserRole,
    branchDetails,
    inputOnChangeCooperative, 
    downshiftOnChangeCooperative,
    ...other
  } = props;

  const {
    classes,
    hasCreate,
    hasEdit,
    hasList,
    hasShow,
    ...rest
  } = other;

  let groupRelated = {
    state,
    userRole,
    filterValue,
    setUserRole,
    branchDetails,
    inputOnChangeCooperative, 
    downshiftOnChangeCooperative,
  };

  // who authorized to do stuff
  let authorizedUser = {
    agent: agent,
    superagent: superagent,
    superuser: superuser,
  };
  
  return (
    <React.Suspense fallback={<FetchProgress title="Loading..." top={true} /> }>
      {(history.location.hash === "#my-wallet")
        /* displayed for "my-wallet" main */ 
        ? <UserWallet {...rest} />
        : history.location.hash.includes(`#my-profile`)
          /* displayed for "my-profile" related */ 
          ? <Edit title={<UserTitle />} {...other}>
              <UserFormBasic
                {...rest}
                {...groupRelated}
                {...authorizedUser}
                onCancel={handleClose}
              />
            </Edit>
          : null}
    </React.Suspense>
  );
};

const UserTitle: React.FC<FieldProps<User>> = ({ record }) => {
  return record ? (
    <FullNameField record={record} />
  ) : null;
};
