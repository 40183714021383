import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Card, Button, CardActions, CardContent, CardMedia, Typography, useMediaQuery, Theme } from '@material-ui/core';
import RequestIcon from '@material-ui/icons/AttachMoney';
import ServiceIcon from '@material-ui/icons/Collections';
import { useTranslate } from 'react-admin'; // eslint-disable-line

import { /* MemberList */ MemberCount, MonthlyRevenue, NewMembers, TotalRequests } from './stats'; // eslint-disable-line

import { UpdatesBar } from '../../layout';
import ServicesOffered from './ServicesOffered'; // eslint-disable-line
import AllRequestsLineGraph from './AllRequestsLineGraph';

import { cardStyles } from '../../stylesheets/jss';
import { apiFullCall } from '../../modules';


interface State {
	allRequestsCount: number;
	pendingRequestsCount: number;
	pendingRequests: any;
	allRequests: any;
	memberCount: number;
}

const mediaUrl: string = "";

const SaccoAdminDashboard = (props: any) => { 
	const classes = cardStyles();
	const translate = useTranslate();

	const { anyUpdates, newSaccoUsers } = props

	const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
	const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

	const [ state, setState ] = React.useState<State>({
		allRequests: [],
		allRequestsCount: 0,
		pendingRequestsCount: 0,
		pendingRequests: [],
		memberCount: 0
	});

	const handleApprove = (value) => { return null
		// apiFullCall(
		// 	{ status: 'approved', request_id: value, reason: '' },
		// 	sessionStorage.getItem('token'),
		// 	'post',
		// 	'adminresponceonrequest/'
		// )
		// 	.then((response) => {
		// 		if (response) {
		// 			getRequestStats();
		// 			notify('Service has been approved');
		// 		}
		// 	})
		// 	.catch(
		// 		error => console.error('Error while saving:', error)
		// 	);
	};

	const [ loading, setLoading ] = React.useState<boolean>(false);
  const token = sessionStorage.getItem('token');

  let isLoaded = React.useRef(true);


  /* fetch "sacco stats" */


  React.useEffect(() => {

    if ( isLoaded.current ) {

      /* query for members */

      apiFullCall(
        '', 
        token, 
        'get',
        `cooperativebranchusers/` 
      ).then(res => {

        if (res) {

					if (isLoaded.current) setLoading(true);

          const { status, body } = res;

          if (status === 200 || status === 201) {

            // get the system "id" for farmer (role)
            let totalMembers = body && body.results && body.results.length;
            setState({ ...state, memberCount: totalMembers });
          };

					if (isLoaded.current) setLoading(false);

        };

      }).catch(
        error => console.error('Error while fetching "member" count:', error)
      );

			/* query for requests */

			apiFullCall(
				'', 
				token, 
				'get', 
				`saccorequests/` 
			).then(res => {

				if (res) {

					if (isLoaded.current) setLoading(true);

					const { status, body } = res;

					if (status === 200 || status === 201) {

						const { count, results } = body;

						if (results.length > 0) {

							const pendingRequests = results.filter(
								requests => requests.request_status === 'pending'
							);

							setState((state) => ({
								...state,
							// 	allRequests: res && res.results,
								allRequestsCount: count,
								pendingRequests: pendingRequests,
								pendingRequestsCount: pendingRequests && pendingRequests.length
							}));

						};

						if (isLoaded.current) setLoading(false);
						
					};

				};

			}).catch(
				error => console.error('Error while fetching:', error)
			);

    };

    // clean up API call, on unmount
    return function cleanup() { isLoaded.current = false; }

  }, [isLoaded]) // eslint-disable-line react-hooks/exhaustive-deps  


	const { allRequests, allRequestsCount, pendingRequests, pendingRequestsCount, memberCount } = state;

	const moreProps = {
		classes: classes,
		translate: translate,
		memberCount: memberCount
	};

	return isXSmall ? (
		<Card>
			<CardMedia image={mediaUrl} className={classes.media} />
			<CardContent>
				<Typography variant="h5" component="h2">
					{translate('pos.dashboard.welcome.title')}
				</Typography>
				<Typography component="p">{translate('pos.dashboard.welcome.subtitle')}</Typography>
			</CardContent>
			<CardActions style={{ justifyContent: 'flex-end' }}>
				<Button component={Link} to="/provider">
					<RequestIcon style={{ paddingRight: '0.5em' }} />
					{translate('pos.dashboard.welcome.aor_button')}
				</Button>
				<Button component={Link} to="/providerservice">
					<ServiceIcon style={{ paddingRight: '0.5em' }} />
					{translate('pos.dashboard.welcome.demo_button')}
				</Button>
			</CardActions>
		</Card>
	) : isSmall ? (
		<Card>
			<CardMedia image={mediaUrl} className={classes.media} />
			<CardContent>
				<Typography variant="h5" component="h2">
					{translate('pos.dashboard.welcome.title')}
				</Typography>
				<Typography component="p">{translate('pos.dashboard.welcome.subtitle')}</Typography>
			</CardContent>
			<CardActions style={{ justifyContent: 'flex-end' }}>
				<Button component={Link} to="/provider">
					<RequestIcon style={{ paddingRight: '0.5em' }} />
					{translate('pos.dashboard.welcome.aor_button')}
				</Button>
				<Button component={Link} to="/providerservice">
					<ServiceIcon style={{ paddingRight: '0.5em' }} />
					{translate('pos.dashboard.welcome.demo_button')}
				</Button>
			</CardActions>
		</Card>
	) : (
		<Box>
			<UpdatesBar
				link="/cooperativebranchusers/?approved=false"
				show={anyUpdates}
				cardMessage={translate('pos.dashboard.newSaccoUsers.notice', { smart_count: newSaccoUsers }
				)}
			/>
			<div className={classes.flex}>
				<div className={classes.leftCol}>
					<Box 
						display={{ md: 'flex' }}
						className={classes.upDown}
					>
						<MonthlyRevenue />
						<TotalRequests value={allRequestsCount} loading={loading} />
						<MemberCount {...moreProps} />
					</Box>
					<div className={classes.singleCol}>
						<NewMembers
							handleApproval={handleApprove}
							pendingRequestsCount={pendingRequestsCount}
							pendingRequests={pendingRequests}
						/>
					</div>
					{/* <div className={classes.singleCol}>
						<ServicesOffered />
					</div> */}
					<div className={classes.singleCol}>
						{allRequests && allRequests.length !== 0 ? <AllRequestsLineGraph allRequests={allRequests} /> : null}
					</div>
				</div>
				{/* <div className={classes.rightCol}>
					<div className={classes.flex}>
						<MemberList value={100} />
					</div>
				</div> */}
			</div>
		</Box>
	);
};

export default SaccoAdminDashboard;