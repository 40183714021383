import React from 'react';

interface contextProps{
  classes: object,
  classesBase: any,
  RenderProviderTitle?: React.ReactElement,
  RenderProviderButtons?: React.ReactElement,
  RenderProviderCards?: React.ReactElement,
  isMediumRange: boolean,
  isXSmall: boolean,
  isSmall: boolean
};

export const ProviderDashboardContext = React.createContext({} as contextProps);
