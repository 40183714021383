import React from 'react';
import clsx from 'clsx';
import format from 'date-fns/format';
import { useFormState } from 'react-final-form';
import { SaveButton, Toolbar, useRefresh, useNotify } from 'react-admin';

import { ApproveButton } from '../../../layout';
import { apiFullCall } from '../../apiHelper';


/* Handling "accept" CASH request */


export const CashOrderAcceptToolbar = props => {
  // destructure to pass directly to <ApproveButton>
  const { isXSmall, ...rest } = props;
  const { handleCloseAcceptDialog, ...other } = rest;

  return (
    <Toolbar {...other}>
      <ApproveButton
        label="No"
        icon={null}
        isXSmall={isXSmall}
        className={clsx('small--btn')}
        onClick={handleCloseAcceptDialog}
      />
      <AcceptButton {...rest} />
    </Toolbar>
  );
};

const AcceptButton = props => {
  const formState = useFormState();
  const refresh = useRefresh();
  const notify = useNotify();

  // destructure to pass "rest" to <SaveButton>
  const { handleCloseAcceptDialog, onClose, mutationMode, record, validating, ...rest } = props;

  // destructure "date" and "reason" to pass to API call
  const { provider_visit_date, reason } = formState && formState.values;

  const token = sessionStorage.getItem('token');

  const [loading, setLoading] = React.useState(false); // eslint-disable-line

  let isLoaded = React.useRef(false);

  React.useEffect(() => {
    /* Cleanup useEffect */

    isLoaded.current = true; // set to true on mount...
    return () => { isLoaded.current = false; }; // ... and to false on unmount
  }, [isLoaded]); // eslint-disable-line react-hooks/exhaustive-deps
  

  const acceptData = {
    request_id: record && record['id'],
    provider_visit_date:
      provider_visit_date &&
      format(new Date(provider_visit_date), 'dd-MM-yyyy'),
    reason: reason,
    status: 'accepted',
  };

  const handleAcceptance = React.useCallback(() => {

    // Double-check to ensure that "date", "reason" and "id" are defined or not empty
    if ( !!provider_visit_date && !!reason && !!record['id'] ) {

      apiFullCall(
        acceptData, 
        token, 
        'post', 
        `providerrequests/`
      ).then(res => {

          if (res) {

            if (isLoaded.current) setLoading(true);

            const { status } = res;

            if (status === 200 || status === 201) {
              // when successfully submitted
              notify('The request has been scheduled for delivery', 'info');

              // go back to requests list
              handleCloseAcceptDialog();
              onClose();
              refresh();
            } else if (status === 400) {
              notify(`Please check again and resend.`, 'warning');
            } else if (status >= 500) {
              notify(`Server error, please try again later.`, 'warning');
            }

            if (isLoaded.current) setLoading(false);

          };

        })
        .catch(error => console.error('Error while sending:', error));
    } else {
      // inform user to enter a "date" and/or "comment"
      notify(`Please select a delivery date and/or add a comment`, 'warning');
    }
  }, [acceptData]); // eslint-disable-line react-hooks/exhaustive-deps

  // define a component that returns "null"
  // And pass it to <SaveButton> to override the "save" icon
  const NoIcon = () => null;

  return (
    <SaveButton
      {...rest}
      icon={<NoIcon />}
      label={loading ? '' : 'Yes'}
      submitOnEnter={false}
      saving={loading}
      onSave={handleAcceptance}
    />
  );
};
