import React from 'react';
import { Redirect } from 'react-router';
import { Route, useHistory } from 'react-router-dom';
import { Create } from 'react-admin';

import { GroupTitle } from '../groupfields';
import { FetchProgress } from '../../../layout';

import * as P from '../../../allPermissions';

const GroupMultiForm = React.lazy(() => import("../groupforms/GroupMultiForm"));

const GroupCreate = (props: any) => {
  const { permissions, basePath } = props;

  const history = useHistory();

  const addOnlyGroups = P.authCreateGroups(permissions);

  const handleClose = React.useCallback(() => {
    history.push(basePath);
  }, [history, basePath]);

  if (!permissions) { // if no permissions
    return null;
  };

  /* passed to groups-related */
  const groupProps = {
    onCancel: handleClose,
    ...props
  };

  return (  
    <React.Suspense fallback={<FetchProgress title="Loading section..." top={true} /> }>
      {addOnlyGroups
        ? <Route path="/groups">
            <NewGroup {...groupProps} />
          </Route> 
        : <Redirect to="/" />} 
    </React.Suspense>
  )
};

const NewGroup = (props: any) => {

  const { onCancel, ...other } = props;
  const { basePath, hasCreate, hasEdit, hasList, hasShow, permissions, ...rest } = other;

  const moreProps = {
    onCancel: onCancel,             // used to close the forms
    ...rest,
  };

  return (
    <Create {...other} title={<GroupTitle />}>
      <GroupMultiForm {...moreProps} />
    </Create>
  );
};

export default GroupCreate;
