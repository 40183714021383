import React from 'react';
import { useTranslate } from 'react-admin';

export const FunderTitle = props => {
  const translate = useTranslate();
  const { match } = props;

  const newFunder = match && match.path === '/funders/create';
  const editFunder = match && match.path === '/funders/:id';

  return (
    <span>
      {newFunder && translate("resources.funders.fields.create_title")}
      {editFunder && translate("resources.funders.fields.edit_title")}
    </span>
  )
};