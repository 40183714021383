import React from 'react';
import PropTypes from 'prop-types';
import { Identifier } from 'ra-core';
import { Box, Typography } from '@material-ui/core';
import AggregateIcon from '@material-ui/icons/Publish';
import { BulkSaveWithConfirmButton, BulkSaveWithConfirmButtonProps } from './BulkSaveWithConfirmButton';

import { RequestAggregateForm } from 'modules/requests/requestforms';

const RenderContent = props => {
  const {confirmTitle, selectedIds} = props;
  return (
    <Box mt={{ xs: '18px' }} mb={{ xs: '10px' }}>
      <Typography variant="h5" component="div" >
        Are you ready to send the {selectedIds && selectedIds.length}&nbsp;{confirmTitle}?
      </Typography>            
    </Box>
  )
};

export const AggregateButton: React.FC<AggregateButtonProps> = ({ undoable, ...props }) => {

  const moreProps = {
    label: 'Aggregate',
    confirmTitle: '(credit) requests',
    mainTitle: 'Please confirm ...',
    ...props
  };

  return undoable ? (
    <BulkSaveWithConfirmButton {...moreProps} />
  ) : null;
};

interface Props {
  undoable?: boolean;
  basePath?: string;
  label?: string;
  icon: React.ReactElement;
  resource: string | any;
  selectedIds: Identifier[] | any;
};

type AggregateButtonProps = Props & BulkSaveWithConfirmButtonProps;

AggregateButton.propTypes = {
  basePath: PropTypes.string,
  label: PropTypes.string,
  resource:  PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  selectedIds: PropTypes.arrayOf(PropTypes.any),
  undoable: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.any]),
};

AggregateButton.defaultProps = {
  undoable: true,
  content1: <RenderContent />,
  content2: <RequestAggregateForm />,
  icon: <AggregateIcon />,
};
