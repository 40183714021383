import React, { useState, useEffect } from 'react';

import { Card } from '@material-ui/core/';
import { Line as LineChart, ChartData } from 'react-chartjs-2'; // eslint-disable-line
import moment from 'moment';

import 'react-day-picker/lib/style.css';
import { cardStyles } from '../../stylesheets/jss';

const options: any = {  // eslint-disable-line
	elements: {
		line: {
			tension: 0
		}
	},
	responsive: true,
	title: {
		display: true,
		text: 'All Requests Made within the sacco',
		fontSize: 12,
		marginBottom: '25px'
	},
	legend: {
		display: true,
		position: 'top'
	},
	scales: {
		yAxes: [
			{
				scaleLabel: {
					display: true,
					labelString: 'No. of requests'
				},
				ticks: {
					max: 5,
					min: 0,
					stepSize: 1
				},
				gridLines: {
					zeroLineWidth: 3,
					zeroLineColor: '#777'
				}
			}
		],
		xAxes: [
			{
				// type: 'time',
				distribution: 'linear',
				display: true,
				scaleLabel: {
					display: true,
					labelString: 'Date'
				},
				gridLines: {
					zeroLineWidth: 3,
					zeroLineColor: '#777'
				},
				ticks: {
					major: {
						fontStyle: 'bold',
						fontColor: '#FF0000'
					}
				}
			}
		]
	}
};

const AllRequestsLineGraph = ({ allRequests }) => {
	const classes = cardStyles();
	const [ chatData, setChatData ] = useState<ChartData<any>>({}); // eslint-disable-line
	const [ startDate, setStartDate ] = useState('2020-04-01');
	const [ endDate, setEndDate ] = useState('2020-05-01');

	const chart = (dateLabels, allrequests) => {

		setChatData({
			labels: dateLabels,
			datasets: [
				{
					label: 'All requests',
					spanGaps: true,
					fill: false,
					borderWidth: 3,
					borderColor: 'red',
					backgroundColor: 'red',
					hoverBorderWidth: 3,
					hoverBorderColor: 'black',
					data: allrequests
				}
			]
		});
	};

	const handleDate = (e) => {
		e.target.type = 'date';
	};

	useEffect(() => {
		let getCountOfRequestsByDate = {};

		const getRequestsByDateRange =
			allRequests.length !== 0
				? allRequests.filter((a) => {
						const requestDate = moment(a.request_date).format('YYYY-MM-DD');
						const startRequestDate = moment(startDate).format('YYYY-MM-DD');
						const endRequestDate = moment(endDate).format('YYYY-MM-DD');
						return requestDate >= startRequestDate && requestDate <= endRequestDate;
					})
				: null;

		//get the count of requests made by the date
		//returns an object {date:count}
		getRequestsByDateRange.map((request) => {
			const requestDate = moment(request.request_date).format('YYYY-MM-DD');
			return (getCountOfRequestsByDate[requestDate] =  (getCountOfRequestsByDate[requestDate] || 0) + 1);
		});

		//extracting a list of the dates
		const getDateList = Object.keys(getCountOfRequestsByDate);

		let RequestsCountByDate: any = [];
		getDateList.map((date: any) => RequestsCountByDate.push(getCountOfRequestsByDate[date]));

		return chart(getDateList, RequestsCountByDate);
	
	}, [ startDate, endDate, allRequests ]);	// eslint-disable-next-line react-hooks/exhaustive-deps

	return (
		<Card className={classes.root}>
			<div className={classes.graphContainer}>
				<h3>All requests</h3>
				<div style={{ float: 'right' }}>
					<input
						type="text"
						name="start_date"
						onChange={(e) => setStartDate(moment(e.target.value).format('YYYY-MM-DD'))}
						onFocus={handleDate}
						placeholder="2020-04-01"
						style={{ marginRight: '5px' }}
						required
					/>
					<input
						type="text"
						name="end_date"
						onChange={(e) => setEndDate(moment(e.target.value).format('YYYY-MM-DD'))}
						onFocus={handleDate}
						placeholder="2020-05-01"
						style={{ marginBottom: '15px' }}
						required
					/>
				</div>
				{/* <LineChart data={chatData} width={450} height={250} options={options} /> */}
			</div>
		</Card>
	);
};

export default AllRequestsLineGraph;
