import React, { FC } from 'react';
import clsx from 'clsx';

import format from 'date-fns/format';
import isBefore from 'date-fns/isBefore';
import formatDistance from 'date-fns/formatDistance';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInHours from 'date-fns/differenceInHours';

import { makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import { FieldProps } from '../../../../types';

const useStyles = makeStyles({
	withInTime: {
		color: '#3f51b5',
	},
	outOfTime: {
		color: '#df1f26',
	},
});


/* requested date - cash & credit orders */


const RequestDateField: FC<FieldProps> = (props: any) => {
	const classes = useStyles();
	const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

	const { record, resource } = props;
	
	const dateOfRequest = record &&
		record.request_date &&
			format(new Date(record['request_date']), 'dd/MM/yyyy p');			
	
	const daysFromRequestDate = record &&
		record.request_date &&
			formatDistance(new Date(record['request_date']), new Date(), { addSuffix: true } );

	// returns "hours"
	const timeSinceRequest = record &&
		record.request_date &&
			differenceInHours(new Date(), new Date(record['request_date']));

	// cash orders
	const delivered = record &&	record['request_status'] &&	record['request_status'] === "delivered";
	
	// credit orders
	const rejected = record && record['request_status'] && record['request_status'] === "rejected";

	const approved = record && record['request_status'] && record['request_status'] === "approved" && resource === 'credit-orders';

	if (!record) {

		return null;

	} else if (	delivered || approved || rejected ) {

		// if "delivered", show period between
		// "request date" and "planned deliver date"
		return (
			<span>
				{dateOfRequest}
			</span>
		);

	} else {

		return (
			<span>
				{isXSmall 
					? <>{dateOfRequest} &nbsp;</>
					: <>{dateOfRequest}<br/></>
				}
				<span className={clsx({
					[classes.withInTime]: timeSinceRequest < 24,
					[classes.outOfTime]: timeSinceRequest >= 24,
				})}>
					&#40;{daysFromRequestDate}&#41;
				</span>
			</span>
		);

	};
};

export const PureRequestDateField = RequestDateField;

PureRequestDateField.defaultProps = {
	source: 'request_date',
	label: 'Requested On'
};


/* approved date - credit orders */


const ApproveDateField: FC<FieldProps> = (props: any) => {
	const classes = useStyles();
	const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

	const { record } = props;
	
	const dateOfApproval = record &&
		record.approval &&
			record.approval.approval_date &&
				format(new Date(record['approval']['approval_date']), 'dd/MM/yyyy p');			

	// returns "days"
	const timeSinceRequest = record &&
		record.request_date &&
			record.approval &&
				record.approval.approval_date &&
					differenceInDays(new Date(record['approval']['approval_date']), new Date(record['request_date']));

	
	if (!record) {

		return null;

	} else {

		return (
			<span>
				{isXSmall 
					? <>{dateOfApproval} &nbsp;</>
					: <>{dateOfApproval}<br/></>
				}
				<span className={clsx({
					[classes.withInTime]: timeSinceRequest <= 2,
					[classes.outOfTime]: timeSinceRequest >= 2,
				})}>
					&#40;{
						timeSinceRequest <= 1
							? `in less than 24hrs`
							: timeSinceRequest <= 2
								? `in more than 48hrs`
								: `in more than 2days`
					}&#41;
				</span>
			</span>
		);

	};
};

export const PureApproveDateField = ApproveDateField;

PureApproveDateField.defaultProps = {
	source: 'approval.approval_date',
	label: 'Approved On'
};


/* aggregated date - credit orders */


const AggregateDateField: FC<FieldProps> = (props: any) => {
	const classes = useStyles();
	const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

	const { record } = props;
	
	const dateAggregated = record &&
		record.date_added &&
			format(new Date(record['date_added']), 'dd/MM/yyyy p');	
	
	const daysFromAggregateDate = record &&
		record.date_added &&
			formatDistance(new Date(record['date_added']), new Date(), { addSuffix: true } );	

	// returns "days"
	const timeSinceRequest = record &&
		record.date_added &&
			differenceInDays(new Date(record['date_added']), new Date(record['request_date']));

	
	if (!record) {

		return null;

	} else {

		return (
			<span>
				{isXSmall 
					? <>{dateAggregated} &nbsp;</>
					: <>{dateAggregated}<br/></>
				}
				<span className={clsx({
					[classes.withInTime]: timeSinceRequest < 24,
					[classes.outOfTime]: timeSinceRequest >= 24,
				})}>
					&#40;{daysFromAggregateDate}&#41;
				</span>
			</span>
		);

	};
};

export const PureAggregateDateField = AggregateDateField;

PureAggregateDateField.defaultProps = {
	source: 'date_added',
	label: 'Aggregated On'
};


/* rejected date - credit orders */


const RejectedDateField: FC<FieldProps> = (props: any) => {
	const classes = useStyles();
	const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

	const { record } = props;
	
	const dateOfApproval = record &&
		record.approval &&
			record.approval.approval_date &&
				format(new Date(record['approval']['approval_date']), 'dd/MM/yyyy p');			

	// returns "days"
	const timeSinceRequest = record &&
		record.request_date &&
			record.approval &&
				record.approval.approval_date &&
					differenceInDays(new Date(record['approval']['approval_date']), new Date(record['request_date']));

	
	if (!record) {

		return null;

	} else {

		return (
			<span>
				{isXSmall 
					? <>{dateOfApproval} &nbsp;</>
					: <>{dateOfApproval}<br/></>
				}
				<span className={clsx({
					[classes.withInTime]: timeSinceRequest <= 2,
					[classes.outOfTime]: timeSinceRequest >= 2,
				})}>
					&#40;{
						timeSinceRequest <= 1
							? `in less than 24hrs`
							: timeSinceRequest <= 2
								? `in more than 48hrs`
								: `in more than 2days`
					}&#41;
				</span>
			</span>
		);

	};
};

export const PureRejectedDateField = RejectedDateField;

PureRejectedDateField.defaultProps = {
	source: 'approval.approval_date',
	label: 'Rejected On'
};


/* scheduled date - cash orders */


const DeliveryDateField: FC<FieldProps> = ({ record }) => {
	const classes = useStyles();
	
	// return formated "planned delivery date"
	let dateOfDelivery = record &&
		record.completion &&
			record.completion.provider_visit_date &&
				format(new Date(record['completion']['provider_visit_date']), 'dd/MMM/yyyy');			
	
	// return "period" between today and planned delivery date
	let daysToDeliveryDate = record &&
		record.completion &&
			record.completion.provider_visit_date &&
				formatDistance(new Date(record['completion']['provider_visit_date']), new Date(), { addSuffix: true } );
	
	// returns "boolean" to support color code
	let timeToDeliver = record &&
		record.completion &&
			record.completion.provider_visit_date &&
				isBefore(new Date(), new Date(record['completion']['provider_visit_date']));

	if (!record) {

		return null;

	} else if (	
		record &&	record['request_status'] &&	record['request_status'] === "delivered"
	) {

		// if "delivered", show period between
		// "request date" and "planned deliver date"
		return (
			<span>
				{dateOfDelivery}
			</span>
		);

	} else {

		// Expected, if "accepted" to show period between
		// "today" and "planned delivery date"
		return (
			<span>
				{dateOfDelivery}<br/>
				<span className={clsx({
					[classes.withInTime]: timeToDeliver,
					[classes.outOfTime]: !timeToDeliver,
				})}>
					&#40;{daysToDeliveryDate}&#41;
				</span>
			</span>
		);

	};
};

export const PureDeliveryDateField = DeliveryDateField;

PureDeliveryDateField.defaultProps = {
	source: 'completion.provider_visit_date',
	label: 'Scheduled'
};


/* confirmed date - cash orders */


const ConfirmedDateField: FC<FieldProps> = ({ record }) => {

	// return formated "confirmed delivery date"
	let confirmedDelivery = record &&
		record.completion &&
			record.completion.completion_date &&
				format(new Date(record['completion']['completion_date']), 'dd/MMM/yyyy p');

	if (!record) {

		return null;

	} else {

		// Expected, if "accepted" to show period between
		// "today" and "planned delivery date"
		return (
			<span>
				{confirmedDelivery}
			</span>
		);

	};
};

export const PureConfirmedDateField = ConfirmedDateField;

PureConfirmedDateField.defaultProps = {
	source: 'completeion.completion_date',
	label: 'Delivered on'
};

