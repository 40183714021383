import FarmerIcon from '@material-ui/icons/PeopleOutline';

import UserList from '../usercrud/UserList';
import UserCreate from '../usercrud/UserCreate';
import UserEdit from '../usercrud/UserEdit';

const farmers = {
  list: UserList,
  create: UserCreate,
  edit: UserEdit,
  icon: FarmerIcon,
};

export default farmers;
