import TransactionIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import transactiondetails from './details';
import TransactionReport from './TransactionReport';

const transactioncards = {
  icon: TransactionIcon,
  list: TransactionReport,
};

const transactions = {
  cards: transactioncards,
  detail: transactiondetails
};

export default transactions;
