import loanpapps from './loanpapps';
import loanpays from './loanpays';
import loanproducts from './loanproducts';

const loans = {
  loanpapps: loanpapps,
  loanpays: loanpays,
  loanproducts: loanproducts,
}

export default loans;