import React from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom'
import { TextField } from 'react-admin';
import { Avatar, Card, CardHeader, CardContent } from '@material-ui/core';
import GroupWorkIcon from '@material-ui/icons/GroupWork';

import { CircularProgress } from '../../../layout';

const CooperativeReportCard = props => {
	const history = useHistory();
    console.log(props)
  const { ids, basePath, data, filters, loading } = props; // eslint-disable-line
	
	const viewDetails = (classification) => { 
		sessionStorage.setItem('_cooperatives_filter', JSON.stringify(filters))
		sessionStorage.setItem('_cooperatives_classification', JSON.stringify(classification))
		
		history.push({ 
			pathname: '/cooperativesreport-details', 
			state: { classification: classification, filters: filters } 
		});
	};

	return (
		<div className={clsx('report-section')}>
			{ids.map(id =>
				<Card
					raised={false}
					key={id} 
					className={clsx('report-card', 'groups')}  
					onClick={() => viewDetails(data[id])}
				>
					<CardHeader
						title={<TextField record={data[id]} source="short_name" />}
						subheader={<TextField record={data[id]} source="coop_service_name" />}
						avatar={<Avatar icon={<GroupWorkIcon/>} />}
					/>
					{loading
						? <CircularProgress className={clsx('sm-wide')} />
						:	<CardContent>
								<TextField record={data[id]} source="cooperatives" />
							</CardContent>}
				</Card>
			)}
		</div>
	);
};

CooperativeReportCard.defaultProps = {
	data: {},
	ids: [],
};

export default CooperativeReportCard;