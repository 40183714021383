import React from 'react';
import { useTranslate } from 'react-admin';

export const GroupTitle = props => {
  const translate = useTranslate();
  const { record } = props;

  // check if record has key 'id', use this check to render guide messages
  let isRegistered = Object.prototype.hasOwnProperty.call(record, 'id');

  return (
    <span>
      {isRegistered 
        ? translate("resources.cooperatives.fields.edit_title")
        : translate("resources.cooperatives.fields.create_title")
      }
    </span>
  )
};
