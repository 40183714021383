import React from 'react';
import clsx from 'clsx';
import { SimpleForm } from 'react-admin';
import { Slide, Theme, useMediaQuery } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { grantAccess } from '../../validation';

import { FetchProgress } from '../../../layout';
import { FunderFields } from '../settingsfields';
import { userEditStyles } from '../../stylesheets';

const FunderManager = React.lazy(() => import("../settingsops/FunderManager"));

interface Style {
  input?: string;
  check?: string;
  container?: string;
  details?: string;
  photo?: string;
}

const FunderForm = (props: any) => {
  const classes: Style = userEditStyles();
  const token = sessionStorage.getItem('token');

  // destructure "basePath", remove from DOM element (div, line 80)
  const {
    className,
    onCancel,
    location,
    ...rest
  } = props;

  const { record } = rest;
  
  /* if record (ra) has key 'id', use this check to render guide messages */
  let isRegistered: boolean = record && Object.prototype.hasOwnProperty.call(record, 'id');

  let isSmall: boolean = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  /* passable properties */
  const generalProps = {
    token: token,
    record: record,
    classes: classes,
    isSmall: isSmall,
    location: location,
    onCancel: onCancel,
    isRegistered: isRegistered,
    ...props
  };

  const Toolbar = <FunderManager {...generalProps} />;

  return (
    <React.Suspense fallback={<FetchProgress title="Loading buttons..." top={true} /> }>
      <SimpleForm
        {...rest}
        className={clsx( className, 'register-form' )}
        submitOnEnter={false}
        validate={grantAccess}
        toolbar={Toolbar}
      >
        <FunderFields {...generalProps} />
      </SimpleForm>
    </React.Suspense>
  );
};

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children?: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default FunderForm;