import React from 'react';
import { CashOrderAcceptToolbar as CashAT } from '../cashorders/CashOrderAccept';
import { CreditOrderAcceptToolbar as CreditAT } from '../creditorders/CreditOrderAccept';


/**
 * For use within the RequestAcceptForm "toolbar"
 * @approve - admin
 * @accept  - provider
 */

export const Accept = (props: any) => {

  const { resource } = props;

  // orders
  const cashOrders = resource === 'cash-orders';
  const creditOrders = resource === 'credit-orders';

  return creditOrders ?
    /* approve CREDIT orders */ <CreditAT {...props} />
    : cashOrders
      ? /* accept CASH orders */ <CashAT {...props} />
      : null;
};
