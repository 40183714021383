import React from 'react';
import clsx from 'clsx';
import Downshift from 'downshift';
import { Box, MenuItem, Paper } from '@material-ui/core';

import { Text } from '../../../validation';
import { AkbInput, AkbTextField, SectionTitle } from '../../../utils';


export const AddToGroupFields = (props: any) => {
  const {
    classes,
    state,
    branchDetails,
    userRole,
    setUserRole,
    filterValue,
    downshiftOnChangeCooperative,
    inputOnChangeCooperative,
  } = props;

  return (
    <>
      <SectionTitle label="Add to Group" />
      <Box
        display={{ md: 'flex' }}
        mb={{ xs: '20px' }}
        width={{ md: '100% !important' }}
        className={clsx('add-to-group', 'AkRegisterForm-body')}
      >
        <Box flex={3}>              
          <Downshift
            onChange={downshiftOnChangeCooperative}
            itemToString={item => (item ? item.cooperative_branch_name : '')}
          >
            {({ selectedItem, getInputProps, getItemProps, highlightedIndex, isOpen, inputValue, getLabelProps }) => (
              <div
                className={clsx(
                  classes.container,
                  { 'stretch': isOpen }
                )}
              >
                {AkbInput({
                  fullWidth: true,
                  classes,
                  name: 'search-cooperative',
                  label: 'Search by Group',
                  InputProps: getInputProps({
                    onChange: inputOnChangeCooperative,
                  }),
                })}
                {isOpen && (
                  <Paper square className={clsx('select-group')}>
                    {getGroupSuggestions(state.groupList, inputValue).map(
                      (item, index) => {
                        const { cooperative_branch_name } = item;

                        const isHighlighted = highlightedIndex === index;
                        const isSelected =
                          String(selectedItem || '').indexOf(cooperative_branch_name) >
                          -1;

                        return (
                          <MenuItem
                            {...getItemProps({ key: index, index, item })}
                            selected={isHighlighted}
                            component="div"
                            style={{
                              fontWeight: isSelected ? 500 : 400,
                            }}
                          >
                            {cooperative_branch_name}
                          </MenuItem>
                        );
                      }
                    )}
                  </Paper>
                )}
              </div>
            )}
          </Downshift>
          <AkbTextField
            disabled={true}
            value={branchDetails.cooperative_branch_name}
            name="cooperative_branch_id"
            label="Selected Group"
            className={clsx(
              { 'hidden': !branchDetails.id },
              classes.input
            )}
          />
          <AkbTextField
            value={userRole.role}
            name="role"
            label="Group role"
            onChange={event => setUserRole({ role: event.target.value })}
            className={classes.input}
            validate={Text}
          />
        </Box>
      </Box>
      <Box 
        display={{ md: 'flex' }}
        width={{ md: '100% !important' }}
        className={clsx('AkRegisterForm-body')}
      >
        <SectionTitle 
          label='*fields (below) will be updated when you "Search by Group"'
          className={clsx(
            { 'hidden': !filterValue.id },
            classes.details
          )} 
        />
      </Box>
      <Box
        display={{ md: 'flex' }}
        width={{ md: '100% !important' }}
        className={clsx('add-to-group', 'AkRegisterForm-body')}
      >
        <Box flex={3}>
          <AkbTextField
            type="hidden"
            disabled={true}
            value={filterValue.id}                  
            name="village_id"
            label="id"
            className={clsx('unique-field', classes.input)}
          />
          <AkbTextField
            disabled={true}
            value={filterValue.villagename}
            name="villagename"
            label="Village"
            className={clsx(
              { 'hidden': !filterValue.id },
              classes.input
            )}
          />
          <AkbTextField
            disabled={true}
            value={filterValue.parishname}
            name="parishname"
            label="Parish"
            className={clsx(
              { 'hidden': !filterValue.id },
              classes.input
            )}
          />
          <AkbTextField
            disabled={true}
            value={filterValue.subcountyname}
            name="subcountyname"
            label="Subcounty"
            className={clsx(
              { 'hidden': !filterValue.id },
              classes.input
            )}
          />
          <AkbTextField
            disabled={true}
            value={filterValue.countyname}
            name="countyname"
            label="County"
            className={clsx(
              { 'hidden': !filterValue.id },
              classes.input
            )}
          />
          <AkbTextField
            disabled={true}
            value={filterValue.districtname}
            name="districtname"
            label="District (selected)"
            className={clsx(
              { 'hidden': !filterValue.id },
              classes.input
            )}
          />
          <AkbTextField
            disabled={true}
            value={filterValue.regionname}
            name="regionname"
            label="Region"
            className={clsx(
              { 'hidden': !filterValue.id },
              classes.input
            )}
          />           
        </Box>
      </Box>
    </>
  )
};


const getGroupSuggestions = (groupList = [], inputValue) => {
  let count = 0;

  return groupList.filter(({ cooperative_branch_name }) => {
    const keep =
      (!inputValue ||
        String(cooperative_branch_name)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) !== -1) &&
      count < 10; // max. number of suggestion within list

    if (keep) {
      count += 1;
    }

    // return just the first ten,
    // helps improve performance
    return keep;
  });
};

