import React from 'react';
import clsx from 'clsx';
import { required, SimpleForm, TextInput } from 'react-admin';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

import { Rejection } from '../requestops';

import { Transition, SectionTitle } from '../../utils';
import { userEditStyles } from '../../stylesheets';

export const RequestRejectForm = props => {
  const classes = userEditStyles();

  const {
    isXSmall,
    handleData,
    rejectionDialog,
    handleCloseRejectDialog,    
    syncWithLocation,
    ...rest 
  } = props; // eslint-disable-line

  // passed to <Rejection />
  const rejectProps = {
    isXSmall: isXSmall,
    handleCloseRejectDialog: handleCloseRejectDialog,
    ...rest
  };
  
  return (
    <Dialog
      maxWidth="xs"
      open={rejectionDialog}
      TransitionComponent={Transition}
      className={clsx('request--modal')}
      aria-labelledby="reject-dialog-title"
    >
      <DialogTitle
        disableTypography={true} // makes this a <div> not a default <h2>
        id="reject-dialog-title"
      >
        <SectionTitle label="Reject this request..." />
      </DialogTitle>
      <DialogContent>
        <SimpleForm 
          {...rest}
          submitOnEnter={false}
          className={clsx('request', props.className)}
          toolbar={<Rejection {...rejectProps} /> }
        >
          <TextInput
            label="Enter a reason"
            source="reason"
            className={classes.input}
            validate={[required('Please provide a reason')]}
          />
        </SimpleForm>
      </DialogContent>
    </Dialog>
  );
};
