import React from 'react';
// import format from 'date-fns/format';
// import parseISO from 'date-fns/parseISO';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});

// Create Document Component
export const UserProfilePDF = () => {
  const printable = JSON.parse(sessionStorage.getItem("printable"));

  if (!printable) return null;

  // const {
  //   dob,
  //   nin,
  //   email,
  //   gender,
  //   username,
  //   last_name,
  //   first_name,
  //   occupation,
  //   middle_name,
  //   nationality,
  //   income_status,
  //   marital_status,
  //   secondary_email,
  //   refugee_reg_number,
  //   secondary_phone_number
  // } = printable;

  // console.log(state, 'state')

  // const LastName = () => `${last_name}`;
  const LastName = () => ` Matovu`;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text>Last name:</Text>
          <Text children={<LastName/>} />
          {/* <Text>{format(parseISO(dob), 'dd, MMM yyyy')}</Text> */}
        </View>
        <View style={styles.section}>
          <Text>Section #2</Text>
        </View>
      </Page>
    </Document>
  );
};