import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useTranslate } from 'react-admin';
import GroupIcon from '@material-ui/icons/Group';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import { Button, Box, Grid, Typography } from '@material-ui/core';

import { AgentDashboardContext } from 'context/dashboards';
import { apiFullCall, Separator } from 'modules';
import { cardStyles, moreStyles } from 'stylesheets/jss';
import { CardWithIcon } from 'layout';

interface Style {
  flex?: string;
  bothCol?: string;
  leftCol?: string;
  rightCol?: string;
  spaceTop?: string;
  flexChange?: string;
  flexColumn?: string;
}

const AgentDashboard = (props: any) => {
  const classes = moreStyles();
  const classesBase: Style = cardStyles();
  
  const { isSmall, isXSmall, isMediumRange } = props;

  return (
    <AgentDashboardContext.Provider
      value={{
        classes: classes,
        classesBase: classesBase,
        isMediumRange: isMediumRange,
        isXSmall: isXSmall,
        isSmall: isSmall
      }}
    >
      <Box mt={{ xs: '24px' }} className={classesBase.flex}>
        <Box className={clsx(classesBase.leftCol, classesBase.bothCol)}>
          <RenderAgentTitle />
          <RenderAgentButtons />
          <RenderAgentCards />
        </Box>
        {!isSmall && <Box className={classesBase.rightCol}></Box>}
      </Box>
    </AgentDashboardContext.Provider>
  );
};

const RenderAgentTitle = () => {
  const translate = useTranslate();

  const firstName = sessionStorage.getItem('firstName');
  const lastName = sessionStorage.getItem('lastName');

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={10}>
        <Typography variant="h5" component="h2">
          {translate('pos.dashboard.agent.title')} {firstName}&nbsp;{lastName}
        </Typography>
        <Separator />
        <Typography component="p">
          {translate('pos.dashboard.agent.subtitle')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={2}></Grid>
    </Grid>
  );
};

const RenderAgentButtons = () => {
  const classes = moreStyles();
  const classesBase = cardStyles();
  const translate = useTranslate();

  const { isSmall } = React.useContext(AgentDashboardContext);

  return (
    <Box className={clsx(classesBase.flexColumn, classesBase.upDown )}>
      <Box flex={1} className={classesBase.flexChange}>
        <Button
          // farmer/user registration
          size="small"
          component={Link}
          to="/users/create"
          className={clsx(classes.button, classes.farmerButton)}
        >
          {translate('pos.dashboard.agent.farmer_button')}
        </Button>
      </Box>
      <Box flex={!isSmall ? 1.925 : 1} className={classesBase.flexChange}>
        <Button
          // provider registration
          size="small"
          component={Link}
          to="/provider/create"
          className={clsx(classes.button)}
        >
          {translate('pos.dashboard.agent.provider_button')}
        </Button>
      </Box>
    </Box>
  );
};

const RenderAgentCards = () => {  
  const classesBase = cardStyles();

  const userId = sessionStorage.getItem('id');
  const token = sessionStorage.getItem('token');
  const programid = sessionStorage.getItem('programid');

  const { isSmall } = React.useContext(AgentDashboardContext);

  // used to trigger card loaders
  const [loading, setLoading] = React.useState(true);

  const [users, setUsers] = React.useState<any>(null);
  const [providers, setProviders] = React.useState<any>(null);

  let isLoaded = React.useRef<boolean>(true);

  /* fetch "farmers" and "providers" totals */

  React.useEffect(() => {

    if (isLoaded && userId) {

      /* farmers (users) */

      apiFullCall(
        '',
        token,
        'get',
        `users/?is_farmer=true&programid=${programid}`
      ).then(res => {

        if (res) {

          const { status, body } = res;

          if (isLoaded.current) setLoading(true);

          if (status === 200 || status === 201) {
            setUsers(body.count);
          };

          if (isLoaded.current) setLoading(false);

        };

      }).catch(
        error => console.error('Error while fetching data:', error)
      );

      /* service providers */
      
      apiFullCall(
        '',
        token,
        'get',
        `provider/?is_provider=true&programid=${programid}`
      ).then(res => {

        if (res) {

          const { status, body } = res;

          if (isLoaded.current) setLoading(true);

          if (status === 200 || status === 201) {
            setProviders(body.count);
          };

          if (isLoaded.current) setLoading(false);

        };

      }).catch(
        error => console.error('Error while fetching data:', error)
      );

    };


  }, [isLoaded, userId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box className={clsx(classesBase.flexColumn, classesBase.upDown)}>
      <Box flex={1} className={classesBase.right}>
        <CardWithIcon
          to={`/users`}
          title="FARMER(S)"
          loading={loading}
          icon={GroupIcon}
          subtitle={!!users?users:"0"}
        />
      </Box>
      <Box flex={1} className={classesBase.spaceTop}>
        <CardWithIcon
          to={`/provider`}
          title="SERVICE PROVIDER(S)"
          loading={loading}
          icon={TouchAppIcon}
          subtitle={!!providers?providers:"0"}
        />
      </Box>
      {!isSmall && <Box flex={1}></Box>}
    </Box>
  );
};

export default AgentDashboard;
