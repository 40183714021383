import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import { UserMenu, MenuItemLink } from 'react-admin';
/* start - icons */
import FaceIcon from '@material-ui/icons/Face';
import SwapProgramIcon from '@material-ui/icons/ThreeSixty';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
/* end - icons */
import { DialogSwitchProgram } from '../dialogs';

export const ProfileMenu = props => {
  const history = useHistory();

  const userId = sessionStorage.getItem('id');
  const userPrograms = sessionStorage.getItem('userPrograms');
  const permissions = sessionStorage.getItem('permissions');

  if (!userId) {
    // render only 'logout', since 'id' is undefined
    return <UserMenu {...props} />
  };

  return (
    <>
      <UserMenu {...props}>
        {(userPrograms && JSON.parse(userPrograms).length > 1) &&
          <MenuItemLink
            // Render "Switch program", if user is in multiple progs 
            to={`${history.location.pathname}#switch-program`}
            primaryText="Switch district"
            leftIcon={<SwapProgramIcon />}
          />
        }
        {permissions && !permissions.includes('devpartner') &&
          <MenuItemLink
            // Render "My Wallet", if we have a valid "id"
            to={{ pathname: `/users/${userId}`, hash: '#my-wallet', state: { prevPath: history.location.pathname } }}
            primaryText="My Wallet"
            leftIcon={<AccountBalanceWalletIcon />}
          />}
        {permissions && !permissions.includes('devpartner') &&
          <MenuItemLink
            // Render "My Profile", if we have a valid "id"
            to={{ pathname: `/users/${userId}`, hash: '#my-profile', state: { prevPath: history.location.pathname } }}
            primaryText="My Profile"
            leftIcon={<FaceIcon />}
          />}
      </UserMenu>
      <Route
        render={
          // render "dialog" only if we're at this "switch-program"
          ({ location }) => ((location.hash === `#switch-program`) 
            && <DialogSwitchProgram
                history={history}
                userPrograms={userPrograms}
              />
        )}
      />
    </>
  );
};
