import React from 'react';
import PropTypes from 'prop-types';
import { CreateButton, ExportButton, sanitizeListRestProps, TopToolbar } from 'react-admin';
import ContentAdd from '@material-ui/icons/Add';

import { BackButton as Button } from './buttons/BackButton';

export const ListActions = props => {
  const {
    currentSort,
    className,
    label,
    resource,
    filters,
    displayedFilters,
    exporter,         // you can hide ExportButton if exporter = (null || false)
    filterValues,
    permanentFilter,
    hasCreate,        // you can hide CreateButton if hasCreate = false
    basePath,
    selectedIds,
    onUnselectItems,
    showFilter,
    maxResults,
    total,
    handleCreate,     // loads a dialog to "create" e.g loans
    customCreate,     // show custom "create" button e.g Register loans
    superuser,        // role -> show ExportButton
    hasBack,          // show BackButton (used in <RequestBulkList>)
    handleBack,       // go back
    ...rest
  } = props;

  const createProps = {
    handleBack: handleCreate,
    classname: 'not-colour',
    icon: createIcon,
    label: label
  };

  return React.useMemo(
    () => (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {hasBack && typeof(handleBack) === 'function' && <Button handleBack={handleBack} />}
        {customCreate && typeof(handleCreate) === 'function' && <Button {...createProps} />}
        {hasCreate && <CreateButton basePath={basePath} label={label} />}
        {/* add !exporter to hide the "add filters" bulk action */}
        {filters && React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
        {/* only show "export" button to "superuser" */}
        {exporter !== false && superuser && (
          <>
            <ExportButton
              disabled={total === 0}
              resource={resource}
              sort={currentSort}
              filter={{ ...filterValues, ...permanentFilter }}
              exporter={exporter}
              maxResults={maxResults}
            />
            {/* <div {...getRootProps()}>
                <input {...getInputProps()} />
                {
                  isDragActive ?
                    <p>Drop the files here ...</p> :
                    <p>Drag 'n' drop some files here, or click to select files</p>
                }
            </div> */}
          </>
        )}
      </TopToolbar>
    ),
    [resource, displayedFilters, filterValues, selectedIds, filters, maxResults, total] // eslint-disable-line react-hooks/exhaustive-deps
  );
};

const createIcon = <ContentAdd />;

ListActions.propTypes = {
  basePath: PropTypes.string,
  className: PropTypes.string,
  currentSort: PropTypes.object,
  label: PropTypes.string,
  displayedFilters: PropTypes.object,
  exporter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  filters: PropTypes.element,
  filterValues: PropTypes.object,
  hasCreate: PropTypes.bool,
  resource: PropTypes.string,
  onUnselectItems: PropTypes.func.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.any),
  showFilter: PropTypes.func,
  total: PropTypes.number,
};

ListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
  label: 'Register',
};