import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-admin';
import clsx from 'clsx';
import {
  IconButton,
  Snackbar,
  SnackbarContent,
  withStyles,
} from '@material-ui/core';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';

import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import grey from '@material-ui/core/colors/grey';

interface Bar {
  open?: boolean;
}

export const UpdatesBar = (props: any) => {
  const [update, setUpdate] = useState<Bar>({
    open: true,
  });

  const handleClose = () => {
    setUpdate({ open: false });
  };

  const { open } = update;
  const { cardMessage, link, show } = props;

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={show && open}
        onClose={handleClose}
        className={clsx('UpdatesBar', props.className)}
      >
        <AbSnackbarContentWrapper
          link={link}
          onClose={handleClose}
          variant="info"
          message={cardMessage}
        />
      </Snackbar>
    </div>
  );
};

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: grey[900],
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  content: {
    padding: '2px 6px',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 11,
    color: '#ccc',
  },
});

const AbSnackbarContent = (props: any) => {
  const {
    classes, 
    className, 
    link = '',
    message, 
    onClose, 
    variant, 
    ...other 
  } = props;

  const Icon = variantIcon[variant];
  const defaultIcon = (
    <Icon className={clsx(classes.icon, classes.iconVariant)} />
  );
  const history = useHistory();

  const handleClick = useCallback(() => {
    history.push(link);
  }, [history, link]);

  return (
    <SnackbarContent
      className={clsx(classes[variant], classes.content, className)}
      aria-describedby={!link ? 'client-snackbar' : ''}
      message={
        !link ? (
          <span
            id="client-snackbar"
            style={{ textTransform: 'uppercase' }}
            className={classes.message}
          >
            {defaultIcon}
            {message}
          </span>
        ) : (
          <Button
            label={message}
            onClick={handleClick}
            className={clsx(classes.message)}
          >
            {defaultIcon}
          </Button>
        )
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
};

AbSnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  link: PropTypes.string,
  message: PropTypes.any,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const AbSnackbarContentWrapper = withStyles(styles1)(AbSnackbarContent);
