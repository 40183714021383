import { AuthProvider } from 'ra-core';
import { BASE_URL, apiFullCall, createCookie, eraseCookie } from './modules';

interface Obj {
  [key: string | number ]: any;
}

export const setPermissions = ( 
  user: Obj, 
  token: string,
  user_program?: Obj, 
  pos?: Obj,
  provider?: Obj, 
  user_programs?: Obj, 
  cooperative_branch?: Obj ,
) => {

  let permissions: Array<string> = [];
  let userPrograms: Array<string> = [];
  
  if (!!user) {

    const { user_system_role } = user;
    // populate the permissions array
    user_system_role && user_system_role.map(
      value => permissions.push(value.key)
    );

    // General key/values
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('id', user.id);
    sessionStorage.setItem('lastName', user.last_name);
    sessionStorage.setItem('firstName', user.first_name);
    sessionStorage.setItem('middleName', user.middle_name);
    sessionStorage.setItem('permissions', JSON.stringify(permissions));
  };

  // if "pos" exists, save username + token:
  if (!!pos) {
    const { user: posUser, token: posToken } = pos;      
    const { username: posUsername } = posUser;

    sessionStorage.setItem('posUsername', posUsername);
    sessionStorage.setItem('posToken', posToken);
  };

  // Related to provider's services
  sessionStorage.setItem('hasServices', 'false');

  if (!!user_programs) {
    const { results } = user_programs;
    // User's program, if logged-in directly
    if(results && results.length !== 0){
      sessionStorage.setItem('userProgram', results[0]['program_name'])
      sessionStorage.setItem('userPrograms', JSON.stringify(results))
      sessionStorage.setItem('userProgramId', results[0]['id'])
    };
  };

  if (!!user_program) {
    user_program && user_program.map(value => userPrograms.push(value));   
    // User's program, if logged-in via POS
    if(user_program.length !== 0){
      sessionStorage.setItem('userProgram', userPrograms[0]['program_name'])
      sessionStorage.setItem('userPrograms', JSON.stringify(userPrograms))
      sessionStorage.setItem('userProgramId', userPrograms[0]['program'])
    };
  };

  // check if provider object is not empty
  if (!!provider) {
    let isProvider = Object.prototype.hasOwnProperty.call(provider, 'id');

    if (isProvider) {
      const { providerid, providerlocationid } = provider;
  
      sessionStorage.setItem('providerId', providerid);
      sessionStorage.setItem('providerlocationid', providerlocationid);
    };
  };

  // check if Group admin
  if (cooperative_branch && cooperative_branch.results && cooperative_branch.results[0]) {
    sessionStorage.setItem('saccoId', cooperative_branch.results[0].id);
    sessionStorage.setItem('saccoName', cooperative_branch.results[0].sacco_name);
  };

  return null;

};

const authProvider: AuthProvider = {

  login: ({ username, password }) => {
    const url = BASE_URL + '/token-auth/';

    const request = new Request(url, {
      method: 'POST',
      body: `username=${username}&password=${password}`,
      headers: new Headers({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }),
    });

    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error('Wrong username or password. Try again!');
        }
        return response.json();
      })
      .then(({ token, pos, user, user_program, user_programs, provider, cooperative_branch }) => {
        setPermissions(
          user,
          token,
          user_program,
          pos,
          provider,
          user_programs,
          cooperative_branch
        );
      })
      .then(() => {
        const posUsername = sessionStorage.getItem('posUsername');
        const posToken = sessionStorage.getItem('posToken');
        const akProgramId = sessionStorage.getItem('userProgramId');
        const akProgramToken = sessionStorage.getItem('token');

        // create a cookie
        createCookie('_ak2pos_', `${posUsername}%${posToken}`, 7)
        createCookie('_ak2data_', `${akProgramId}%${akProgramToken}`, 7)
      });
  },

  logout: () => {
    eraseCookie('_ak2pos_');
    eraseCookie('_pos2ak_');
    eraseCookie('_ak2data_');
    sessionStorage.clear(); // clear sessionstorage

    return Promise.resolve();
  },

  checkError: error => {
    const status = error.status;
    // We assert that permissions isn't 'null' (@typescript)
    let permissions = JSON.parse(sessionStorage.getItem('permissions')!);

    if (status === 401 || status === 403) {
      if (permissions !== undefined) {
        return Promise.resolve();
      }
      sessionStorage.clear();
      return Promise.reject();
    }
    return Promise.resolve();
  },

  checkAuth: (params) => {
    const { posCookie, userIsVerified } = params;
    const authToken = sessionStorage.getItem('token');

    // if not-verified and we have a cookie
    if (!Boolean(userIsVerified) && posCookie){

      sessionStorage.setItem('userIsVerified', 'true');
      const credentials = posCookie.split("%")
      const username = credentials[0];
      const token = credentials[1];

      return apiFullCall('', token, 'get', `users/?username=${username}`)
        .then(res => {

          const { status, body } = res;

          if (status === 200 || status === 201) {  

            const { id, first_name, last_name, middle_name, user_system_role, user_program } = body.results[0];
            const user = {
              id: id,
              first_name: first_name,
              last_name: last_name,
              middle_name: middle_name,
              user_system_role: user_system_role,
            };
  
            setPermissions(user, token, user_program);
            console.log('And, you\'re now verified.')

            return ( Promise.resolve(), window.location.reload() );
  
          };
          
        });

    } else if (!!authToken) {

      return Promise.resolve();
    } else {

      return Promise.reject();
    };
  },

  getPermissions: () => {
    // We assert that permissions isn't 'null' (@typescript)
    let permissions = JSON.parse(sessionStorage.getItem('permissions')!);

    return permissions
      ? Promise.resolve(permissions)
      : Promise.reject('Unknown method');
  },
};

export default authProvider;
