import React from 'react';
import { useRefresh } from 'react-admin';
import {
  Button,
  Dialog,
  Divider,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SaveIcon from '@material-ui/icons/Save';

import apiCall from '../apiHelper';
import { requestStyles } from '../stylesheets';

export const AddServices = () => {
  const [open, setOpen] = React.useState(false);
  const [categories, setCategories] = React.useState({
    current: '',
    list: [],
  });
  const [subCategories, setSubCategories] = React.useState({
    current: '',
    list: [],
  });
  const [services, setServices] = React.useState({
    current: '',
    list: [],
    selected: [],
  });
  const classes = requestStyles();
  const refresh = useRefresh();

  const closeAddServicesDialog = () => {
    setOpen(false);
  };

  const addServiceDialog = () => {
    setOpen(true);
  };

  const selectServiceCateogry = event => {
    event.preventDefault();
    setCategories({
      ...categories,
      current: event.target.value,
    });
    apiCall(
      '',
      sessionStorage.getItem('token'),
      'get',
      'subcategories/?categoryid=' + event.target.value
    )
      .then(response => {
        setSubCategories({
          current:
            response['results'].length > 0 ? response['results'][0].id : '',
          list: response.results,
        });
        apiCall(
          '',
          sessionStorage.getItem('token'),
          'get',
          'services/?subcategoryid=' + response['results'][0].id
        )
          .then(response => {
            setServices({
              ...services,
              current:
                response['results'].length > 0 ? response['results'][0].id : '',
              list: response.results,
            });
          })
          .catch(error => error);
      })
      .catch(error => error);
  };

  const selectServiceSubCateogry = event => {
    event.preventDefault();
    setSubCategories({
      ...subCategories,
      current: event.target.value,
    });
    apiCall(
      '',
      sessionStorage.getItem('token'),
      'get',
      'services/?subcategoryid=' + event.target.value
    )
      .then(response => {
        setServices({
          ...services,
          current:
            response['results'].length > 0 ? response['results'][0].id : '',
          list: response.results,
        });
      })
      .catch(error => error);
  };

  const selectSelectService = event => {
    event.preventDefault();
    setServices({
      ...services,
      current: event.target.value,
    });
  };

  const initialiseCategories = () => {
    apiCall('', sessionStorage.getItem('token'), 'get', 'categories/')
      .then(response => {
        if (response.results.length > 0) {
          setCategories({
            current:
              response['results'].length > 0 ? response['results'][0].id : '',
            list: response.results,
          });
          apiCall(
            '',
            sessionStorage.getItem('token'),
            'get',
            'subcategories/?categoryid=' + response.results[0].id
          )
            .then(response => {
              if (response['results'].length > 0) {
                setSubCategories({
                  current:
                    response['results'].length > 0
                      ? response['results'][0].id
                      : '',
                  list: response.results,
                });
                apiCall(
                  '',
                  sessionStorage.getItem('token'),
                  'get',
                  'services/?subcategoryid=' + response.results[0].id
                )
                  .then(response => {
                    setServices({
                      ...services,
                      current:
                        response['results'].length > 0
                          ? response['results'][0].id
                          : '',
                      list: response.results,
                    });
                  })
                  .catch(error => error);
              }
            })
            .catch(error => error);
        }
      })
      .catch(error => error);
  };

  React.useEffect(initialiseCategories, [services]);

  const addService = event => {
    event.preventDefault();
    let service = services.current;
    if (!alreadyAdded(service, services['selected'])) {
      let days = document.getElementById('days_payable').value;
      for (let i = 0; i < services['list'].length; i++) {
        if (services['list'][i].id === service) {
          let newService = services['list'][i];
          newService['days_payable'] = days;
          let newServices = services.selected;
          newServices.push(newService);

          setServices({
            ...services,
            selected: newServices,
          });
          break;
        }
      }
    }
  };

  function alreadyAdded(service, selected) {
    let exists = false;
    for (let c = 0; c < selected.length; c++) {
      if (selected[c].id === service) {
        exists = true;
        break;
      }
    }

    return exists;
  }

  const saveSelectedServices = event => {
    event.preventDefault();
    for (let i = 0; i < services['selected'].length; i++) {
      let data = {
        is_active: true,
        service_id: services['selected'][i].id,
      };
      apiCall(
        data,
        sessionStorage.getItem('token'),
        'post',
        'cooperativebranchservices/'
      )
        .then(response => {
          // Process response
        })
        .catch(error => error);
    }
    setOpen(false);
    refresh();
  };

  const removeSelection = serviceid => {
    const newList = [];
    for (let i = 0; i < services['selected'].length; i++) {
      if (services['selected'][i].id !== serviceid) {
        newList.push(services['selected'][i]);
      }
    }
    setServices({
      ...services,
      selected: newList,
    });
  };

  return (
    <>
      <Button
        className={classes.addServiceButton}
        variant="outlined"
        color="primary"
        onClick={addServiceDialog}
      >
        <AddIcon /> Add Service
      </Button>
      <Dialog
        maxWidth="lg"
        open={open}
        onClose={closeAddServicesDialog}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={closeAddServicesDialog}
          className={classes.requestDialog}
        >
          Add Sacco Services.
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={closeAddServicesDialog}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.addServiceDialog} dividers>
          <div className={classes.serviceColumn}>
            <h2>Setup Services</h2>
            <div className={classes.formColumFullWidth}>
              <FormControl className={classes.formInput} variant="outlined">
                <InputLabel required id="category-label">
                  Select Category
                </InputLabel>
                <Select
                  labelId="category-label"
                  id="category"
                  value={categories.current}
                  label="Select Category"
                  onChange={selectServiceCateogry}
                >
                  {categories['list'].map((value, index) => (
                    <MenuItem value={value.id}>{value.category_name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <Divider className={classes.dividerSuccess} />
            <div className={classes.formColumFullWidth}>
              <FormControl className={classes.formInput} variant="outlined">
                <InputLabel required id="subcategory-label">
                  Select Subcategory
                </InputLabel>
                <Select
                  labelId="subcategory-label"
                  id="subcategory"
                  label="Select Subcategory"
                  value={subCategories.current}
                  onChange={selectServiceSubCateogry}
                >
                  {subCategories['list'].map((value, index) => (
                    <MenuItem value={value.id}>
                      {value.sub_category_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <Divider className={classes.dividerSuccess} />
            <div className={classes.formColumFullWidth}>
              <FormControl className={classes.formInput} variant="outlined">
                <InputLabel required id="service-label">
                  Select Service
                </InputLabel>
                <Select
                  labelId="service-label"
                  id="service_field"
                  label="Select Service"
                  value={services.current}
                  onChange={selectSelectService}
                >
                  {services['list'].map((value, index) => (
                    <MenuItem value={value.id}>{value.service_name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <Divider className={classes.dividerSuccess} />
            <div className={classes.formColumFullWidth}>
              <TextField
                id="days_payable"
                label="Days Payable"
                variant="outlined"
                type="number"
                className={classes.formInput}
              />
            </div>
            <Divider className={classes.dividerSuccess} />
            <Button
              className={classes.buttonAddService}
              variant="outlined"
              color="primary"
              onClick={addService}
            >
              <AddIcon /> Add Service
            </Button>
          </div>
          <div className={classes.serviceList}>
            <h2>Selected Services</h2>
            <TableContainer component={Paper}>
              <Table
                className={classes.saccoServiceTable}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.saccoServiceTableCell}>
                      Service
                    </TableCell>
                    <TableCell className={classes.saccoServiceTableCell}>
                      Type
                    </TableCell>
                    <TableCell className={classes.saccoServiceTableCell}>
                      Measure
                    </TableCell>
                    <TableCell className={classes.saccoServiceTableCell}>
                      Days Payable
                    </TableCell>
                    <TableCell
                      className={classes.saccoServiceTableCell}
                      align="center"
                    >
                      <DeleteForeverIcon />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {services['selected'].map((value, index) => (
                    <TableRow className={classes.saccoServiceTableRow}>
                      <TableCell>{value.service_name}</TableCell>
                      <TableCell>{value.service_type}</TableCell>
                      <TableCell>{value.unit_of_measure}</TableCell>
                      <TableCell>{value.days_payable}</TableCell>
                      <TableCell align="center">
                        <IconButton
                          aria-label="close"
                          onClick={() => removeSelection(value.id)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Button
              className={classes.buttonSaveServices}
              variant="outlined"
              color="primary"
              onClick={saveSelectedServices}
            >
              <SaveIcon /> Save Services
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
