import React from 'react';
import { Redirect } from 'react-router';
import { Create, useNotify } from 'react-admin';
import { Route, Switch, useHistory } from 'react-router-dom';

import { FetchProgress } from 'layout';
import * as P from 'allPermissions';

const UserFormBasic = React.lazy(() => import("../userforms/UserFormBasic"));

const UserCreate = (props: any) => {
  const history = useHistory();
  const notify = useNotify();

  const { basePath, permissions } = props;
  
  const addOnlyFarmers = P.authCreateFarmers(permissions);
  const addOnlyMembers = P.authCreateSaccoMembers(permissions);
  const addCoordinatorsAndAccountants = P.authCreateCoordinatorsAndAccountants(permissions);
  const addFarmersAgentsAccountants = P.authCreateFarmerAgentsAndAccountants(permissions);

  const handleClose = React.useCallback(() => {
    history.push(basePath);
  }, [history, basePath]);
  
  // Enable HTML5 Geolocation API
  if (navigator.geolocation) {

    navigator.geolocation.getCurrentPosition(
      // success
      position => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        
        const location = { latitude: lat, longitude: lng };

        // Add the "coordinated" to session (picked when saving a user's location)
        sessionStorage.setItem('geolocation', JSON.stringify(location));

        console.log(`latitude: ${ lat } | longitude: ${ lng }`);
        notify(`Confirming location...`, 'info');
      },
      // failure
      error => {
        console.error(`Error(${error.code}): ${error.message}`)
        notify(`No location...`, 'warning');
      },
      // options
      {
        enableHighAccuracy: true,
        maximumAge: 30000,
        timeout: 27000
      }
    );

  };

  if (!permissions) { // if no permissions
    return null;
  };

  const moreProps = {
    onCancel: handleClose,
    addFAA: addFarmersAgentsAccountants,
    ...props,
  };

  /* passed to coordinator/accountant related */
  const otherProps = {
    addCA: addCoordinatorsAndAccountants,
    ...moreProps
  };

  /* passed to sacco-related */
  const memberProps = {
    addOM: addOnlyMembers,
    ...moreProps
  };

  return (
    <React.Suspense fallback={<FetchProgress title="Loading..." top={true} /> }>
      {addOnlyFarmers
        ? <NewFarmer {...moreProps} />
        : addFarmersAgentsAccountants 
          ? <Switch>
              <Route exact path="/users/create">
                <NewFarmer {...moreProps} />
              </Route>
              <Route path="/agents">
                <NewAgent {...moreProps} />
              </Route>
              <Route path="/accountants">
                <NewAccountant {...moreProps} /> {/* different props */}
              </Route> 
            </Switch>
          : addCoordinatorsAndAccountants
            ? <Switch>
                <Route path="/coordinators">
                  <NewCoordinator {...otherProps} />
                </Route>  
                <Route path="/accountants">
                  <NewAccountant {...otherProps} /> {/* different props */}
                </Route>      
              </Switch>
            : addOnlyMembers
              ? <Route path="/members">
                  <NewMember {...memberProps} />
                </Route>      
              : <Redirect to="/" />}
    </React.Suspense>
  )
};

const NewCoordinator = (props: any) => {

  const { addCA, onCancel, addFAA, ...other } = props;
  const { basePath, hasCreate, hasEdit, hasList, hasShow, permissions, ...rest } = other;

  const moreProps = {
    onCancel: onCancel,        // used to close the forms
    addFAA: addFAA,            // used in "toolbar" logic
    addCA: addCA,              // Also used in the "toolbar" logic
    ...rest,
  };

  return (
    <Create title="Coordinator Registration" {...other}>
      <UserFormBasic {...moreProps} />
    </Create>
  );
};

const NewAccountant = (props: any) => {

  const { addCA, onCancel, addFAA, ...other } = props;
  const { basePath, hasCreate, hasEdit, hasList, hasShow, permissions, ...rest } = other;

  const moreProps = {
    onCancel: onCancel,         // used to close the forms
    addFAA: addFAA,             // used in "toolbar" logic
    addCA: addCA,               // Also used in the "toolbar" logic
    ...rest,
  };

  return (
    <Create title="Accountant Registration" {...other}>
      <UserFormBasic {...moreProps} />
    </Create>
  );
};

const NewMember = (props: any) => {

  const { onCancel, addFAA, addOM, ...other } = props;
  const { basePath, hasCreate, hasEdit, hasList, hasShow, permissions, ...rest } = other;

  const moreProps = {
    onCancel: onCancel,         // used to close the forms
    addFAA: addFAA,             // used in "toolbar" logic
    addOM: addOM,               // Also used in the "toolbar" logic
    ...rest,
  };

  return (
    <Create title="Member Registration" {...other}>
      <UserFormBasic {...moreProps} />
    </Create>
  );
};

const NewAgent = (props: any) => {

  const { onCancel, addFAA, ...other } = props;
  const { basePath, hasCreate, hasEdit, hasList, hasShow, permissions, ...rest } = other;

  const moreProps = {
    onCancel: onCancel,     // used to close the forms
    addFAA: addFAA,         // used in "toolbar" logic
    ...rest,
  };

  return (
    <Create title="Agent Registration" {...other}>
      <UserFormBasic {...moreProps} />
    </Create>
  );
};

const NewFarmer = (props: any) => {

  const { onCancel, addFAA, ...other } = props;
  const { basePath, hasCreate, hasEdit, hasList, hasShow, permissions, ...rest } = other;

  const { resource } = rest;

  const isFarmer: boolean = resource === "users" ? true : false;

  const moreProps = {
    isFarmer: isFarmer,     // user type
    onCancel: onCancel,     // used to close the forms
    addFAA: addFAA,         // used in "toolbar" logic
    ...rest,
  };

  return (
    <Create title="Farmer Registration" {...other}>
      <UserFormBasic {...moreProps} /> 
    </Create>
  );
};

export default UserCreate;
