/*
 * App permissions
 * 
 * Boilerplate for handling permissions
 * @returns boolean
 */

const Perms = (permissions, userRole?) => permissions && permissions.includes(userRole);

export const programmanager = permissions => Perms(permissions, 'programmanager');
export const stakeholder = permissions => Perms(permissions, 'stakeholder');
export const accountant = permissions => Perms(permissions, 'accountant');
export const superagent = permissions => Perms(permissions, 'superagent');
export const cordinator = permissions => Perms(permissions, 'cordinator');
export const devpartner = permissions => Perms(permissions, 'devpartner');
export const superuser = permissions => Perms(permissions, 'superuser');
export const provider = permissions => Perms(permissions, 'provider');
export const onefarm = permissions => Perms(permissions, 'onefarm');
export const farmer = permissions => Perms(permissions, 'farmer');
export const admin = permissions => Perms(permissions, 'admin');
export const agent = permissions => Perms(permissions, 'agent');


/**
 * Authorized to view LISTS 
 */


/* Requests */


export const accessRequestsMenu = permissions =>
	superagent(permissions) ||
	provider(permissions) ||
	onefarm(permissions) ||
	admin(permissions); 


// Only view aggregated requests

export const authAggregatedReq = permissions =>
	onefarm(permissions) ||
	admin(permissions); 


/* Stock */


export const accessStockMenu = permissions =>
	programmanager(permissions) || 
	cordinator(permissions) ||  
	superagent(permissions) ||
	superuser(permissions) || 
	admin(permissions);


/**
 * Authorized to CREATE stock 
 */


// Can create 'stock' product
export const authCreateStock = permissions => 
	superagent(permissions) ||
	admin(permissions);



/* Users */


// all users
export const accessUserMenu = permissions =>
	programmanager(permissions) || 
	cordinator(permissions) ||  
	superagent(permissions) || 
	admin(permissions) || 
	agent(permissions) || 
	superuser(permissions); 


// coordinators
export const accessCoordinatorMenu = permissions => 
	programmanager(permissions); 


// Superagents
export const accessSuperagentMenu = permissions => 
	programmanager(permissions) || 
	cordinator(permissions) ||
	superuser(permissions); 


// Accoutants
export const accessAccountantsMenu = permissions => 
	programmanager(permissions) ||
	superagent(permissions) ||
	superuser(permissions); 


// Agents
export const accessAgentMenu = permissions => 
	programmanager(permissions) || 
	cordinator(permissions) ||
	superagent(permissions) ||
	superuser(permissions); 


// Farmers
export const accessFarmerMenu = permissions => 
	programmanager(permissions) || 
	cordinator(permissions) ||
	superagent(permissions) ||
	superuser(permissions) || 
	agent(permissions); 


// Service providers
export const accessProviderMenu = permissions => 
	programmanager(permissions) || 
	cordinator(permissions) ||
	superagent(permissions) ||
	superuser(permissions) || 
	agent(permissions);


// Sacco-members -> sidebar menu
export const accessSaccoMembersMenu = admin;


// see "registered by" fields
export const superagentRegisteredBy = accessSuperagentMenu;
export const farmerRegisteredBy = accessAgentMenu;
export const agentRegisteredBy = accessAgentMenu;
export const providerRegisteredBy = accessAgentMenu;


/* Groups */


export const accessGroupMenu = permissions =>
	programmanager(permissions) || 
	cordinator(permissions) ||
	superagent(permissions) ||
	superuser(permissions) || 
	agent(permissions);


/* Sacco members */


// access the 'members' list
export const accessSaccoMembers = permissions =>
	programmanager(permissions) ||
	cordinator(permissions) ||
	superuser(permissions) || 
	admin(permissions);



/* Loans */


// access the 'loans' list
export const accessLoansMenu = permissions =>
	programmanager(permissions) ||
	accountant(permissions) || 
	superagent(permissions) || 
	superuser(permissions);


/* Reports */


export const accessReportsMenu = permissions =>
	programmanager(permissions) || 
	stakeholder(permissions) ||
	cordinator(permissions) ||
	superagent(permissions) ||
	superuser(permissions);



/* Service */


export const accessServicesMenu = permissions =>
	provider(permissions) || 
	superuser(permissions) ||
	admin(permissions);


/* My details */


export const accessMyDetailsMenu = permissions =>
	superagent(permissions) ||
	provider(permissions);


/**
 * Authorized to view Stats dashboard 
 */


export const accessStatsDashboard = permissions =>
	programmanager(permissions) || 
	stakeholder(permissions) ||
	cordinator(permissions) ||
	superagent(permissions) ||
	superuser(permissions);



/**
 * NOT Authorized to CREATE 
 */


// Cannot create farmers
export const cantAddFarmers = permissions => 
	programmanager(permissions) || 
	cordinator(permissions);


// Cannot create/edit providers 
export const cantAddOrEditproviders = permissions => 
	programmanager(permissions) || 
	cordinator(permissions);


// Cannot create/edit agents	
export const cantAddOrEditAgents = permissions =>
	programmanager(permissions) ||
	cordinator(permissions) ||
	superuser(permissions); 


// Cannot create/edit superagents
export const cantAddOrEditSuperagents = permissions => null;
	// programmanager(permissions);


// Cannot create/edit groups
export const accessDeniedSAG = permissions => 
	programmanager(permissions);


// Cannot create coordinators
export const accessDeniedCD = permissions => 
	cordinator(permissions);


/**
 * NOT Authorized to EDIT 
 */

// Cannot edit farmers
export const cantEditFarmers = permissions => 
	programmanager(permissions) || 
	cordinator(permissions);


/**
 * Authorized to CREATE users 
 */


// Only create farmers
export const authCreateFarmers = permissions =>
	superuser(permissions) || 
	agent(permissions);


// Only create farmers and agents
export const authCreateFarmerAndAgents = permissions =>
	superagent(permissions);


// Only create super-agents
export const authCreateSuperagents = permissions =>
	programmanager(permissions) || // allow PM to create SA
	cordinator(permissions);
	

// Only create coordinators
export const authCreateCoordinators = permissions =>
	programmanager(permissions);


// Only create accountants
export const authCreateAccountants = permissions =>
	programmanager(permissions) || // allow PM to create Acc
	superagent(permissions);


// Only create coordinators and accountants
export const authCreateCoordinatorsAndAccountants = permissions =>
	programmanager(permissions);


// Only create farmers, agents and accountants
export const authCreateFarmerAgentsAndAccountants = permissions =>
	superagent(permissions);


// Can create providers
export const authCreateProviders = permissions =>
	superagent(permissions) ||
	agent(permissions);


// "superuser" can create provider and superagents	
export const authCreateProSup = superuser;


/**
 * Authorized to EDIT users 
 */


// Only edit farmers
export const authEditFarmers = permissions =>
	superuser(permissions) ||	
	agent(permissions);



/**
 * Authorized to CREATE groups 
 */

// Only create groups
export const authCreateGroups = permissions =>
	superagent(permissions) ||
	superuser(permissions) ||
	agent(permissions);


/**
 * Authorized to EDIT groups 
 */

// Only create groups
export const authEditGroups = permissions =>
	superagent(permissions) ||
	superuser(permissions);



/**
 * Authorized to CREATE sacco-members 
 */



// "admin" can create sacco-members	
export const authCreateSaccoMembers = admin;


export const cantAddSaccoMembers = permissions =>
	programmanager(permissions) || 
	stakeholder(permissions) ||
	cordinator(permissions) ||
	superagent(permissions) ||
	superuser(permissions) ||
	agent(permissions);


export const cantEditSaccoMembers = permissions =>
	programmanager(permissions) || 
	stakeholder(permissions) ||
	cordinator(permissions) ||
	superagent(permissions) ||
	superuser(permissions) ||
	agent(permissions);



/**
 * Authorized to CREATE loan-applications 
 */


// Can create 'loans' application
export const authCreateLoanApps = permissions =>
	accountant(permissions);


/* Authorized to view POS */


export const accessPosMenu = permissions =>
	superuser(permissions) ||
	provider(permissions);
