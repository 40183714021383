import React, { FC } from 'react';

import { FieldProps, User } from 'types';

const FullNameField: FC<FieldProps<User>> = ({ record }) => {

	if (!record) {
		return null

	} else {
		const { first_name, middle_name, last_name } = record;
		const fullNames = !!middle_name ? `${last_name} ${first_name} ${middle_name}` : `${last_name} ${first_name}`;

		return <span className="font-sm">{fullNames}</span>
	};
};

const PureFullNameField = FullNameField;

PureFullNameField.defaultProps = {
	source: 'last_name',
	label: 'pos.menu.providers.name'
};

export default PureFullNameField;