import React from 'react';
import PropTypes from 'prop-types';
import { DescriptionSharp } from '@material-ui/icons';
import { ApproveButton } from './ApproveButton';

export const DownloadButton = ({
	icon = defaultIcon,
	label = 'Download',
	handleClick = (f) =>f,
	...props
}) => {
  return (
    <ApproveButton
    	icon={icon}
    	label={label}
    	handleClick={handleClick}
    	{...props}
    />
  );
}

const defaultIcon = <DescriptionSharp />;

DownloadButton.propTypes = {
	icon: PropTypes.element,
	label: PropTypes.string,
	handleClick: PropTypes.func
};
