import React from 'react';
import { useTranslate } from 'react-admin';
import Box from '@material-ui/core/Box';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import { CardWithIcon } from 'layout';
import { cardStyles } from 'stylesheets/jss/';

import apiCall from 'modules/apiHelper';

interface Props {
  value?: number;
}

interface CountStats { // eslint-disable-line
  users?: number;
  farmers?: number;
  providers?: number;
  sumfarmerRequests?: number;
  unapprovedProviderRequests?: number;
  anyUpdates?: boolean;
}

export const MonthlyRevenue: React.FC<Props> = ({ value }) => {
  const classes = cardStyles();
  const translate = useTranslate();

  const token = sessionStorage.getItem('token');
  const saccoName = sessionStorage.getItem('saccoName');         // eslint-disable-line
  const [coopBranches, setCoopBranches] = React.useState([       // eslint-disable-line
    { id: 0, cooperative_branch_name: '', monthly_income: '' },
  ]);

  React.useEffect(() => {

    const handleFetchCoopBranches = (pageNumber, results) => (

      apiCall(
        '',
        token,
        'get',
        'cooperativebranches/?page=' + pageNumber
      ).then(response => {

          response.results.map(
            (coop, index) => results.push(coop)
          );

          if (response.next !== null) {
            pageNumber = parseInt(pageNumber) + 1;
            handleFetchCoopBranches(pageNumber, results);
          } else {
            setCoopBranches(results);
          };

      }).catch(
        error => console.error('Error while fetching:', error)
      )

    );

    apiCall(
      '',
      token, 
      'get', 
      'cooperativebranches/'
    ).then(response => {
        
      if (response.next !== null) {
        handleFetchCoopBranches(2, response.results);
      };

    }).catch(
      error => console.error('Error while fetching:', error)
    );

  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  // const monthlyIncome = coopBranches && coopBranches.map(
  //   branch => (
  //     branch.cooperative_branch_name === saccoName ? branch.monthly_income : 0
  //   )
  // );

  const monthlyIncome = 0;

  return (
    <Box flex={1} className={classes.right}>
      <CardWithIcon
        subtitle={`${monthlyIncome}`} 
        title={translate('pos.dashboard.monthly_revenue')} 
        icon={MonetizationOnIcon} 
      />
    </Box>
  );
};
