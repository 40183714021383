import serviceproviders from './serviceproviders';
import transactions from './transactions';
import farmers from './farmers';

const reports = {
  farmers: farmers,
  transactions: transactions,
  serviceproviders: serviceproviders,
};

export default reports;
