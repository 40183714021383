import React from 'react';
import { CreditOrderAggregateToolbar as CreditAT } from '../creditorders/CreditOrderAggregate';


/**
 * For use within the RequestAggregateForm "toolbar" 
 */

export const Aggregate = (props: any) => {

  const { resource } = props;

  // credit-orders
  const creditOrders = resource === 'credit-orders';

  return creditOrders ? (
    /* aggregate CREDIT orders */ <CreditAT {...props} />
  ) : null;
};
