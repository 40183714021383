import ProviderIcon from '@material-ui/icons/PeopleOutline';
import ProviderReport from './ProviderReport';
import ProviderReportList from './providerReportList';

const serviceproviders = {
  icon: ProviderIcon,
  list: ProviderReport,
  detail: ProviderReportList,
};

export default serviceproviders;