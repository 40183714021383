import React from 'react';
import { Redirect } from 'react-router';
import { Route, Switch } from 'react-router-dom';

import { Edit } from 'react-admin';
import { UserProfile } from 'modules/users/profile';
import { apiFullCall } from 'modules/apiHelper';
import { FetchProgress } from 'layout';

import { trim } from 'modules/utils';
import { User, FieldProps } from 'types';
import { FullNameField } from '../userfields';

import * as P from 'allPermissions';

const UserFormBasic = React.lazy(() => import("../userforms/UserFormBasic"));

const UserEdit = props => {

  const { permissions } = props;

  const superuser = P.superuser(permissions);
  const onlyEditFarmers = P.authEditFarmers(permissions); /* removed agents */
  const addOnlyMembers = P.authCreateSaccoMembers(permissions);
  const addFarmersAgentsAccountants = P.authCreateFarmerAgentsAndAccountants(permissions);
  const addCoordinatorsAndAccountants = P.authCreateCoordinatorsAndAccountants(permissions);
  
  let userId = sessionStorage.getItem('id');

  const UserIsPermitted = permissions && (
    addCoordinatorsAndAccountants ||
    addFarmersAgentsAccountants ||
    onlyEditFarmers || 
    addOnlyMembers
  );
 
  /* related to adding users to a group */ 

  const token = sessionStorage.getItem('token');
  const [state, setState] = React.useState<object | any>({ groupList: [] });
  const [userRole, setUserRole] = React.useState<object | any>({ role: '' });

  const [branchDetails, setBranchDetails] = React.useState<object | any>({
    id: '', cooperative_branch_name: '', village_id: '',
  });  

  const [filterValue, setFilterValue] = React.useState<object | any>({
    id: '',
    villagename: '',
    parishname: '',
    subcountyname: '',
    countyname: '',
    districtname: '',
    regionname: '',
  }); 

  // onChange method for the input field
  const inputOnChangeCooperative = React.useCallback((e, id?: string | number) => {
    if (!e.target.value) {
      return null;
    }

    searchCooperative(e.target.value); // pass districtid
  }, []); // eslint-disable-line
  
  const searchCooperative = (value) => {
    let cooperative = trim(value);

    if (cooperative.length > 2) {

      apiFullCall('', token, 'get', `cooperativebranches/?search=${cooperative}`)
      .then(res => {
        if (res) {
          // destructure response
          const { status, body } = res;

          if (status === 200 || status === 201) {

            setState(prevState => ({ ...prevState, groupList: body['results'] }))

          }
        }
      })
      .catch(error =>
        console.error('Error while searching for location:', error)
      );

    };

  };

  // input field for the <Downshift /> component
  const downshiftOnChangeCooperative = React.useCallback(value => {
    // update branch details
    setBranchDetails({ ...value });
    // trigger fetch of location
    fetchCooperativeLocation(value['village_id']);
  }, []); // eslint-disable-line


  const fetchCooperativeLocation = villageId => {

    apiFullCall('', token, 'get', `locationsearch/?id=${villageId}`)
    .then(res => {
      if (res) {
        // destructure response
        const { status, body } = res;

        if (status === 200 || status === 201) {

          // destructure the wanted meta-data
          const {
            id,
            villagename,
            parishname,
            subcountyname,
            countyname,
            districtid,
            districtname,
            regionname,
          } = body.results[0];

          const village_meta = {
            id,
            villagename,
            parishname,
            subcountyname,
            countyname,
            districtid,
            districtname,
            regionname,
          };

          setFilterValue(prevState => ({ ...prevState, ...village_meta }))

        }
      }
    })
    .catch(error =>
      console.error('Error while searching for group location:', error)
    );

  };

  // who's authorized to do stuff
  let authorizedUser = {
    addFAA: addFarmersAgentsAccountants,
    addCA: addCoordinatorsAndAccountants,
    onlyEditFarmers: onlyEditFarmers,
    addOnlyMembers: addOnlyMembers,
    superuser: superuser
  };

  // used by superuser or agent
  let groupManagement = {
    state,
    userRole,
    filterValue,
    setUserRole,
    branchDetails,
    inputOnChangeCooperative, 
    downshiftOnChangeCooperative 
  };
  

  if (!permissions) { // if no permissions
    return null;
  };

  return UserIsPermitted ? (
      // pass dynamic props (system-admin or agent)
      <EditComponent 
        {...props}
        {...authorizedUser}
        {...groupManagement}
      />
    ) : userId ? (
      // pass updated props (if any other user)
      <EditComponent
        id={userId}        
        basePath="/"
        hasCreate={false}
        hasList={false}
        {...props}
      />
    ) : <Redirect to="/" />
};

/**
 * Here, we handle two things
 * 
 * - Either edit "own profile", if we're at #my-profile
 * - Or edit "other users", if one has rights
 * 
 */
const EditComponent = (props: any) => {

  const {
    addCA,
    addFAA,
    onlyEditFarmers,
    addOnlyMembers,
    ...rest
  } = props;

  const { resource } = rest;
  const isFarmer: boolean = resource === "users" ? true : false;   // user type
 
  return (
    <Route
      render={
        // render "dialog" only if we're at this "my-profile"
        ({ location }) => (
          (location.hash.includes(`#my-profile`) || location.hash.includes(`#my-wallet`)) 
            ? <UserProfile {...rest} isFarmer={isFarmer} />
            : <EditOtherUsers {...props} />
      )}
    />
  );
};

/* 
 * Handle edit-mode for other users - used within "EditComponent" 
 */
const EditOtherUsers = (props: any) => {
  const {
    history,
    superuser,
    state,
    addCA,
    addFAA,
    userRole,
    filterValue,
    setUserRole,
    branchDetails,
    addOnlyMembers,
    onlyEditFarmers,
    inputOnChangeCooperative, 
    downshiftOnChangeCooperative,
    ...other
  } = props;

  const {
    classes,
    hasCreate,
    hasEdit,
    hasList,
    hasShow,
    ...rest // eslint-disable-line
  } = other;

  const { resource, basePath } = props;

  const handleClose = React.useCallback(() => {
    const myProfile = history.location.hash.includes(`#my-profile`);

    if (myProfile) {
      history.push(history.location.pathname);
    } else {
      history.push(basePath);
    }
  }, [history, basePath]);

  
  /* Handling routing logic for either farmer or agent (edit mode) */

  const isAgent: boolean = resource === "agents" ? true : false;   // user type
  const isFarmer: boolean = resource === "users" ? true : false;   // user type
  const isAccountant: boolean = resource === "accountants" ? true : false;

  const allProps = {
    isAgent: isAgent,
    isFarmer: isFarmer,
    onCancel: handleClose,
    isAccountant: isAccountant,
    ...props
  };

  /* Edit users conditionally */
  return (
    <React.Suspense fallback={<FetchProgress title="Loading..." top={true} /> }>
      {onlyEditFarmers
        ? <Edit title={<UserTitle />} {...rest}>
            <UserFormBasic {...allProps} />
          </Edit>
        : addFAA
          ? (isAgent || isFarmer || isAccountant) &&
            /* In edit-mode: <Route path="/basePath/:id"> didn't work yet */
            <Edit title={<UserTitle />} {...rest}>
              <UserFormBasic {...allProps} />
            </Edit>
          : addCA
            ? <Switch>
                <Route path="/coordinators/:id">
                  <Edit title={<UserTitle />} {...rest}>
                    <UserFormBasic {...allProps} />
                  </Edit>
                </Route> 
                <Route path="/accountants/:id">
                  <Edit title={<UserTitle />} {...rest}>
                    <UserFormBasic {...allProps} />
                  </Edit>
                </Route>
              </Switch>
            : addOnlyMembers
              ? <Route path="/members/:id">
                  <Edit title={<UserTitle />} {...rest}>
                    <UserFormBasic {...allProps} />
                  </Edit>
                </Route> 
              : <Redirect to="/" />}
    </React.Suspense>
  )
};

const UserTitle: React.FC<FieldProps<User>> = ({ record }) => {
  return record ? (
    <FullNameField record={record} />
  ) : null;
};

export default UserEdit;