import React from 'react';
import moment from "moment";
import ProviderReportCards from './ProviderReportCards';
import { List, CardActions, useNotify } from 'react-admin';
import { TextField, InputLabel, FormControl, MenuItem, Paper, Select } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { apiFullCall } from '../../apiHelper';
import { serviceProvidersStyles } from '../../stylesheets';


const ProviderReport = ({ classes, ...props }) => {
  const notify = useNotify();

  const token = sessionStorage.getItem('token');
  const programid = sessionStorage.getItem('userProgramId');

  const [districts, setDistricts] = React.useState([]);
  const [districtid, setDistrict] = React.useState('none');
  const [regions, setRegions] = React.useState([]);

  const [agents, setAgents] = React.useState([]);
  const [agentid, setAgent] = React.useState('none');

  const [startDate, setStartDate] = React.useState('');
  const [endDate, setEndDate] = React.useState('');

  const setSelectAgent = e => setAgent(e.target.value ? e.target.value : 'none');

  const setSelectDistrictValue = param => {
    setDistrict(param ? param.districtid : 'none');

    if (param) {
      fetchAgent(`is_agent=${true}&districtid=${param.districtid}`)
    } else {
      fetchAgent(`is_agent=${true}`)
    };

  };

  const setDates = e => {
    if (e.target.value) {
      if (e.target.name === "startDate") {
        setStartDate(e.target.value)
      }
      else {
        setEndDate(e.target.value)
      }
    }
  };

  const setfuncDistrictValue = param => {

    if (param.length >= 3) {
      apiFullCall(
        '',
        token,
        'get',
        `district/?search=${param}`
      ).then(res => {
        const { status, body } = res;

        if (status === 200) {

          setDistricts(body.results)

        } else {
          notify(`Experienced an error, please try again later.`, 'warning')
        };

      }).catch(
        error => console.error('Error while getting districts:', error)
      );
    };

  };

  const fetchAgent = param => {

    apiFullCall(
      '',
      token,
      'get',
      `providerclassifications/?${param}&filter_2=${programid}`
    ).then(res => {

      const { status, body } = res;

      if (status === 200) {

        setAgents(body.results)

      } else {
        notify(`Experienced an error, please try again later.`, 'warning')
      };

    }).catch(
      error => console.error('Error while getting agents:', error)
    );

  };

  React.useEffect(() => {

    apiFullCall(
      '',
      token,
      'get',
      `region/`
    ).then(res => {
      const { status, body } = res;

      if (status === 200) {
        setRegions(body.results)
      } else {
        notify(`Experienced an error, please try again later.`, 'warning')
      };

    }).catch(
      error => console.error('Error while getting regions:', error)
    );

    apiFullCall(
      '',
      token,
      'get',
      `providerclassifications/?is_agent=${true}&filter_2=${programid}`
    ).then(res => {
      const { status, body } = res;

      if (status === 200) {
        setAgents(body.results)
      } else {
        notify(`Experienced an error, please try again later.`, 'warning')
      };

    }).catch(
      error => console.error('Error while getting agents:', error)
    );

  }, [programid]); // eslint-disable-line react-hooks/exhaustive-deps


  const moreProps = {
    agents: agents,
    agent: agentid,
    endDate: endDate,
    regions: regions,
    setDates: setDates,
    startDate: startDate,
    districts: districts,
    programid: programid,
    setDistricts: setDistricts,
    setSelectAgent: setSelectAgent,
    setfuncDistrictValue: setfuncDistrictValue,
    setSelectDistrictValue: setSelectDistrictValue,
  };

  const filterProps = {
    filter_1: districtid,
    filter_2: programid,
    filter_3: agentid, 
    filter_4: startDate ? startDate : 'none', 
    filter_5: endDate ? endDate : 'none'
  };

  return (
    <>
      <ServiceProvidersFilters {...moreProps} />
      <List
        {...props}
        pagination={false}
        actions={<NoneActions />}
        title="Service Providers Report"
        filterDefaultValues={{ ...filterProps }}
      >
        <ProviderReportCards filters={{ ...filterProps }} {...props} />
      </List>
      {(districtid !== 'none' || programid !== 'none' || agentid !== 'none' || startDate !== '' || endDate !== '') &&
        <Paper>
          <div style={{ textAlign: 'center' }}>
            <p>
              <i>Applied filters: 
                <span>
                  <b>{`
                    ${districtid !== 'none' ? 'District ,' : ''} 
                    ${programid !== 'none' ? 'Program ,' : ''} 
                    ${agentid !== 'none' ? 'Agent ,' : ''} 
                    ${startDate !== '' ? 'Start date ,' : ''} 
                    ${endDate !== '' ? 'End date' : ''}
                  `}</b>
                </span>
              </i>
            </p>
          </div>
        </Paper>
      }
    </>
  );
};

const ServiceProvidersFilters = ({ 
  programid,
  startDate, 
  setDates, 
  endDate, 
  agents, 
  agent, 
  districts, 
  regions, 
  setDistricts, 
  setSelectAgent,
  setfuncDistrictValue, 
  setSelectDistrictValue 
}) => {
  const classes = serviceProvidersStyles();

  return (
    <form
      noValidate
      autoComplete="off"
      style={{ width: '100%', marginTop: '30px', marginBottom: '-30px' }}
    >
      <Autocomplete
        autoHighlight
        options={districts}
        id="country-select-demo"
        style={{ width: 224, float: 'left', marginLeft: '10px' }}
        classes={{ option: classes.option }}
        getOptionLabel={(option) => option.districtname}
        onChange={(event, newValue) => {
          event.preventDefault();
          setDistricts(newValue ? [newValue, ...districts] : districts);
          setSelectDistrictValue(newValue);
        }}
        onInputChange={(event, newInputValue) => setfuncDistrictValue(newInputValue)}
        renderOption={(option) => <>{option.districtname} ({option.region})</>}
        renderInput={(params) =>
          <TextField
            {...params}
            variant="outlined"
            label="Search District"
            inputProps={{ autoComplete: 'new-password', ...params.inputProps }}
          />
        }
      />
      <TextField id="outlined-basic" label="Start date"
        type="date"
        name="startDate"
        value={startDate}
        variant="outlined"
        onChange={setDates}
        InputLabelProps={{ shrink: true }}
        style={{ float: 'left', marginLeft: '10px' }}
        inputProps={{ max: moment().format('YYYY-MM-DD') }}
      />
      <TextField id="outlined-basic" label="End date"
        type="date" 
        name="endDate"
        value={endDate}
        variant="outlined"
        onChange={setDates} 
        InputLabelProps={{ shrink: true }}
        style={{ float: 'left', marginLeft: '10px' }}
        inputProps={{ max: moment().format('YYYY-MM-DD') }}
      />
      <FormControl variant="outlined" style={{ float: 'left', minWidth: 220, marginLeft: '10px' }}>
        <InputLabel id="demo-simple-select-outlined-label">Agent</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={agent}
          onChange={setSelectAgent}
          label="Agent"
        >
          <MenuItem value="none">
            <em>Select</em>
          </MenuItem>
          {agents.map((agent, index) => <MenuItem key={index} value={agent.id}>{agent.fullname}</MenuItem>
          )}
        </Select>
      </FormControl>
    </form>
  );
};

const NoneActions = props => (
  <CardActions />
);

export default ProviderReport;
