import LoanApplicationIcon from '@material-ui/icons/TableChartTwoTone';
import LoanCreate from '../loancrud/LoanCreate';
import LoanList from '../loancrud/LoanList';

const loanpapps = {
  list: LoanList,
  create: LoanCreate,
  icon: LoanApplicationIcon,
};

export default loanpapps;