import * as request from 'superagent';

export const BASE_URL = `${
  process.env.NODE_ENV !== 'production'
    ? process.env.REACT_APP_API
    : process.env.REACT_APP_AWS
}`;

export default function apiCall(data, header, type, url) {

  return new Promise((resolve, reject) => {
    request[type](BASE_URL + `/${url}`)
      .send(data)
      .set('Content-Type', 'application/json')
      .set('authorization', 'Bearer ' + header)
      .end((err, res) => {
        if (res) {
          return resolve(res.body);
        }
        return reject(err);
      });
  });
}

// Use this API if you require to manupilate entire response at component-level,
// and return guiding steps to handle user journey

// See usage: src/modules/providers/form/ProviderMultiForm
export const apiFullCall = (data, header, type, url) => (
  new Promise((resolve, reject) => {
    request[type](BASE_URL + `/${url}`)
      .send(data)
      .set('Content-Type', 'application/json')
      .set('authorization', 'Bearer ' + header)
      .end((err, res) => {
        if (res) {
          // console.log(res)
          return resolve(res);
        }        
        // console.log(err);
        return reject(err);
      });
  })
);

export const apiFileUploadCall = (data, header, type, url) => (
  new Promise((resolve, reject) => {
    request[type](BASE_URL + `/${url}`)
      .send(data)
      .set('authorization', 'Bearer ' + header)
      .end((err, res) => {
        if (res) {
          return resolve(res);
        }
        return reject(err);
      });
  })
);

export const apiExternalFullCall = (data, type, url) => (
  new Promise((resolve, reject) => {
    request[type](`${url}`)
      .send(data)
      .set('Content-Type', 'application/json')
      .end((err, res) => {
        if (res) {
          // console.log(res)
          return resolve(res);
        }        
        // console.log(err);
        return reject(err);
      });
  })
);

export const apiFileUploadCallPrediction = (data, header, type, url) => (
  new Promise((resolve, reject) => {
    request[type](`https://pushsmscoreapi.inocrate.com/api/${url}`)
      .send(data)
      // .set('authorization', 'Bearer ' + header)
      .end((err, res) => {
        if (res) {
          return resolve(res.body);
        }
        return reject(err);
      });
  })
);