
import React from 'react';
import clsx from 'clsx';

import format from 'date-fns/format';
import formatDistance from 'date-fns/formatDistance';
import differenceInHours from 'date-fns/differenceInHours';

import { makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import { FieldProps } from 'types';

const useStyles = makeStyles({
	withInTime: {
		color: '#3f51b5',
	},
	outOfTime: {
		color: '#df1f26',
	},
});


/* start date - programs */


const StartDateField: React.FC<FieldProps> = (props: any) => {
	const classes = useStyles();
	const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

	const { record } = props;
	
	const StartDate = record &&
		record.start_date &&
			format(new Date(record['start_date']), 'dd/MM/yyyy');			
	
	const daysFromStartDate = record &&
		record.start_date &&
			formatDistance(new Date(record['start_date']), new Date(), { addSuffix: true } );

	// returns "hours"
	const timeSinceStart = record &&
		record.start_date &&
			differenceInHours(new Date(), new Date(record['start_date']));

	if (!record) {
		return null;
	};

  return (
    <span>
      {isXSmall 
        ? <>{StartDate} &nbsp;</>
        : <>{StartDate}<br/></>
      }
      <span className={clsx({
        [classes.withInTime]: timeSinceStart < 24,
        [classes.outOfTime]: timeSinceStart >= 24,
      })}>
        &#40;{daysFromStartDate}&#41;
      </span>
    </span>
  );
};

export const PureStartDateField = StartDateField;

PureStartDateField.defaultProps = {
	source: 'start_date',
	label: 'Start'
};


/* end date - programs */


const EndDateField: React.FC<FieldProps> = (props: any) => {
	const classes = useStyles();
	const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

	const { record } = props;
	
	const EndDate = record &&
		record.end_date &&
			format(new Date(record['end_date']), 'dd/MM/yyyy');			
	
	const daysFromEndDate = record &&
		record.end_date &&
			formatDistance(new Date(record['end_date']), new Date(), { addSuffix: true } );

	// returns "hours"
	const timeToEnd = record &&
		record.end_date &&
			differenceInHours(new Date(), new Date(record['end_date']));

	if (!record) {
		return null;
	};

  return (
    <span>
      {isXSmall 
        ? <>{EndDate} &nbsp;</>
        : <>{EndDate}<br/></>
      }
      <span className={clsx({
        [classes.withInTime]: timeToEnd < 24,
        [classes.outOfTime]: timeToEnd >= 24,
      })}>
        &#40;{daysFromEndDate}&#41;
      </span>
    </span>
  );
};

export const PureEndDateField = EndDateField;

PureEndDateField.defaultProps = {
	source: 'end_date',
	label: 'End'
};

