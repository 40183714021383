import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { FetchProgress } from 'layout';

const ReportList = React.lazy(() => import("../report/ReportList"));
const UserMessage = React.lazy(() => import("../../users/userdialogs/UserMessageModal"));

const SmsList = props => {
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  let programid = sessionStorage.getItem('userProgramId');

  const [data, setData] = React.useState({});

  const { permissions, ...rest } = props;

  const [openStatus, setOpenStatus] = React.useState(false);
  // handles modals open/close
  const handleOpenStatus = () => setOpenStatus(!openStatus);

  // handles fetch of single record
  const handleData = singleRecord => setData({ ...data, ...singleRecord });

  const [show, setShow] = React.useState(false);
  const onClick = () => setShow(!show);

  const moreProps = {
    handleOpenStatus: handleOpenStatus,
    permissions: permissions,
    handleData: handleData,
    programid: programid,
    isXSmall: isXSmall,
    ...rest,
  };

  return (
    <React.Suspense fallback={<FetchProgress title="Loading..." top={true} /> }>
      <ReportList {...moreProps} />
      <UserMessage        
        {...props} 
        show={show}
        record={data}
        onClick={onClick} 
        open={openStatus}
        isXSmall={isXSmall}
        onClose={handleOpenStatus}
      />
    </React.Suspense>
  )
};

export default SmsList;
