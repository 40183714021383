import FarmerIcon from '@material-ui/icons/PeopleOutline';
import FarmerReportList from './FarmerReportList';
import FarmerReport from './FarmerReport';

const farmers = {
  icon: FarmerIcon,
  list: FarmerReport,
  detail: FarmerReportList
};

export default farmers;
