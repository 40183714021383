import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { Dialog, useMediaQuery } from '@material-ui/core';

import { RequestListContext } from 'context/requests';
import { FetchProgress } from 'layout';

const RequestEdit = React.lazy(() => import("./RequestEdit"));
const RequestBulkList = React.lazy(() => import("./RequestBulkList"));
const CashOrderList = React.lazy(() => import("../cashorders/CashOrderList"));
const CreditOrderList = React.lazy(() => import("../creditorders/CreditOrderList"));
const AggregatedOrderList = React.lazy(() => import("../aggregatedorders/AggregatedOrderList"));

const RequestList = props => {
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const openSidebar = useSelector(state => state.admin.ui.sidebarOpen);
  useSelector(state => state.theme); // force rerender on theme change

  const programid = sessionStorage.getItem('userProgramId');

  const [open, setOpen] = React.useState(false);
  const [openDetail, setOpenDetail] = React.useState(false);
  const [data, setData] = React.useState({});

  const { resource } = props;

  // handles "Edit" model open/close
  const handleOpenModal = () => setOpen(!open);

  // handles "Detail" model open/close
  const handleOpenDetailModal = () => setOpenDetail(!openDetail);

  // handles fetch of single record
  const handleData = singleRecord => setData({ ...data, ...singleRecord });

  const cashOrders = resource === "cash-orders";
  const creditOrders = resource === "credit-orders";
  const aggOrders = resource === "aggregated-orders";
  const cordinatedOrders = resource === "cordinated-orders";
  
  /**
   * Technically, "resource" (react-admin) isn't different to "path" (Route)
   * Why use both?
   * 
   * - We need <RequestEdit/> for both "cash" and "credit"
   * - And yet we need the components to display at specific "path"
   * - While for "aggregated" we render another
   */
  return (
    <RequestListContext.Provider 
      value={{
        handleOpenModal: handleOpenModal,
        handleData: handleData,
        programid: programid,
        isXSmall: isXSmall,
        open: open,        // consumed by <AggregatedOrderList>, <RequestBulkList>
        data: data,        // consumed by <RequestEdit>, <RequestBulkList>
        ...props
      }}
    >
      <React.Suspense fallback={<FetchProgress title="Loading..." top={true} /> }>
        {(cashOrders || creditOrders || cordinatedOrders)
          ? <>
              <Switch>
                <Route exact path={["/credit-orders", "/cordinated-orders"]}>
                  <CreditOrderList />
                </Route>
                <Route path="/cash-orders">
                  <CashOrderList />
                </Route>
              </Switch>
              <Dialog // individual order details (for provider / sacco-admin)
                maxWidth="lg"
                open={open}
                onClose={handleOpenModal}
                className={clsx('provider-requests--modal')}
                aria-labelledby="requests-dialog-title"
              >
                <RequestEdit />
              </Dialog>
            </>
          : aggOrders
            ? <Route path="/aggregated-orders">
                <AggregatedOrderList />        
                <Dialog // orders breakdown (for sacco-admin / credit-manager)
                  fullScreen
                  open={open}
                  onClose={handleOpenModal}
                  aria-labelledby="requests-dialog-title"
                  className={clsx("aggregated-requests", "full-width", { "expand" : openSidebar } )}
                >
                  <RequestBulkList handleOpenDetailModal={handleOpenDetailModal} />
                </Dialog>
                <Dialog // individual order details (for sacco-admin / credit-manager) 
                  maxWidth="lg"
                  open={openDetail}
                  onClose={handleOpenDetailModal}
                  className={clsx('provider-requests--modal')}
                  aria-labelledby="requests-dialog-title"
                >
                  <RequestEdit handleOpenDetailModal={handleOpenDetailModal} />
                </Dialog>
              </Route>
            : null}
      </React.Suspense>
    </RequestListContext.Provider>
  );
};

export default RequestList;
