import React, { useState } from 'react';
import { useRefresh } from 'react-admin';
import readXlsxFile from 'read-excel-file';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  TextField,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import apiCall from '../../apiHelper';
import { requestStyles } from '../../stylesheets';
import Autocomplete from '@material-ui/lab/Autocomplete';

export function Uploads() {
  var theJsonArray = [];

  const [loadTable, setLoaderState] = useState(false);
  const [trans, setTransactions] = useState([]);
  const [providers, setProviders] = useState([]);
  const [provider, setProvider] = useState({ id: 0, business_name: '' }); //eslint-disable-line
  const [providerID, setProviderId] = useState(0); //eslint-disable-line
  const [branches, setBranches] = useState([]);
  const [branch, setBranch] = useState({ id: 0 });
  const [crops, setCrops] = useState([]);
  const [crop, setCrop] = useState({ id: 0 });
  const [buttontext, setButtontext] = React.useState("CHOOSE A FILE");

  const refresh = useRefresh();
  const classes = requestStyles();

  // File Upload
  const programid = sessionStorage.getItem('userProgramId');
  const [uploading, setUploading] = useState(false);
  const [file, SetFile] = useState(null);
  const [error1, SetError1] = useState(false);
  const [error2, SetError2] = useState(false);
  const [error3, SetError3] = useState(false);
  const [error4, SetError4] = useState(false);
  const [helperText, SetHelperText] = useState('');
  const hiddenFileInput = React.useRef(null);
  const [ucost, setUcost] = useState(0);
  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  const handleFileChange = event => {
    const fileUploaded = event.target.files[0];
    console.log(fileUploaded);
    SetFile(fileUploaded);
    setButtontext(fileUploaded.name);
  };
  // End of File Upload

  const handleSubmit = event => {
    if (branch.id === 0 || crop.id === 0 || file === null) {

      if (branch.id === 0) {
        SetError2(true)
      }

      if (crop.id === 0) {
        SetError3(true)
      }

      if (file === null) {
        SetHelperText('Please fill in this required field')
      }

    } else {

      theJsonArray = [];

      var unitCost = document.getElementById('unit-cost').value;
      if (parseInt(unitCost) > 0) {
        setUcost(unitCost);
        SetError4(false);
        readXlsxFile(file).then((rows) => {
          // `rows` is an array of rows
          // each row being an array of cells.
          for (var cc = 1; cc < rows.length; cc++) {

            if (rows[cc][0] != null && rows[cc][1] != null && rows[cc][2] != null && rows[cc][3] != null && rows[cc][4] != null && rows[cc][5] != null && rows[cc][6] != null && rows[cc][7] != null && rows[cc][8] != null && rows[cc][10] != null && rows[cc][11] != null && rows[cc][12] != null) {

              var date = new Date(Math.round((rows[cc][0] - 25567) * 86400 * 1000) - 2 * 24 * 60 * 60 * 1000);
              var month = date.getMonth() + 1;
              theJsonArray.push({
                date: date.getDate() + '/' + month + '/' + date.getFullYear(),
                f_year: date.getFullYear(),
                f_month: month,
                f_day: date.getDate(),
                phone_no: rows[cc][1],
                no: rows[cc][2],
                name: rows[cc][3],
                village: rows[cc][4],
                parish: rows[cc][5],
                sub_county: rows[cc][6],
                district: rows[cc][7],
                total_cost: rows[cc][8],
                amount_bought: parseInt(rows[cc][8] / unitCost),
                acres: rows[cc][10],
                amount_paid: rows[cc][11],
                balance: rows[cc][12],
              });
            }
          }

          setTransactions(JSON.parse(JSON.stringify(theJsonArray)));
          setLoaderState(true);
        });
      } else {
        SetError4(true);
        document.getElementById('unit-cost').focus();
      }
    }
  };

   //Upload transactions
   const handleUploadTransactions = () => {
    setUploading(true);
    //change n to 30(any number) or the number needed for each api call
    const n = 30

    const result = new Array(Math.ceil(trans.length / n)).fill().map(_ => trans.splice(0, n))
    // loop through each array formed and send an api request for each
    result.forEach(transactions_batch => {
      const uploadData = {
        transactions: transactions_batch,
        programid: programid,
        location: branch.id,
        provider_service: crop.id,
        unit_cost: ucost
      }
      // api call goes here where for each new array, an api call will be made.....
      apiCall({ 'data': uploadData }, sessionStorage.getItem('token'), 'post', 'uploadtransactions/').then(response => {
        setUploading(false);
        refresh();
        setButtontext('CHOOSE A FILE');
      }).catch(error => console.error('Error while Uploading:', error));

      setLoaderState(true);

    });
  };

  // End of Submit
  const fetchProviders = (value33) => {
    apiCall('', sessionStorage.getItem('token'), 'get', `provider/?search=${value33}`)
      .then(providerData => {
        setProviders(providerData.results);
      })
      .catch(error => console.error('Error during fetch:', error));
  };

  const selectProvider = event => {
    const value33 = event.target.value;
    fetchProviders(value33);
  };

  const selectedProviderLog = (event, value) => {
    const value34 = value;

    var prd = '';
    var prdObject = {}; // eslint-disable-line
    for (let i = 0; i < providers.length; i++) {
      if (providers[i].business_name === value34) {
        prd = providers[i].id;
        prdObject = providers[i]; // eslint-disable-line
        SetError1(false);
        break;
      }
    }

    setProviderId(prd);
    setProvider({ id: prd, business_name: value34 });

    // // Fetch Branches
    apiCall('', sessionStorage.getItem('token'), 'get', `providerbranch?provider_id=${prd}`)
      .then(branchData => {
        setBranches(branchData.results);
      })
      .catch(error => console.error('Error during fetch:', error));

  };

  // Handle selected Branch
  const selectBranch = event => {
    const value2 = event.target.value;
    for (let i = 0; i < branches.length; i++) {
      if (branches[i].id === value2) {
        setBranch(branches[i]);
        SetError2(false);
        break;
      }
    }

    // Fetch Crops
    apiCall('', sessionStorage.getItem('token'), 'get', `providerservice?providerlocationid=${value2}`)
      .then(cropData => {
        setCrops(cropData.results)
      })
      .catch(error => console.error('Error during fetch:', error));
  };

  // Handle selected Crop
  const selectCrop = event => {
    const value = event.target.value;
    for (let i = 0; i < crops.length; i++) {
      if (crops[i].id === value) {
        setCrop(crops[i]);
        SetError3(false);
        break;
      }
    }
  }

  return loadTable ?
    <>
      <TableContainer component={Paper}>
        <Table className={classes.saccoServiceTable} aria-label="customized table" >
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell className={classes.saccoServiceTableCell}>
                #
              </TableCell>
              <TableCell className={classes.saccoServiceTableCell}>
                Req Date
              </TableCell>
              <TableCell className={classes.saccoServiceTableCell}>
                Phone No
              </TableCell>
              <TableCell className={classes.saccoServiceTableCell}>
                Req No.
              </TableCell>
              <TableCell className={classes.saccoServiceTableCell}>
                Customer
              </TableCell>
              <TableCell className={classes.saccoServiceTableCell}>Village</TableCell>
              <TableCell className={classes.saccoServiceTableCell}>Total Cost</TableCell>
              <TableCell className={classes.saccoServiceTableCell}>Quantity</TableCell>
              <TableCell className={classes.saccoServiceTableCell}>Acres</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {trans.map((tr, index) => (

              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  {index + 1}
                </TableCell>

                <TableCell>
                  {tr.date}
                </TableCell>

                <TableCell>
                  0{tr.phone_no}
                </TableCell>

                <TableCell>
                  {tr.no}
                </TableCell>

                <TableCell>
                  {tr.name}
                </TableCell>

                <TableCell>
                  {tr.village}
                </TableCell>

                <TableCell>
                  {tr.total_cost}
                </TableCell>

                <TableCell>
                  {tr.amount_bought}
                </TableCell>

                <TableCell>
                  {tr.acres}
                </TableCell>
              </TableRow>
            ))}

          </TableBody>
        </Table>
      </TableContainer>
      <Button
        variant="contained"
        className={classes.addProgramFunderButton}
        onClick={handleUploadTransactions}
      >
        {uploading ? 'Uploading...': 'Upload Transactions'}
      </Button>
    </>
    :
    <form autoComplete="off" style={{ width: '100%' }}>
      <div className={classes.formRow}>
        <div style={{ width: '10%' }} />
        <div className={classes.formFourColumn} style={{ width: '60%' }}>
          <Autocomplete
            freeSolo
            id="provider_field"
            disableClearable
            options={providers.map((option) => option.business_name)}
            filterSelectedOptions
            onChange={selectedProviderLog}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Service Provider"
                margin="normal"
                variant="outlined"
                InputProps={{ ...params.InputProps, type: 'search' }}
                onChange={selectProvider}
                error={error1}
              />

            )}
          />
          <br /><br />
          {/* Selecting the Branch based on a Provider*/}
          <FormControl className={classes.formInput} variant="outlined" required>
            <InputLabel
              required
              id="branch_variety_field-label"
              data-testid="branch_variety_field"
            >
              Select Branch
            </InputLabel>
            <Select
              labelId="branch_variety_field-label"
              id="branch_variety_field"
              data-testid="branch_variety_field"
              label="Select Variety"
              onChange={selectBranch}
              error={error2}
              value={branch.id}
            >
              {branches?.map((branch, index) => (
                <MenuItem value={branch['id']}>
                  {branch['village_name']}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <br /><br />
          {/* Selecting the Crop based on a Branch */}
          <FormControl className={classes.formInput} variant="outlined" required>
            <InputLabel
              required
              id="variety_field-label"
              data-testid="crop_variety_field"
            >
              Select Crop
            </InputLabel>
            <Select
              labelId="variety_field-label"
              id="crop_variety_field"
              data-testid="crop_variety_field"
              label="Select Variety"
              onChange={selectCrop}
              value={crop.id}
              error={error3}
            >
              {crops.map((crop, index) => (
                <MenuItem value={crop['id']}>
                  {crop['service_name']}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <br /><br />
          <FormControl className={classes.formInput} variant="outlined" required>
            <TextField
              id="unit-cost"
              label="Unit Cost"
              variant="outlined"
              type="number"
              error={error4}
            />
          </FormControl>
          <br /><br />
          {/* Choose Transaction File to Upload */}
          <FormControl className={classes.formInput} variant="outlined" required>
            <Button
              onClick={handleClick}
            >
              {buttontext}
            </Button>
            <input
              id="upload_file"
              type="file"
              ref={hiddenFileInput}
              onChange={handleFileChange}
              style={{ display: 'none' }}
              accept=".xlsx, .xls, .csv"
            />
          </FormControl>
          <FormHelperText color='red'>{helperText}</FormHelperText>
          <br /><br />
          <Button
            variant="contained"
            className={classes.addProgramFunderButton}
            onClick={handleSubmit}
          >
            Upload
          </Button>
        </div>
      </div>
    </form>
};
