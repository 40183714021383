import React from 'react';
import clsx from 'clsx';
import Downshift from 'downshift';
import { MenuItem, Paper } from '@material-ui/core';

import { AkbInput, AkbTextField, SectionTitle } from '../../../utils';


const getDistrictSuggestions = (address = [], inputValue) => {
  let count = 0;

  return address.filter(({ districtname }) => {
    const keep =
      (!inputValue ||
        String(districtname)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) !== -1) &&
      count < 10; // max. number of suggestion within list

    if (keep) {
      count += 1;
    }

    // return just the first ten,
    // helps improve performance
    return keep;
  });
};


export const AddCoordinatorDistrict = (props: any) => {
  const {
    classes,
    filterDistrict,
    getDistrict,
    downshiftOnChangeDistrict,
    inputOnChangeDistrict,
  } = props;

  return (
    <div className="address">
      {/* Enable 'district' search */}
      <SectionTitle label="Coordinator area" />
      {/* hidden field to store 'district-id' */}
      <AkbTextField
        id="districtId"
        value={filterDistrict.districtid}
        name="district_id"
        data-testid="district_id"
        label="District id"
        className={clsx(classes.input, 'hidden')}
      />
      <Downshift
        onChange={downshiftOnChangeDistrict}
        itemToString={item => (item ? item.districtname : '')}
      >
        {({ selectedItem, getInputProps, getItemProps, highlightedIndex, isOpen, inputValue, getLabelProps }) => (
          <div 
            className={clsx(
              classes.container,
              { 'stretch--district': isOpen }
            )}
          >
            {AkbInput({
              fullWidth: true,
              classes,
              name: 'search-district',
              label: 'Select district',
              InputProps: getInputProps({
                onChange: inputOnChangeDistrict,
              }),
            })}
            {isOpen && (
              <Paper square className={clsx('select-location')}>
                {getDistrictSuggestions(getDistrict.districtList, inputValue).map(
                  (item, index) => {
                    const { districtname } = item;

                    const isHighlighted = highlightedIndex === index;
                    const isSelected =
                      String(selectedItem || '').indexOf(districtname) >
                      -1;

                    return (
                      <MenuItem
                        {...getItemProps({ key: index, index, item })}
                        selected={isHighlighted}
                        component="div"
                        style={{
                          fontWeight: isSelected ? 500 : 400,
                        }}
                      >
                        {districtname}
                      </MenuItem>
                    );
                  }
                )}
              </Paper>
            )}
          </div>
        )}
      </Downshift>
    </div>
  )
};
