import React from 'react';
import { Box } from '@material-ui/core';
import { DateInput, SelectInput, TextInput } from 'react-admin';

import { FetchProgress } from 'layout';

import {
  firstName,
  Text,
  lastName,
  Needed,
  NIN,
  Sex,
  Gender,
  Email,
  phoneNumber
} from 'modules/validation';


export const GeneralFields = (props: any) => {

  const { classes, isRegistered } = props;

  /* general validation */
  const mainContact = [ ...Needed, ...phoneNumber ];

  return (
    <React.Suspense fallback={<FetchProgress title="Loading..." top={true} /> }>
      <Box>
        <TextInput
          autoFocus={!isRegistered ? true : false}
          source="first_name"
          className={classes.input}
          validate={firstName}
        />
        <TextInput
          source="middle_name"
          className={classes.input}
          validate={Text}
        />
        <TextInput
          source="last_name"
          className={classes.input}
          validate={lastName}
        />
        <DateInput
          label="Date of Birth"
          source="dob"
          className={classes.input}
          validate={Needed}
        />
        <SelectInput
          source="gender"
          choices={Sex}
          className={classes.input}
          validate={Gender}
        />
        <TextInput
          label="Email"
          type="email"
          source="email"
          className={classes.input}
          validate={Email}
        />
        <TextInput
          label="Phone contact (Main)"
          source="username"
          className={classes.input}
          validate={mainContact}
        />
        <TextInput
          label="Phone contact (Other)"
          source="secondary_phone_number"
          className={classes.input}
          validate={phoneNumber}
        />
        <TextInput
          label="NIN"
          source="nin"
          className={classes.input}
          validate={NIN}
        />
      </Box>
    </React.Suspense>
  );
};
