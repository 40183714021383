import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core';

const useStyles = props => makeStyles({
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: props ? -30 : -8,  // passes "true" || undefined
    marginBottom: -8,
    justifyContent: 'center',
  },
  chip: { margin: 4 },
});

export const RequestCountField = props => {

  const { record, isXSmall } = props;

  const classes = useStyles(isXSmall);

  // returns "total requests" in aggregated order
	let totalRequests = record && record.requests && record.requests.length;

  if (!record) {
    return null;
  };

  return (
    <span className={classes.main}>
      <Chip label={totalRequests} className={classes.chip} />
    </span>
  );
};

RequestCountField.defaultProps = {
  label: 'Total Orders',
  textAlign: 'center',
};
