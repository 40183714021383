import React from 'react';
import { Charts } from '../../../types';

type Dispatch<A> = (value: A) => void;
type SetStateAction<S> = S | ((prevState: S) => S);

interface Totals {
  agents: number,
  farmers: number, 
  providers: number, 
  transactions: number, 
  transactions_amount: number | string | null,
};

interface contextProps {
  Charts: Charts,
  totals: Totals,
  loading: boolean,
  isSmall: boolean,
  isXSmall: boolean,
  sidebarOpen: boolean,
  isSuperuser: boolean,
  isSuperagent: boolean,
  superagentId: boolean,
  selectedAgent: string, 
  loadingChart: boolean,
  activeDistrict: string,
  setLoadingChart: Dispatch<SetStateAction<boolean>>,
  setSelectedAgent: Dispatch<SetStateAction<string>>,
  setSelectedDistrict: Dispatch<SetStateAction<object>>,
};

export const SuperuserDashboardContext = React.createContext({} as contextProps);
