import React from 'react';
import clsx from 'clsx';
import { Box, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { RenderProgramDetails, RenderFunderDetails, SelectFunderField } from './DetailFields';
import { SectionTitle, Separator } from '../../../utils';


export const ProgramFields = (props: any) => {
  const {
    record,
    classes,
    isSmall,
    onCancel,
    listFunders,
    isRegistered,
    listParentPrograms
  } = props;

  const { program_funders } = record;

  const generalProps = {
    isSmall: isSmall,
    classes: classes,
    isRegistered: isRegistered,
  };

  /* for program fields */
  const otherProps = {
    listParentPrograms: listParentPrograms,
    ...generalProps,
  };

  return (
    <Box>
      <Box display={{ xs: 'inline-flex' }} width={{ xs: '100% !important' }} mb={{ xs: '10px' }}>
        <Box flex={1}>
          <SectionTitle label="Program details" className={clsx('AkRegisterForm-title')} />
        </Box>
        <Box>
          <IconButton onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Separator />
      </Box>   
      {/* 
        * SimpleForm expects Input or Field components as children.
        * Explained here: https://marmelab.com/react-admin/CreateEdit.html#the-simpleform-component. 
        * So it's normal that you see warnings because we passed <Box>
        */}
      <Box
        display={{ md: 'flex' }}
        className={clsx('AkRegisterForm-body')}
      >
        <Box flex={3}>
          <Box>
            <RenderProgramDetails {...otherProps} />
            {/* displays if >[599.98px] */}
            {!isSmall && <RenderFunderDetails funders={program_funders} listFunders={listFunders} {...generalProps} />}
          </Box>
          <Separator />
        </Box>
        <Box flex={1} mt={{ sm: '32px' }}>
          <Box display={{ xs: 'inline-flex' }} width={{ xs: '100% !important' }} mb={{ xs: '10px' }}>
            <Box flex={1}>
              <SectionTitle label="Funder(s)" className={clsx('AkRegisterForm-title')} />
            </Box>
          </Box>
          <Separator />
          <Typography variant="body1" component="h3" gutterBottom>
            {program_funders &&
              program_funders.length > 0
                ? listFunders.filter(
                    ({ id }) => id === String(program_funders[0]['funder'])
                  ).map(({ name }, i) => <div key={i}><span>{name}</span><hr /></div>)
                : <><span>None</span><hr /></>}
          </Typography>
          <SelectFunderField isRegistered={isRegistered} funders={program_funders} listFunders={listFunders} />
          {/* displays if <[599.98px] */}
          {isSmall && <RenderFunderDetails funders={program_funders} listFunders={listFunders} {...generalProps} />}
        </Box>
      </Box>    
    </Box>
  )
};
