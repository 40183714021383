import React from 'react';
import { Redirect } from 'react-router';
import { Route, useHistory } from 'react-router-dom';
import { Create } from 'react-admin';

import { FetchProgress } from 'layout';
import * as P from 'allPermissions';

const LoanApplicationForm = React.lazy(() => import("../loanforms/LoanApplicationForm"));

const LoanCreate = props => {
  const history = useHistory();

  const { basePath, permissions } = props;
  
  const userIsPermitted = P.authCreateLoanApps(permissions);

  const handleClose = React.useCallback(() => {
    history.push(basePath);
  }, [history, basePath]);

  if (!permissions) { // if no permissions
    return null;
  };

  const moreProps = {
    onCancel: handleClose,
    ...props,
  };

  return (
    <React.Suspense fallback={<FetchProgress title="Loading..." top={true} /> }>
      {userIsPermitted
        ? <Route path="/loan-applications">
            <NewLoanApplication {...moreProps} />
          </Route>      
        : <Redirect to={basePath} />}
    </React.Suspense>
  )
};

const NewLoanApplication = props => {

  const { onCancel, ...other } = props;
  const { basePath, hasCreate, hasEdit, hasList, hasShow, permissions, ...rest } = other;

  const moreProps = {
    onCancel: onCancel,     // used to close the form
    ...rest,
  };

  return (
    <Create title="New Loan Application" {...other}>
      <LoanApplicationForm {...moreProps} />
    </Create>
  );
};

export default LoanCreate;
