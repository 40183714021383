import React from 'react';
import clsx from 'clsx';
import { DateInput, required, SimpleForm, TextInput } from 'react-admin';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

import { Accept } from '../requestops';

import { Transition, SectionTitle } from '../../utils';
import { userEditStyles } from '../../stylesheets';

export const RequestAcceptForm = props => {
  const classes = userEditStyles();

  const {
    isXSmall,
    handleData,
    acceptanceDialog,
    handleCloseAcceptDialog,
    syncWithLocation,
    ...rest
  } = props;

  const { resource } = rest;

  // orders
  const cashOrder = resource === 'cash-orders'; 
  const creditOrder = resource === 'credit-orders'; 

  // passed to <Accept />
  const acceptProps = {
    isXSmall: isXSmall,
    handleCloseAcceptDialog: handleCloseAcceptDialog,
    ...rest
  };

  return (
    <Dialog
      maxWidth="xs"
      open={acceptanceDialog}
      TransitionComponent={Transition}
      className={clsx('request--modal')}
      aria-labelledby="accept-dialog-title"
    >
      <DialogTitle
        disableTypography={true} // makes this a <div> not a default <h2>
        id="accept-dialog-title"
      >
        <SectionTitle label="Approve this request..." />
      </DialogTitle>
      <DialogContent>
        <SimpleForm
          {...rest}
          submitOnEnter={false}
          className={clsx('request', props.className)}
          toolbar={<Accept {...acceptProps} />}
        >
          {creditOrder
            /* comment - not required from admin */
            ? <TextInput
                label="Add a comment"
                source="reason"
                data-testid="reason"
                className={classes.input}
                validate={[required('Please add a comment')]}
              />
            /* comment - required from provider */
            : cashOrder &&
              <>
                <DateInput
                  label="Date to deliver"
                  data-testid="date"
                  source="provider_visit_date"
                  className={classes.input}
                  validate={[required('Please schedule delivery')]}
                />
                <TextInput
                  label="Add a comment"
                  source="reason"
                  data-testid="reason"
                  className={classes.input}
                  validate={[required('Please add a comment')]}
                />
              </>
          }
        </SimpleForm>
      </DialogContent>
    </Dialog>
  );
};
