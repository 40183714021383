import SmsReportIcon from '@material-ui/icons/PlaylistPlay';

import SmsList from '../smscrud/SmsList';

const report = {
  list: SmsList,
  icon: SmsReportIcon,
};

export default report;
