import {
  required,
  minLength,
  maxLength,
  regex,
  email,
  choices,
} from 'react-admin';

/* Generic validation */

const Needed = [required()];

const Text = [
  // ^               // start of line
  // [a-zA-Z]{2,}    // will expect a name with at least two characters
  // \s              // will look for white space between name and surname
  // [a-zA-Z]{1,}    // needs at least 1 Character
  // \'?-?           // possibility of **'** or **-** for double barreled and hyphenated surnames
  // [a-zA-Z]{2,}    // will expect a name with at least two characters
  // \s?             // possibility of another whitespace
  // ([a-zA-Z]{1,})? // possibility of a second surname
  regex(
    /^[a-zA-Z]{2,}\s?([a-zA-z]{1,})?'?-?([a-zA-Z]{2,})?\s?([a-zA-Z]{1,})?/,
    'Please enter "letters" only'
  ),
];

const boxNumber = [
  regex(
    /^[0-9]{1,},?\s?,?-?\s?[a-zA-z]{2,}/,
    'Please enter correct box address'
  ),  
];

const Precise = [
  regex(/^\d*\.?\d*$/, 'Please enter numbers only')
];

const Truthy = [
  choices([true, false], 'Must be one of the choices'),
  ...Needed,
];

/* Linked to User Create/Registration */

const firstName = [...Text, ...Needed];

const lastName = [...Text, ...Needed];

const phoneNumber = [
  minLength(10, 'Please enter a valid number'),
  maxLength(10, 'Please enter a valid number')
];

const Email = [
  email('Please enter a valid email'),
];

const Gender = [
  choices(['M', 'F', 'O'], 'Must be one of the choices'),
  ...Needed,
];

const Sex = [
  { id: 'M', name: 'Male' },
  { id: 'F', name: 'Female' },
  { id: 'O', name: 'Other' },
];

const TrueOrFalse = [
  { id: true, name: 'Yes' },
  { id: false, name: 'No' },
];

const repaymentCycle = [
  { id: 'daily', name: 'Daily' },
  { id: 'weekly', name: 'Weekly' },
  { id: '2-weeks', name: 'Every 2-weeks' },
  { id: 'monthly', name: 'Monthly' },
  { id: '3-months', name: 'Every 3-months' },
  { id: '6-months', name: 'Every 6-months' },
  { id: 'annually', name: 'Annually' },
];

const Passwords = ({ new_password, confirm_new_password }) => {
  const errors = {};

  if (new_password && confirm_new_password && new_password !== confirm_new_password) {
    errors.confirm_password = ['errors.password_mismatch'];
  }
  return errors;
};

const grantAccess = ({ username, is_admin, is_farmer, is_provider }) => {
  const errors = {};
  // const reg = /^[0-9]*$/g;

  if (
    (is_admin === true && username === '') ||
    (is_admin === true && username === undefined)
  ) {
    errors.username = ['resources.users.errors.username'];
  }

  if (
    (is_farmer === true && username === '') ||
    (is_farmer === true && username === undefined)
  ) {
    errors.username = ['resources.users.errors.username'];
  }

  if (
    (is_provider === true && username === '') ||
    (is_provider === true && username === undefined)
  ) {
    errors.username = ['resources.users.errors.username'];
  }

  return errors;
};

const NIN = [
  maxLength(14, 'NIN is incorrect'),
  regex(/^[A-Z0-9]+$/, 'Please enter a valid NIN'),
];

const Passport = [
  maxLength(8, 'Passport number is incorrect'),
  regex(/^[A-Z]{1,}[0-9]{7,}$/, 'Please enter a valid Passport number.'),
];

/* Used within Provider Approval Processs */

const idType = [
  { id: 'NIN', name: 'National ID' },
  { id: 'DL', name: "Drivers' license" },
  { id: 'PP', name: 'Passport' },
  { id: 'RGN', name: 'Refugee Number' },
];

const providerType = [
  { id: 'individual', name: 'Individual' },
  { id: 'company', name: 'Company' },
];

const TIN = [
  maxLength(10, 'TIN is incorrect'),
  regex(/^[0-9]+$/, 'Please enter a valid TIN'),
];

const limitText = [ 
  maxLength(280, 'Maximum characters exceeded') 
];

const certAgency = [
  { id: 'agrinvest', name: 'AGRINVEST' },
  { id: '6', name: 'Other' },
];

const providerCategory = [
  { id: 'ip', name: 'Input Producer' },
  { id: 'i', name: 'Importer' },
  { id: 's', name: 'Supplier' },
  { id: 'd', name: 'Distributor' },
];

/* Other validation, to be refactored */

const nationality = [
  { id: 'Ugandan', name: 'Ugandan' },
  { id: 'Other', name: 'Non-Ugandan' },
];

const users = [
  { id: '1', name: 'Farmer' },
  { id: '2', name: 'Service Provider' },
  { id: '3', name: 'SACCO Admin' },
  { id: '4', name: 'Other' },
];

const workType = [
  { id: '1', name: 'Contract' },
  { id: '2', name: 'Full-time' },
  { id: '3', name: 'Part-time' },
  { id: '4', name: 'Other' },
];

const interestRateType = [
  { id: 'Amortization', name: 'Amortization' },
  { id: 'Declining balance', name: 'Declining balance' },
  { id: 'Flat rate', name: 'Flat rate' }
];

const incomeStatus = [
  { id: 'Below 499,000', name: 'Below 499,000' },
  { id: '500,000 -- 1,499,000', name: '500,000 -- 1,499,000' },
  { id: '1,500,000 -- 2,499,000', name: '1,500,000 -- 2,499,000' },
  { id: 'Above 2,500,000', name: 'Above 2,500,000' },
];

const maritalStatus = [
  { id: 'Married', name: 'Married' },
  { id: 'Single', name: 'Single' },
  { id: 'Divorced', name: 'Divorced' },
  { id: 'Widow/Widower', name: 'Widow/Widower' },
  { id: 'Other', name: 'Other' },
];

const landNature = [
  { id: 1, name: 'Hired' },
  { id: 2, name: 'Private' },
  { id: 3, name: 'Community' },
  { id: 4, name: 'Family' },
  { id: 5, name: 'Other' },
];

const cropsPlanted = [
  { id: 1, name: 'Cassava' },
  { id: 2, name: 'Coffeee' },
  { id: 3, name: 'Maize' },
  { id: 4, name: 'Rice' },
  { id: 5, name: 'Other' },
];

const keptAnimals = [
  { id: 1, name: 'Chicken' },
  { id: 2, name: 'Cows' },
  { id: 3, name: 'Goats' },
  { id: 4, name: 'Pigs' },
  { id: 5, name: 'Sheep' },
  { id: 7, name: 'Other' },
];

export {
  /* Mainly for User Create/Edit */
  Text,
  boxNumber,
  Needed,
  Precise,
  Truthy,
  TrueOrFalse,
  repaymentCycle,
  firstName,
  lastName,
  phoneNumber,
  Email,
  Gender,
  Sex,
  NIN,
  Passport,
  limitText,
  grantAccess,
  /* Mainly for Provider Approval */
  idType, 
  providerType,     
  TIN,
  certAgency,
  providerCategory,
  /* TODO: Refactor */
  nationality,
  users,
  Passwords,
  workType,
  interestRateType,
  incomeStatus,
  maritalStatus,
  landNature,
  cropsPlanted,
  keptAnimals,
};
