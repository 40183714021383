import React from 'react';
import clsx from 'clsx';
import { useFormState } from 'react-final-form';
import { Box, Button } from '@material-ui/core';
import { minLength, PasswordInput, TextInput, useNotify, useRefresh } from 'react-admin';
import { Needed } from '../../../validation';

import { ApproveButton as NoButton } from '../../../../layout';
import { SectionTitle } from '../../../utils';

import { apiFullCall } from '../../../apiHelper';

export const PasswordFields = (props: any) => {
  const {
    classes,
    isSmall, /* < 599.98px */
    token,
  } = props;

  const notify = useNotify();
  const refresh = useRefresh();
  const formState = useFormState();

  const { values } = formState && formState; /* formState.values */

  /* password validation */
  const checkChange = (value, allValues) => {

    if (value === allValues['old_password']) {
      return 'errors.password_not_changed';
    };

    return undefined;
  };

  /* confirm password validation */
  const checkPass = (value, allValues) => {

    if (value !== allValues['new_password']) {
      return 'errors.password_mismatch';
    };

    return undefined;
  };
  
  const passwordChange = [ ...Needed, minLength(5, 'Minimum 5 characters'), checkChange ];
  const passwordMatch = [ ...Needed, checkPass ];

  /* send to change password */
  const passData = {
    old_password: values['old_password'],
    new_password: values['new_password'],
  };

  const handleChangePassword = React.useCallback(() => {

    /* update 'password' */
    apiFullCall(
      passData, 
      token, 
      'put',
      `change-password/` 
    ).then(res => {

      if (res) {

        const { status } = res;

        if (status === 200 || status === 201) {

          refresh();
          notify(`Password change was successful`, 'info')
        } else if (status === 400) {

          notify(`Old password was wrong, please try again!`, 'warning')
        } else {
          
          notify(`Server error, please try again later.`, 'warning'); 
        };

      };

    }).catch(
      error => console.error('Error while updating password:', error)
    );

  }, [passData]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {/* if <[599.98px], hide this title / show the title within "UserFiels" within myProfile */
        !isSmall && <><SectionTitle label="Change password" /><hr /></>} 
      {/* if <[599.98px], show this message */
        isSmall && <><SectionTitle label="Complete this form." /><br /></>}  
      <TextInput
        source="old_password"
        className={classes.input}
        validate={Needed}
      />
      <PasswordInput
        source="new_password"
        className={classes.input}
        validate={passwordChange}
      />
      <PasswordInput
        source="confirm_new_password"
        className={classes.input}
        validate={passwordMatch}
      />
      {/* if <[599.98px], hide small buttons / show "PasswordManager" toolbar */
        !isSmall &&
          <>
            <Box className={clsx('change-password')}>
              <NoButton
                label="No"
                icon={null}
                isXSmall={isSmall}
                className={clsx('small--btn')}
                onClick={refresh}
              />
              <Button
                color="primary"
                variant="contained"
                onClick={handleChangePassword}
              >
                Yes
              </Button>
            </Box>
          </>
      }
      {/* if <[599.98px], show this message */
        isSmall &&
          <>
            <SectionTitle label='Press "Yes"' />
            <SectionTitle label='to update your password.' />
          </>} 
    </>
  )
};
