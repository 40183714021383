import React from 'react';
import { List, Datagrid, TextField, useNotify } from 'react-admin';
import { FullNameField, GenderField } from './CustomFields';
import clsx from 'clsx';
import moment from "moment";

const LinkFarmersReportList = props => {
  const programid = sessionStorage.getItem('userProgramId');
  const notify = useNotify();

  return ( 
    <>
    <List
      {...props}
      perPage={15}
      title="Digital Link Farmers Report"
      filterDefaultValues={{input_type:'ID', programid: programid}}
    >
      <div className={clsx(
          'font-sm',
          'narrow-cell')}>
      <Datagrid
        {...props}
      >
        <TextField source="none" label="#" />
        <TextField source="name" label="Name" />
        <TextField source="user_id" label="User ID" />
        <TextField source="group" label="Group name" />
        <TextField source="status" label="Status" />
        <TextField source="super_agent" label="Super Agent name" />
        <TextField source="village" label="Village" />
        <TextField source="parish" label="Parish" />
        <TextField source="district" label="District" />
      </Datagrid></div>
    </List></>
  );
};

export default LinkFarmersReportList;
