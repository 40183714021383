import cashorders from './cashorders';
import creditorders from './creditorders';
import aggregatedorders from './aggregatedorders';
import cordinatedorders from './cordinatedorders';

const requests = {
  cashorders: cashorders,
  creditorders: creditorders,
  aggregatedorders: aggregatedorders,
  cordinatedorders: cordinatedorders
}

export default requests;