import React, { useState, useEffect } from 'react';
import { TextField, Button, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import SaveIcon from '@material-ui/icons/Save';

import { apiCall, requestStyles } from 'modules';

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export const DashboardSettings = () => {
  const [targets, setTargets] = useState({});
  const [open, setOpen] = useState(false);
  const classes = requestStyles();

  const fetchSettings = () => {
    apiCall('', sessionStorage.getItem('token'), 'get', 'appsettings/?setting_key=dashboard_targets')
      .then(appSettings => {
        if (appSettings['setting_value'] !== 'Not Found') {
          setTargets(JSON.parse(appSettings['setting_value']));
        } else {
          setTargets({
            target_male: 0,
            target_female: 0
          });
        }
      })
      .catch(error =>
        console.error('Error during fetch:', error)
      );
  };

  useEffect(fetchSettings, []); // eslint-disable-line react-hooks/exhaustive-deps

  const updateGenderTarget = (event) => {
    var male = document.getElementById('target_male').value;
    var female = document.getElementById('target_female').value;

    setTargets({ target_male: male, target_female: female });
  };

  const saveAppSettings = () => {
    if (parseInt(targets['target_male']) > 0 && parseInt(targets['target_female']) > 0) {
      let data = {
        setting_key: 'dashboard_targets',
        setting_value: JSON.stringify(targets),
      };

      apiCall(
        data, 
        sessionStorage.getItem('token'), 
        'post', 
        'appsettings/'
      ).then(res => {
        setOpen(true);
      }).catch(
        error => console.error('Error while saving:', error)
      );

    } else {

      if (!(parseInt(targets['target_male']) > 0)) {
        document.getElementById('target_male').focus();
      } else {
        document.getElementById('target_female').focus();
      };

    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <div className={classes.saccoServicesWrapper}>
        <div className={classes.formRow}>
          <div className={classes.formOneColumn}>
            <h3>AkelloBanker Dashboard settings</h3>
          </div>
        </div>
        <div className={classes.formRow}>
          <div className={classes.formOneColumn}>
            <h5>Farmer Targets</h5>
          </div>
        </div>
        <div className={classes.formRow}>
          <div className={classes.formThreeColumn}>
            <TextField
              size="small"
              id="target_male"
              label="Male"
              variant="filled"
              autoComplete="off"
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
              onInput={updateGenderTarget}
              value={targets['target_male']}
              className={classes.formInput} 
            />
          </div>
          <div className={classes.formThreeColumn}>
            <TextField
              size="small"
              id="target_female"
              label="Female"
              variant="filled"
              autoComplete="off"
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
              onInput={updateGenderTarget}
              value={targets['target_female']}
              className={classes.formInput} 
            />
          </div>
          <div className={classes.formThreeColumn}>
            <TextField
              size="small"
              id="filled_total"
              label="Total no. of Farmers"
              variant="filled"
              autoComplete="off"
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
              disabled="true"
              value={parseInt(targets['target_male']) + parseInt(targets['target_female'])}
              className={classes.formInput} 
            />
          </div>
        </div>
        <div className={classes.formRow}>
          <div className={classes.formOneColumn}>
            <Button
              className={classes.buttonSaveFunder}
              variant="outlined"
              color="primary"
              onClick={saveAppSettings}
            >
              <SaveIcon />Save Settings
            </Button>
          </div>
        </div>
      </div>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">Settings saved</Alert>
      </Snackbar>
    </>
  );
};
