import React from 'react';
import clsx from 'clsx';

import {
  Box,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { SectionTitle } from '../../../utils';


export const OrderTableField = props => {

  const {
    classes,
    record,
    resource,
    ...rest // eslint-disable-line
  } = props;
  
  // destructure "record"
  const { services_requested_by_farmer, total } = record;

  const RequestItemsTitle = () => (
    // allow styling for modal-requested-items
    <p className={clsx('details')}>
      <span className={clsx('intro')}>Items requested:</span>
    </p>
  );

  return (
    <>
      <Box
        display={{ xs: 'flex' }} 
        width={{ xs: '100% !important' }}
      >
        <SectionTitle label="" show={<RequestItemsTitle/>} />
      </Box>
      <Box
        display={{ xs: 'block' }} 
        width={{ xs: '100% !important' }}
        mb={{ xs: '2rem' }}
      >
        <TableContainer component={Paper}>
          <Table className={clsx('requested-items', classes.table)} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Item</TableCell>
                <TableCell>Acres</TableCell>
                <TableCell>Qty</TableCell>
                <TableCell>Unit Cost</TableCell>
                <TableCell>Total Cost</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {services_requested_by_farmer 
                && services_requested_by_farmer.map((value, i) => {
                  return (
                    <React.Fragment key={i}>
                      <TableRow>
                        <TableCell>{i + 1}</TableCell>
                        {value.specifications 
                          && value.specifications.map((product, i) => (
                            <React.Fragment key={i}>
                              <TableCell>
                                {value['service_name']}&nbsp;({product['specification']})
                              </TableCell>
                              <TableCell>
                                <Chip
                                  label={product['acreage']}
                                  className={classes.requestItemType}
                                  variant="outlined"
                                />
                              </TableCell>                              
                              <TableCell>{product['qty']}</TableCell>
                              <TableCell>{product['service_cost']}</TableCell>
                              <TableCell>{total && total}</TableCell>
                            </React.Fragment>
                          ))
                        }
                      </TableRow>
                      {resource !== "aggregated-orders"
                        && <TableRow>
                            {value.specifications 
                              && value.specifications.map((product, i) => (
                                <React.Fragment key={i}>
                                  <TableCell></TableCell>
                                  <TableCell className={classes.requestDelivery} colSpan="6">
                                    *{(product['mode_of_delivery']).toLowerCase()}
                                  </TableCell>
                                </React.Fragment>
                              )
                            )}
                           </TableRow>
                      }
                    </React.Fragment>
                  );
                })
              }
              <TableRow>
                <TableCell
                  className={classes.requestTotal}
                  colSpan="2"
                >
                  Total Cost
                </TableCell>
                <TableCell
                  className={classes.requestTotal}
                  colSpan="4"
                  align="right"
                >
                  {total && total}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};
