import { createMuiTheme } from '@material-ui/core/styles';

export const AbTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#722F37',
    },
    secondary: {
      main: '#fff',
    },
  },
});

export const lightTheme = {
  palette: {
    secondary: {
      light: '#5f5fc4',
      main: '#283593',
      dark: '#001064',
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiFilledInput: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        '&$disabled': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
      },
    },
  },
};
