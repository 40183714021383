
import CooperativesListIcon from '@material-ui/icons/PeopleOutline';
import CooperativesList from './CooperativeReport';

const cooperatives = {
  list: CooperativesList,
  icon: CooperativesListIcon,
};

export default cooperatives;
