import AgentIcon from '@material-ui/icons/PeopleOutlineTwoTone';

import UserList from '../usercrud/UserList';
import UserCreate from '../usercrud/UserCreate';
import UserEdit from '../usercrud/UserEdit';

const agents = {
  list: UserList,
  create: UserCreate,
  edit: UserEdit,
  icon: AgentIcon,
};

export default agents;